import { Copier } from '../components/common/copier/copier';
import { AppToaster } from './toaster';
import { Intent } from '@blueprintjs/core';

export const AppCopier = Copier.create({
    text: '',
    onCopy: () => {
        AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Copied!',
            timeout: 1000,
        });
    },
});
