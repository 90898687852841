import { action, computed, observable } from 'mobx';
import { IProvisionConfiguration } from '../types/provision';
import { ChangeEvent } from 'react';
import {
    IFormComponent,
    IFormFieldMapTarget,
    IFormParam,
    isFormComponent,
    isFormContainer,
} from '../types';
import { SettingsGroup } from '../types';

export class FormModel {
    @observable formId = 'f';
    @observable settingsGroup: SettingsGroup = SettingsGroup.Basic;

    @observable isValid = false;
    @observable isEnabled = false;
    @observable isSubmitted = false;

    @observable fields: IProvisionConfiguration = {
        [SettingsGroup.Basic]: [],
        [SettingsGroup.Advanced]: [],
    };

    @computed
    get isDisabled(): boolean {
        return this.isSubmitted || !this.isEnabled;
    }

    @action
    setFields(fields: IProvisionConfiguration) {
        this.fields = fields;
        this.validate();
    }

    @action
    setSubmitted(isSubmitted: boolean) {
        this.isSubmitted = isSubmitted;
    }

    @action
    setFormId(formId: string) {
        this.formId = formId;
    }

    @action
    setEnabled(isEnabled: boolean) {
        this.isEnabled = isEnabled;
    }

    @action
    handleSelectParameterChange(p: IFormComponent) {
        return (e: ChangeEvent<HTMLSelectElement>) => {
            p.v = e.currentTarget.value;
            console.log('e.currentTarget.value', e.currentTarget.value, p);

            this.applyPropertyMapping(p);
        };
    }

    @action
    handleParameterChange(p: IFormComponent) {
        return (e: ChangeEvent<HTMLInputElement>) => {
            if (
                e.currentTarget.type === 'checkbox' &&
                e.currentTarget instanceof HTMLInputElement
            ) {
                p.v = JSON.stringify(e.currentTarget.checked);
            } else {
                console.log('e.currentTarget.value', e.currentTarget.value);
                p.v = e.target.value;
                ///return;
                this.applyPropertyMapping(p);
            }
            this.validate();
        };
    }

    @action
    applyPropertyMapping(p: IFormComponent) {
        if (p.mapper) {
            const mapper = p.mapper;
            console.log('mapper', mapper);
            if (mapper.has(p.v)) {
                const mapping = mapper.get(p.v);
                if (mapping) {
                    mapping.forEach((m: IFormFieldMapTarget) => {
                        if (!m.g) {
                            return;
                        }
                        const tp = this.fields[m.g].find(
                            (pm: IFormParam) => pm.k === m.k
                        );
                        console.log('m tp', m, tp);
                        if (tp) {
                            console.log('tp', tp);
                            if (isFormContainer(tp) && isFormContainer(m)) {
                                Object.assign(tp, m);
                            } else if (
                                isFormComponent(tp) &&
                                isFormComponent(m)
                            ) {
                                tp.v = m.v || '';
                            }
                        }
                    });
                }
            }
        }
    }

    @action
    validate() {
        let isValid: boolean = true;
        const checker = (param: IFormParam) => {
            if (isFormComponent(param) && typeof param.isValid === 'function') {
                isValid = isValid && param.isValid(param);
            }
        };
        this.fields[SettingsGroup.Basic].map(checker);
        this.fields[SettingsGroup.Advanced].map(checker);
        this.isValid = isValid;
    }
}
export const formModel = new FormModel();
