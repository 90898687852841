import React, { Component } from 'react';

import './acl.scss';
import { observer } from 'mobx-react';
import {
    Button,
    Classes,
    H1,
    H6,
    HTMLSelect,
    HTMLTable,
    Intent,
    MenuItem,
} from '@blueprintjs/core';
import { withRouter } from 'react-router';
import { observable } from 'mobx';
import {
    IClusterProps,
    OwnershipPermissions,
    PermissionNames,
    Permissions,
    UIState,
} from '../../../types';
import { AppNavigator } from '../../../models/navigation';
import { ACLModel, IACLRecord } from '../../../models/acl-model';
import { IconNames } from '@blueprintjs/icons';
import {
    ISuggestProps,
    ItemListPredicate,
    ItemRenderer,
    Suggest,
} from '@blueprintjs/select';
import { ISlimUser } from '../../../models/users';
import { highlightText } from '../manifest/designer/suggest';
import classNames from 'classnames';
import { UserModel } from '../../../models/user-state';

export const renderUser: ItemRenderer<ISlimUser> = (
    user,
    { handleClick, modifiers, query }
) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${user.firstName} ${user.lastName}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={user.email.toString()}
            key={user.id}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};


export const renderCreateUserOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
) => (
    <MenuItem
        icon="add"
        text={`Create "${query}" user`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
);

export function createUser(email: string): ISlimUser {
    const [fn] = email.split('@', 1);
    const [firstName, lastName] = fn.split('.', 2);

    return {
        id: email,
        email,
        firstName,
        lastName,
    };
}

const searchUsersPredicate: ItemListPredicate<ISlimUser> = (
    query: string,
    items: ISlimUser[]
) => {
    const nQuery = query.toLowerCase();
    const r = items.filter((item: ISlimUser) => {
        return (
            item.email.toLowerCase().includes(nQuery) ||
            item.firstName.toLowerCase().includes(nQuery) ||
            item.lastName.toLowerCase().includes(nQuery) ||
            item.id.toLowerCase().includes(nQuery)
        );
    });
    console.log(items, 'r=', r);
    return r;
};

export const PermissionsOptions = [
    { label: PermissionNames[Permissions.None], value: Permissions.None },
    { label: PermissionNames[Permissions.Read], value: Permissions.Read },
    {
        label: PermissionNames[Permissions.ReadAndWrite],
        value: Permissions.ReadAndWrite,
    },
];

@observer
class ClusterACL extends Component<IClusterProps> {
    readonly m: ACLModel;

    @observable uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);
        this.m = new ACLModel();
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');
        this.uiLoadState = UIState.Loading;
        try {
            const { clusterId } = this.props.match.params;
            if (clusterId) {
                await this.m.loadCluster(clusterId);
                AppNavigator.add(
                    `/clusters/${clusterId}/`,
                    this.m.cluster ? this.m.cluster.clusterName : 'Cluster'
                );
                AppNavigator.add(
                    `/clusters/${clusterId}/acl/`,
                    'Access Management'
                );
                if (this.m.cluster) {
                    await this.m.loadAcls();
                    this.uiLoadState = UIState.Loaded;
                }
            }
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    componentDidMount(): void {
        this.load().then();
    }

    renderUser(u: ISlimUser) {
        return (
            <span>
                <a href={'mailto:' + u.email} target='_blank' rel="noreferrer">
                    {u.firstName} {u.lastName}
                </a>
            </span>
        );
    }

    render() {
        const userSuggestProps: ISuggestProps<ISlimUser> = {
            items: this.m.foundUsers,
            itemRenderer: renderUser,
            itemListPredicate: searchUsersPredicate,
            inputValueRenderer: (user: ISlimUser) =>
                user.firstName + ' ' + user.lastName,
            itemsEqual: (a: ISlimUser, b: ISlimUser) => a.id === b.id,
            noResults: <MenuItem disabled={true} text="No results." />,
            onItemSelect: (item: ISlimUser) => {
                this.m.nrec.userId = item.id;
                this.m.nrec.user = { ...item };
            },
            popoverProps: { minimal: true },
        };

        return (
            <div className="aclManagerComponent">
                <H1>{this.renderTitle()}</H1>
                <div className="flex-spaced">
                    <HTMLTable
                        interactive={true}
                        striped={true}
                        className={'aclTable'}
                    >
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Configuration</th>
                                <th>Manifest</th>
                                <th>Own</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.uiLoadState === UIState.Loading ? (
                                <tr>
                                    <td
                                        colSpan={5}
                                        className={Classes.SKELETON}
                                    >
                                        &nbsp;
                                    </td>
                                </tr>
                            ) : (
                                this.m.records.map((rec) =>
                                    this.renderAclRecord(rec)
                                )
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className={'newAclHeader'} colSpan={5}>
                                    <H6>Add new ACL record:</H6>
                                </td>
                            </tr>
                            <tr className={'newAclRecord'}>
                                <td>
                                    <UserSuggest
                                        {...userSuggestProps}
                                        fill={true}
                                        onQueryChange={(e) =>
                                            this.m.searchUser(e)
                                        }
                                        disabled={this.m.nrec.uiIsUpdating}
                                    />
                                </td>
                                <td>
                                    <HTMLSelect
                                        minimal={true}
                                        options={PermissionsOptions}
                                        value={this.m.nrec.config}
                                        onChange={(e) => {
                                            this.m.nrec.config = e.currentTarget
                                                .value as Permissions;
                                        }}
                                    ></HTMLSelect>
                                </td>
                                <td>
                                    <HTMLSelect
                                        minimal={true}
                                        options={PermissionsOptions}
                                        value={this.m.nrec.manifest}
                                        onChange={(e) => {
                                            this.m.nrec.manifest = e
                                                .currentTarget
                                                .value as Permissions;
                                        }}
                                    ></HTMLSelect>
                                </td>
                                <td>
                                    <HTMLSelect
                                        minimal={true}
                                        options={[
                                            OwnershipPermissions.None,
                                            OwnershipPermissions.Owner,
                                        ]}
                                        value={this.m.nrec.own}
                                        onChange={(e) => {
                                            this.m.nrec.own = e.currentTarget
                                                .value as OwnershipPermissions;
                                        }}
                                    ></HTMLSelect>
                                </td>
                                <td className={'action'}>
                                    <Button
                                        disabled={!this.m.cluster}
                                        loading={this.m.nrec.uiIsUpdating}
                                        onClick={() => this.m.addRecord()}
                                        icon={IconNames.ADD}
                                        intent={Intent.SUCCESS}
                                        minimal={true}
                                    >
                                        Add
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <p className={Classes.TEXT_MUTED}>
                                        If you are unable to find a user,{' '}
                                        <a href={this.cradleSignUp()}>invite</a>{' '}
                                        them to sign up for Cradle first.
                                    </p>
                                </td>
                            </tr>
                        </tfoot>
                    </HTMLTable>
                </div>
                <div className="flex-spaced">
                    <p></p>
                </div>
            </div>
        );
    }

    renderAclRecord(rec: IACLRecord) {
        return (
            <tr key={rec.userId}>
                <td>{rec.user ? this.renderUser(rec.user) : rec.userId}</td>
                <td>
                    <HTMLSelect
                        minimal={true}
                        disabled={rec.uiIsUpdating}
                        options={PermissionsOptions}
                        value={rec.config}
                        onChange={(e) => {
                            rec.config = e.currentTarget.value as Permissions;
                            this.m.updateRecord(rec).then();
                        }}
                    ></HTMLSelect>
                </td>
                <td>
                    <HTMLSelect
                        minimal={true}
                        options={PermissionsOptions}
                        value={rec.manifest}
                        disabled={rec.uiIsUpdating}
                        onChange={(e) => {
                            rec.manifest = e.currentTarget.value as Permissions;
                            this.m.updateRecord(rec).then();
                        }}
                    ></HTMLSelect>
                </td>
                <td>
                    <HTMLSelect
                        minimal={true}
                        disabled={rec.uiIsUpdating}
                        options={[
                            OwnershipPermissions.None,
                            OwnershipPermissions.Owner,
                        ]}
                        value={rec.own}
                        onChange={(e) => {
                            rec.own = e.currentTarget
                                .value as OwnershipPermissions;
                            this.m.updateRecord(rec).then();
                        }}
                    ></HTMLSelect>
                </td>
                <td className={'action'}>
                    <Button
                        onClick={() => this.m.deleteRecord(rec)}
                        disabled={
                            rec.uiIsUpdating ||
                            !UserModel.user ||
                            rec.userId === UserModel.user.id
                        }
                        loading={rec.uiIsDeleting}
                        icon={IconNames.REMOVE}
                        intent={Intent.DANGER}
                        minimal={true}
                    />
                </td>
            </tr>
        );
    }

    renderTitle() {
        return (
            <span>
                ACL for{' '}
                <span
                    className={classNames(
                        Classes.TEXT_MUTED,
                        this.m.cluster ? undefined : Classes.SKELETON
                    )}
                >
                    {this.m.cluster ? this.m.cluster.clusterName : '---'}
                </span>
            </span>
        );
    }

    private cradleSignUp() {
        return (
            'mailto:?subject=' +
            encodeURIComponent('Sign up for Cradle') +
            '&body=' +
            encodeURIComponent(
                'Hi there, please sign up for Cradle at' +
                    ' https://cradle.analoggarage.com/'
            )
        );
    }
}

export const ClusterACLComponent = withRouter<IClusterProps, any>(ClusterACL);

export const UserSuggest = Suggest.ofType<ISlimUser>();
