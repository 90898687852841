import React from 'react';
import {
    HTMLSelect,
    Icon,
    InputGroup,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { observable } from 'mobx';
import { ICradleComponentValue } from '../../../../../types';
import classNames from 'classnames';

interface IAbstractChartParameter {
    secure: boolean;
    name: string;
}

export interface IChartParameterRow extends IAbstractChartParameter {
    value: string;
    componentValue?: ICradleComponentValue;

    setValue(v: string): void;

    delete(n: string): void;

    rename(n: string): void;
}

export function getComponentColor(chartName: string): string {
    let color = '#0067B9';
    if (chartName.includes('-pipeline-')) {
        color = '#00AE65';
    }
    if (chartName.includes('-api-')) {
        color = '#FF7500';
    }
    if (chartName.includes('-ui-')) {
        color = '#8637BA';
    }
    if (chartName.includes('secrets')) {
        color = '#A50034';
    }
    return color;
}

export class ChartParameterRow extends React.Component<
    IChartParameterRow,
    { isMenuOpen: boolean }
> {
    public static defaultProps: IChartParameterRow = {
        secure: false,
        name: 'name',
        value: '',
        rename(n: string): void {},
        delete(n: string): void {},
        setValue(v: string): void {},
    };

    @observable
    public state = { isMenuOpen: false };

    handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        e.preventDefault();
        this.props.setValue(e.target.value);
    }

    openMenu() {
        this.setState({isMenuOpen: true});
        this.forceUpdate();
    }

    closeMenu() {
        this.setState({isMenuOpen: false});
        this.forceUpdate();
    }

    public render() {
        const { secure, name, value, componentValue } = this.props;

        const { isRequired, isSecure } = componentValue || {
            isRequired: false,
            isSecure: secure,
        };

        return (
            <div
                className={classNames({ 'group ': true, isRequired, isSecure })}
            >
                <Popover
                    content={
                        <Menu>
                            <MenuItem
                                onClick={() => this.props.rename(name)}
                                text="Rename"
                                icon={IconNames.EDIT}
                            />

                            <MenuDivider />
                            <MenuItem
                                onClick={() => this.props.delete(name)}
                                text="Delete"
                                icon={IconNames.DELETE}
                            />
                        </Menu>
                    }
                    className="p"
                >
                    <span title={name}>
                        {secure ? (
                            <Icon
                                icon={IconNames.LOCK}
                                iconSize={10}
                                color={'green'}
                            />
                        ) : (
                            ''
                        )}
                        &nbsp;
                        {name}
                    </span>
                </Popover>
                <div className="v">
                    {componentValue ? (
                        this.renderComponentValue(componentValue)
                    ) : (
                        <InputGroup
                            fill={true}
                            round={false}
                            small={true}
                            value={value}
                            title={value}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                this.handleChange(e);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }

    renderComponentValue(cv: ICradleComponentValue) {
        const { value } = this.props;

        if (cv.options && cv.options.length > 0) {
            return (
                <Tooltip content={cv.description}>
                    <HTMLSelect
                        options={cv.options}
                        value={this.props.value}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            this.handleChange(e);
                        }}
                    />
                </Tooltip>
            );
        } else {
            return (
                <Tooltip content={cv.description}>
                    <InputGroup
                        fill={true}
                        round={false}
                        small={true}
                        value={value}
                        title={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleChange(e);
                        }}
                    />
                </Tooltip>
            );
        }
    }
}
