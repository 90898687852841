import edgeLogo from './edge.svg';
import { prometheusConfigParameter } from '../prometheus-config-parameter';
import { certManagerConfigParameter } from '../cert-manager-config-parameter';
import React from 'react';
import { Callout, Classes, Intent } from '@blueprintjs/core';
import { ConsoleExample } from '../../../common/console-example/console-example';
import { maintainerEmailParameter } from '../maintainer-parameter';
import { isNotEmpty } from '../validators';
import {
    Architecture,
    IaaSProvider,
    IClusterInfrastructureProvider,
    IFormParam,
    IInstructions,
    SettingsGroup,
    TypeCast,
} from '../../../../types';

const { RUNNING_TEXT } = Classes;

export const edgeCredentials: IFormParam[] = [
    {
        type: 'text',
        label: 'Edge Instance IP Address',
        k: 'masterInstances',
        description: `The IP address or domain name of the compute instance. Please
                        review our [bare metal setup notes](https://confluence.analog.com/display/S2C/Cradle+User+Guides) to understand your options.
                    `,
        placeholder: 's2c-unicorn.dyndns.org',
        autoComplete: 'on',
        v: '',
        cast: TypeCast.CSVStrings,
        required: true,
        isValid: isNotEmpty,
    },
    {
        type: 'text',
        label: 'SSH Username',
        k: 'sshUser',
        description: 'The username to use when making SSH connections to the master or node instances',
        placeholder: 'root',
        v: '',
        autoComplete: 'on',
        required: true,
        isValid: isNotEmpty,
    },
    {
        type: 'text',
        autoComplete: 'off',
        label: 'SSH Key',
        k: 'sshKey',
        description: `A base 64 encoded ssh key that will allow access to the master or node instances.
            Must have root access on the instances.
            
You can copy the key like this

    # on Mac
    base64 -i ~/.ssh/id_rsa | pbcopy
    # on Linux
    base64 -i ~/.ssh/id_rsa | xclip -selection clipboard
    
    `,
        placeholder: 'SSH KEY',
        v: '',
        required: true,
        isValid: isNotEmpty,
    },
];

export const edgeIaaSProvider: IClusterInfrastructureProvider = {
    id: IaaSProvider.Edge,
    logo: edgeLogo,
    name: 'Edge',
    description: (
        <span key="bmD">
            Install a Cradle on your own ARM-based infrastructure.
        </span>
    ),
    details: (
        <div key="bmDescription">
            <p className={RUNNING_TEXT}>
                We will deploy a Cradle on ARM-based edge instances - physical
                or virtual - that already exist. We assume that these instances
                are connected to a network, and have Linux Ubuntu installed on
                them. It is possible to have the compute instances provisioned
                on a cloud provider, for example as EC2 instances on an AWS
                cloud. If this were the case, we will attempt to create a Cradle
                cluster on these instances without using any of the managed
                services offered by the cloud provider.
            </p>
            <p className={RUNNING_TEXT}>
                With this option, you will have to tell us how to reach your
                compute instances (IP Address/DNS names), and provide us with an
                SSH key that will allow us to connect to and configure these
                instances. If your instances are reachable over a public
                network, we can automatically install Cradle on your behalf. If
                not, you might have to do it yourself by following a few simple
                instructions.
            </p>
            <p className={RUNNING_TEXT}>
                The cost of operation of your Cradle cluster will vary based on
                where your compute instances are executing. For example, using
                local hardware might cost you significantly lesser than using
                compute instances in the cloud.
            </p>
        </div>
    ),
    supported: true,
    config: {
        [SettingsGroup.Basic]: [maintainerEmailParameter, ...edgeCredentials],
        [SettingsGroup.Advanced]: [
            {
                type: 'select',
                label: 'Architecture',
                k: 'architecture',
                v: 'arm64',
                required: true,
                isValid: isNotEmpty,
                description: '',
                list: Object.values(Architecture),
            },
            { ...prometheusConfigParameter, ...{ v: 'false' } },
            certManagerConfigParameter,
        ],
    },
    commonProvisionInstructions: '',
};

export function renderTokenWarning() {
    return (
        <Callout intent={Intent.WARNING} title={'Be Careful!'}>
            <p>
                The “docker run …” command above includes a secret value (CRADLE
                TOKEN=…) that you should protect. That token will expire in{' '}
                <b>12 hours</b>, but while it is active, it allows anyone who
                has this token access to the encrypted configuration information
                that you entered in the previous screen.
            </p>
            <p>Be sure to save this token if you plan to do this step later.</p>
        </Callout>
    );
}

export function renderEdgeProvisionInstructions(instructions: IInstructions) {
    const { REACT_APP_CRADLE_KNOX_URL } = process.env;
    const cradleCmd =
        `docker run --rm -e CRADLE_UP_TOKEN='${instructions.token}' \\\n` +
        `  -e CRADLE_KNOX_URL='${REACT_APP_CRADLE_KNOX_URL}' \\\n` +
        '  --entrypoint cradle_up analoggarage/agc-cradle-up:latest';

    return (
        <div>
            <div>
                <p>
                    Your Cradle cluster is ready to be created, and you can
                    complete the installation by following the detailed
                    installation instructions below.
                </p>
                <ol>
                    <li>
                        <p>
                            Install Docker CE on your installation node. If you
                            already have Docker installed, skip this step. For
                            instructions on how to install Docker, please see{' '}
                            <a
                                href="https://docs.docker.com/v17.12/install/#supported-platforms"
                                target="_blank" rel="noreferrer"
                            >
                                Docker CE installation instructions
                            </a>
                            .
                        </p>
                    </li>
                    <li>
                        <p>
                            Spin up the <b>Cradle Up</b> docker image on the
                            installation as shown below:
                        </p>
                        <ConsoleExample text={cradleCmd} />
                        {renderTokenWarning()}
                    </li>
                </ol>
            </div>
        </div>
    );
}

export function renderEdgeConfirmationMessage() {
    return (
        <div>
            We're ready to go, and will start the installation process as soon
            as you press the <i>Confirm</i> button. We will be trying to connect
            to your instances over a public network, so please make sure that
            they are accessible <b>before</b> clicking the <i>Confirm</i>{' '}
            button.
        </div>
    );
}
