import React, { Component } from 'react';

import './chartmuseum.scss';
import { observer } from 'mobx-react';
import {
    Button,
    ButtonGroup,
    Callout,
    Card,
    Elevation,
    FileInput,
    FormGroup,
    H1,
    H3,
    HTMLSelect,
    InputGroup,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    Tab,
    Tabs,
    Tag,
} from '@blueprintjs/core';
import { ButtonLink } from '../../common/links/button-link';
import { withRouter } from 'react-router';
import { IClusterProps, UIState } from '../../../types';
import { observable } from 'mobx';
import { StatefulComponent } from '../../common/stateful/stateful';
import { ChartMuseumModel } from '../../../models/chartmuseum';
import { ChartCard, ICmChart } from '../../../types/chart';
import {
    MONOSPACE_TEXT,
    RUNNING_TEXT,
    TEXT_MUTED,
    TEXT_SMALL,
} from '@blueprintjs/core/lib/esnext/common/classes';
import { IconNames } from '@blueprintjs/icons';
import { AppCopier } from '../../../service/copier';
import { AppNavigator } from '../../../models/navigation';

@observer
class CmsChartMuseum extends Component<IClusterProps> {
    cmm: ChartMuseumModel;
    @observable uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);
        this.cmm = new ChartMuseumModel();
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/store/', 'Charts');
        this.uiLoadState = UIState.Loading;
        try {
            await this.cmm.load();
            this.uiLoadState = UIState.Loaded;
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    componentDidMount(): void {
        this.load().then();
    }

    componentWillUnmount(): void {
        AppNavigator.clear();
    }

    renderKeywords(chartVersion: ICmChart) {
        const { keywords } = chartVersion;
        if (!keywords) {
            return;
        }
        return keywords.map((keyword) => (
            <span>
                <Tag minimal={true}>{keyword}</Tag>{' '}
            </span>
        ));
    }

    renderMaintainers(chartVersion: ICmChart) {
        const { maintainers } = chartVersion;
        if (!maintainers) {
            return;
        }
        return (
            <div>
                Maintainers:{' '}
                <ul>
                    {maintainers.map((maintainer) => (
                        <li>
                            <a
                                href={'mailto:' + maintainer.email}
                                target={'_blank'} rel="noreferrer"
                            >
                                {maintainer.name || '?'}
                            </a>
                            &nbsp;
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    renderUrls(chartVersion: ICmChart) {
        const { urls } = chartVersion;
        if (!urls) {
            return;
        }
        return (
            <div>
                <b>URLs:</b>
                <ul>
                    {urls.map((url) => (
                        <li className={TEXT_SMALL}>
                            <a href={url} target={'_blank'} rel="noreferrer">
                                {url}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    renderSources(chartVersion: ICmChart) {
        const { sources } = chartVersion;
        if (!sources) {
            return;
        }
        return (
            <div>
                Sources:{' '}
                <ul>
                    {sources.map((source) => (
                        <li>
                            <a href={source} target={'_blank'} rel="noreferrer">
                                {source}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    renderDependencies(chartVersion: ICmChart) {
        const { dependencies } = chartVersion;
        if (!dependencies) {
            return;
        }
        return (
            <div>
                Dependencies:{' '}
                <ul>
                    {dependencies.map((dependency) => (
                        <li key={dependency.name}>
                            {dependency.name} {dependency.version}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    renderChartVersion(chartVersion: ICmChart) {
        const text = `${this.cmm.company}-${this.cmm.department}-${this.cmm.environment}/${chartVersion.name}`;

        return (
            <Tab
                id={`${chartVersion.name}-${chartVersion.version}`}
                title={chartVersion.version}
                panel={
                    <div className="chartVersionPanel">
                        {chartVersion.icon && (
                            <img
                                src={chartVersion.icon}
                                alt={'Icon'}
                                width={100}
                            />
                        )}
                        <p className={RUNNING_TEXT}>
                            {chartVersion.description}
                        </p>
                        <div className="versionInfo flex-spaced">
                            <div>Created: {chartVersion.created}</div>
                            <div>
                                API version:{' '}
                                <span className={MONOSPACE_TEXT}>
                                    {chartVersion.apiVersion || 'n/a'}
                                </span>
                            </div>
                            <div>
                                Application version:{' '}
                                <span className={MONOSPACE_TEXT}>
                                    {chartVersion.appVersion || 'n/a'}
                                </span>
                            </div>
                        </div>

                        {this.renderUrls(chartVersion)}

                        {this.renderMaintainers(chartVersion)}

                        {this.renderDependencies(chartVersion)}

                        {this.renderSources(chartVersion)}

                        <div className="flex-row">
                            <p className={TEXT_SMALL}>
                                Digest: {chartVersion.digest}
                            </p>
                            <p className={TEXT_SMALL}>
                                <ButtonGroup>
                                    <Popover
                                        content={
                                            <Menu>
                                                <MenuItem
                                                    icon={IconNames.CLIPBOARD}
                                                    text="Copy Chart Name"
                                                    onClick={() => {
                                                        AppCopier.copy(text);
                                                    }}
                                                />
                                                <MenuItem
                                                    icon={IconNames.CLIPBOARD}
                                                    text="Copy Version"
                                                    onClick={() => {
                                                        AppCopier.copy(
                                                            `${chartVersion.version}`
                                                        );
                                                    }}
                                                />
                                                <MenuDivider />
                                                <MenuItem
                                                    disabled={
                                                        this.cmm.environment ===
                                                            'production' || true
                                                    }
                                                    icon={IconNames.DELETE}
                                                    text={`Delete ${chartVersion.version}`}
                                                    onClick={() => {
                                                        this.cmm.delete(
                                                            chartVersion
                                                        );
                                                    }}
                                                />
                                            </Menu>
                                        }
                                    >
                                        <Button
                                            minimal={true}
                                            icon={IconNames.MORE}
                                        />
                                    </Popover>
                                </ButtonGroup>
                            </p>
                        </div>
                        {this.renderKeywords(chartVersion)}
                    </div>
                }
            ></Tab>
        );
    }

    renderChartCard(chart: ChartCard) {
        return (
            <Card
                key={chart.name}
                style={{ marginBottom: '10px' }}
                elevation={chart.isSelected ? Elevation.TWO : Elevation.ZERO}
                className={'animated chartCard'}
            >
                <div>
                    {chart.isSelected ? (
                        <div>
                            <div className={'flex-spaced'}>
                                <h3 onClick={() => this.cmm.select(chart.name)}>
                                    {chart.name}
                                </h3>
                                <Button
                                    minimal={true}
                                    icon={IconNames.SMALL_CROSS}
                                    onClick={() => this.cmm.select(chart.name)}
                                >
                                    Close
                                </Button>
                            </div>
                            <Tabs id={chart.name}>
                                {chart.versions.map(
                                    this.renderChartVersion.bind(this)
                                )}
                            </Tabs>
                        </div>
                    ) : (
                        <div
                            className={'chartTitle '}
                            onClick={() => this.cmm.select(chart.name)}
                        >
                            <span className="chartName">{chart.name}</span>
                            <span className={TEXT_MUTED + ' chartDescription'}>
                                {chart.description}
                            </span>
                        </div>
                    )}
                </div>
            </Card>
        );
    }

    renderSearch() {
        return (
            <Callout title={'Search'}>
                <div className={'flex-spaced'}>
                    <FormGroup
                        inline={true}
                        labelFor={'department'}
                        label={'Department'}
                    >
                        <HTMLSelect
                            id={'department'}
                            value={this.cmm.department}
                            onChange={(value) => {
                                this.cmm.setDepartment(
                                    value.currentTarget.value
                                );
                                this.load().then();
                            }}
                            style={{ maxWidth: '120px' }}
                            options={this.cmm.departments}
                        />
                    </FormGroup>
                    <FormGroup
                        inline={true}
                        labelFor={'environment'}
                        label={'Environment'}
                    >
                        <HTMLSelect
                            id={'environment'}
                            style={{ maxWidth: '125px' }}
                            value={this.cmm.environment}
                            onChange={(value) => {
                                this.cmm.setEnvironment(
                                    value.currentTarget.value
                                );
                                this.load().then();
                            }}
                            options={this.cmm.environments}
                        />
                    </FormGroup>
                    <FormGroup
                        inline={true}
                        label="Chart"
                        labelFor="text-input"
                    >
                        <InputGroup
                            id="text-input"
                            placeholder="Name..."
                            value={this.cmm.filter}
                            onChange={this.cmm.filterChangeHandler()}
                        />
                    </FormGroup>
                </div>
                <div className={'flex '}>
                    <FormGroup
                        inline={true}
                        label="Repository"
                        labelFor="text-input"
                    >
                        <InputGroup
                            id="repoUrl"
                            readOnly={true}
                            placeholder="Name..."
                            value={this.cmm.repoUrl}
                            fill={true}
                            size={58}
                        />
                    </FormGroup>
                    <div>
                        <Button
                            minimal={true}
                            icon={IconNames.CLIPBOARD}
                            title="Copy Manifest Definition"
                            onClick={() => {
                                const text = JSON.stringify({
                                    repoUrl: this.cmm.repoUrl,
                                    repoName: `${this.cmm.company}-${this.cmm.department}-${this.cmm.environment}`,
                                });
                                AppCopier.copy(text);
                            }}
                        >
                            Copy
                        </Button>
                    </div>
                </div>
            </Callout>
        );
    }

    render() {
        const items = this.cmm.items;
        console.log(items);
        return (
            <div className="chartMuseumComponent">
                <StatefulComponent state={this.uiLoadState}>
                    <div>
                        <H1>Helm Charts</H1>
                        <div>
                            {this.renderSearch()}
                            <p>&nbsp;</p>
                            <div className="charts">
                                <div className="flex-spaced">
                                    <H3>Found Charts</H3>
                                    <div>
                                        <FileInput
                                            text="Upload chart file..."
                                            onInputChange={this.cmm.uploadHandler()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {items.map(this.renderChartCard.bind(this))}
                                </div>
                            </div>
                        </div>
                    </div>
                </StatefulComponent>
                <ButtonLink to={'/'}>Return</ButtonLink>
            </div>
        );
    }
}

export const ChartMuseumComponent = withRouter<IClusterProps, any>(
    CmsChartMuseum
);
