import { ClusterAwareModel, MaybeCluster } from './cluster-aware';
import { OwnershipPermissions, Permissions } from '../types';
import { action, observable } from 'mobx';
import { ACLService } from '../service/acl-service';
import { ErrorToast, SuccessToast } from '../service/toaster';
import { ISlimUser } from './users';
import { UserModel } from './user-state';

export interface IACLRecord {
    userId: string;
    __version?: string;
    user?: ISlimUser;
    config: Permissions;
    manifest: Permissions;
    own: OwnershipPermissions;
    uiIsUpdating?: boolean;
    uiIsDeleting?: boolean;
}

export class ACLModel extends ClusterAwareModel {
    @observable
    records: IACLRecord[] = [];

    @observable
    nrec: IACLRecord = {
        config: Permissions.ReadAndWrite,
        manifest: Permissions.ReadAndWrite,
        own: OwnershipPermissions.None,
        userId: '',
    };

    @observable
    foundUsers: ISlimUser[] = [];

    @action
    async loadCluster(clusterId: string): Promise<MaybeCluster> {
        return super.loadCluster(clusterId);
    }

    @action
    async loadAcls() {
        if (!this.cluster) return;
        const { items } = await ACLService.listAcls(this.cluster.id);

        if (!items) {
            return;
        }
        this.records = items.filter(aclFilter).sort(aclSorter);
    }

    @action
    async deleteRecord(record: IACLRecord) {
        if (!this.cluster) return;
        if (record.uiIsUpdating) {
            return;
        }

        if (!UserModel.user) return;
        const currentUserId = UserModel.user.id;
        if (
            record.userId === currentUserId &&
            !this.records.some((r) => {
                return (
                    r.userId !== currentUserId &&
                    r.own === OwnershipPermissions.Owner
                );
            })
        ) {
            // this is removal of yourself and there is no one with Ownership
            ErrorToast(
                'You have to delegate ownership to someone if you are going to remove yours. ' +
                    'Orphaned clusters are not permitted.'
            )('');
            this.loadAcls();
            return;
        }

        record.uiIsUpdating = true;
        record.uiIsDeleting = true;
        ACLService.updateAcl(this.cluster.id, {
            __version: record.__version,
            config: Permissions.None,
            userId: record.userId,
            user: record.user,
            manifest: Permissions.None,
            own: OwnershipPermissions.None,
        })
            .then(() => {
                return this.records.splice(this.records.indexOf(record), 1);
            })
            .then(SuccessToast('Record has been deleted!'))
            .then(() => this.loadAcls())
            .catch(ErrorToast('Unable to delete ACL record.'))
            .finally(() => {
                record.uiIsUpdating = undefined;
                record.uiIsDeleting = undefined;
            });
    }

    @action
    async updateRecord(record: IACLRecord) {
        if (!this.cluster) return;
        if (record.uiIsUpdating) return;
        if (!UserModel.user) return;
        const currentUserId = UserModel.user.id;
        if (
            record.own === OwnershipPermissions.None &&
            record.userId === currentUserId &&
            !this.records.some((r) => {
                return (
                    r.userId !== currentUserId &&
                    r.own === OwnershipPermissions.Owner
                );
            })
        ) {
            // this is removal of yourself and there is no one with Ownership
            ErrorToast(
                'You have to delegate ownership to someone if you are going to remove yours. ' +
                    'Orphaned clusters are not permitted.'
            )('');
            this.loadAcls();
            return;
        }

        record.uiIsUpdating = true;
        ACLService.updateAcl(this.cluster.id, record)
            .then(SuccessToast('Record has been updated!'))
            .then(() => this.loadAcls())
            .catch(ErrorToast('Unable to update ACL record.'))
            .finally(() => {
                // whatever?...
                record.uiIsUpdating = undefined;
            });
    }

    @action
    async addRecord() {
        if (!this.cluster) return;
        if (this.nrec.uiIsUpdating) return;
        this.nrec.uiIsUpdating = true;
        ACLService.createAcl(this.cluster.id, this.nrec)
            .then(SuccessToast('Record has been updated!'))
            .then(() => {
                this.nrec.userId = '';
                this.nrec.own = OwnershipPermissions.None;
                this.nrec.config = Permissions.ReadAndWrite;
                this.nrec.manifest = Permissions.ReadAndWrite;
            })
            .then(() => this.loadAcls())
            .catch(ErrorToast('Unable to update ACL record.'))
            .finally(() => {
                // whatever?...
                this.nrec.uiIsUpdating = undefined;
            });
    }

    @action
    async searchUser(query: string) {
        if (!query) return;
        const { items } = await ACLService.searchUsers(query);
        console.log(items);
        this.foundUsers = items;
    }
}

function aclFilter(r: IACLRecord) {
    return !(
        r.config === Permissions.None &&
        r.manifest === Permissions.None &&
        r.own === OwnershipPermissions.None
    );
}

function aclSorter(a: IACLRecord, b: IACLRecord) {
    if (a.userId !== b.userId) {
        if (a.user && b.user) {
            const fc = a.user.firstName.localeCompare(b.user.firstName);
            if (fc !== 0) return fc;
            return a.user.lastName.localeCompare(b.user.lastName);
        } else {
            return a.userId.localeCompare(b.userId);
        }
    }
    return 0;
}
