
import { isNotEmpty } from './validators';
import { IFormParam } from '../../../types/form-param';
import { FormParamTypeEnum } from '../../../types/form-param-type';

export const maintainerEmailParameter: IFormParam = {
    type: FormParamTypeEnum.Email,
    label: 'Maintainer Email',
    k: 'maintainerEmail',
    description: `We will use this email address to notify you of important events about
        your Cradle cluster - certificate renewals, upgrade notices, etc.`,
    v: '',
    required: true,
    isValid: isNotEmpty,
};
