import React, { Component, FormEvent } from 'react';
import { H1, Intent } from '@blueprintjs/core';
import '../edit/edit.scss';
import { IUser } from '../../../models/users';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { UsersEditForm } from '../edit/edit.form';
import { AppToaster, ErrorToast } from '../../../service/toaster';
import { RouteComponentProps, withRouter } from 'react-router';
import { Authentication } from '../../../service/authentication';
import { AppNavigator } from '../../../models/navigation';

interface IUsersAdd extends RouteComponentProps<any> {}

@observer
class UsersAdd extends Component<IUsersAdd> {
    @observable isListLoading = true;
    @observable user!: IUser;

    constructor(props: any) {
        super(props);
        this.user = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            roles: [],
        } as unknown as IUser;
        this.submit = this.submit.bind(this);
    }

    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/users/auth/sign-up/', 'Sign Up');
    }

    redirect() {
        this.props.history.replace('/');
    }

    public submit(event: FormEvent) {
        event.preventDefault();
        if (this.user.email.endsWith('@analog.com')) {
            Authentication.signUp(this.user)
                .then((r) => {
                    AppToaster.show({
                        message: `User ${this.user.email} has been created!`,
                        intent: Intent.SUCCESS,
                    });

                    this.redirect();
                })
                .catch(ErrorToast());
        } else {
            AppToaster.show({
                intent: Intent.DANGER,
                message: 'You have to use an @analog.com email!',
            });
        }
    }

    public render() {
        return (
            <div>
                <H1>Sign Up</H1>
                <p></p>
                <UsersEditForm
                    user={this.user}
                    submit={this.submit}
                    isAddMode={true}
                />
            </div>
        );
    }
}

export const UsersAddComponent = withRouter<IUsersAdd, any>(UsersAdd);
