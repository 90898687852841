import { action, computed, observable } from 'mobx';
import {
    ComponentTemplatesService,
    IDLessCradleComponent,
} from '../service/charts';
import { ICradleComponent } from '../types';

export class CradleComponents {
    private cts: ComponentTemplatesService;

    @observable
    componentsList: ICradleComponent[];

    @observable
    activeComponent: ICradleComponent | undefined;

    @observable
    filter: string;

    @observable
    configurator = {
        isOpen: false,
        name: '',
        url: '',
        login: '',
        password: '',
        repositoryTypes: {
            public: true,
            adi: true,
            private: true,
        },
    };

    constructor() {
        this.filter = '';
        this.componentsList = [];
        this.cts = new ComponentTemplatesService();
    }

    @computed
    get items(): ICradleComponent[] {
        // bit flips

        // search rankings
        return this.componentsList
            .filter((component: ICradleComponent) => {
                return (
                    ((this.configurator.repositoryTypes.public &&
                        component.name.startsWith('stable')) ||
                        (this.configurator.repositoryTypes.adi &&
                            component.name.startsWith('adi')) ||
                        (this.configurator.repositoryTypes.private &&
                            !component.name.startsWith('stable') &&
                            !component.name.startsWith('adi'))) &&
                    (component.name.includes(this.filter) ||
                        component.description.includes(this.filter))
                );
            })
            .sort(this.componentSearchRanker)
            .slice(0, 100);
    }

    componentSearchRanker(a: ICradleComponent, b: ICradleComponent): number {
        return (
            a.name.localeCompare(b.name) * 1000 +
            a.architecture.localeCompare(b.architecture) * 100 +
            a.version.localeCompare(b.version) * 10 +
            a.description.localeCompare(b.description)
        );
    }

    @action
    async load() {
        const { items } = await this.cts.listTemplates();
        this.componentsList = items.map(
            (i: IDLessCradleComponent): ICradleComponent => {
                return { ...i, id: produceId(i) }; // ****ing DynamoDB
            }
        );
    }

    @action
    async addNew(newComponent: ICradleComponent) {
        return this.cts.save(newComponent).then(() => this.load());
    }

    findById(id: string) {
        return this.componentsList.find((c) => c.id === id);
    }
}

function produceId(i: IDLessCradleComponent | ICradleComponent): string {
    return `${i.name}@${i.version}`;
}
