import { HttpApi } from './http-api';
import { IManifest } from '../types/manifest';
import { sp } from '../utils/formatters';

const { REACT_APP_API_CLUSTER_MANIFEST_URL } = process.env;

const urlTemplate =
    REACT_APP_API_CLUSTER_MANIFEST_URL || '/clusters/%s/manifest/';

export const ManifestService = {
    async get(clusterId: string): Promise<IManifest> {
        return HttpApi.get(sp(urlTemplate, clusterId));
    },
    async update(clusterId: string, manifest: IManifest): Promise<IManifest> {
        const data = { manifest };
        return HttpApi.put(sp(urlTemplate, clusterId), data);
    },
};
