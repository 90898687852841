import { withRouter } from 'react-router';
import { Button, IButtonProps } from '@blueprintjs/core';
import React from 'react';

export const ButtonLink = withRouter((props: IButtonProps | any) => {
    // we have to skip staticContext because it gets tied up to a DOM and React goes crazy after it
    const { staticContext, ...rest } = props;
    return (
        <Button
            {...rest}
            onClick={(evt: any) => {
                evt.preventDefault();
                props.onClick && props.onClick(evt);
                props.history.push(props.to);
            }}
            href={props.to}
        />
    );
});
