import { setProperty } from 'dot-prop';
import { HttpApi } from './http-api';
import {
    IaaSProvider,
    ICluster,
    IClusterApiResponse,
    IClusterDeleteApiResponse,
    IClusterDestroyApiResponse,
    IClusterPrepareDestroyApiResponse,
    IClusterProvisionApiResponse,
    IClusterProvisionLogsApiResponse,
    IFormParam,
    isFormComponent,
    SettingsGroup,
    TypeCast,
} from '../types';
import { IProvisionConfiguration } from '../types/provision';
import { sanitizeDomain, sp } from '../utils/formatters';
import { cache, cacheResetDomain } from '../utils/cache';

const {
    REACT_APP_API_CLUSTERS_URL,
    REACT_APP_API_CLUSTER_URL,
    REACT_APP_API_CLUSTER_PROVISION_URL,
    REACT_APP_API_CLUSTER_RESET_PROVISION_STATUS_URL,
    REACT_APP_API_CLUSTER_PROVISION_UP_URL,
    REACT_APP_API_CLUSTER_POKE_URL,
    REACT_APP_API_CLUSTER_PROVISION_LOGS_URL,
    REACT_APP_API_CLUSTER_CREATE_URL,
    REACT_APP_API_CLUSTER_PREPARE_DESTROY_URL,
    REACT_APP_API_CLUSTER_DESTROY_URL,
} = process.env;

export class ClustersService {
    @cacheResetDomain('')
    static async registerCluster(params: any) {
        params = sanitizeClusterHostname(params);
        return HttpApi.put(
            REACT_APP_API_CLUSTER_CREATE_URL || 'clusters/',
            params
        );
    }

    @cache(60)
    static async getCluster(id: string): Promise<ICluster | undefined> {
        return HttpApi.get(REACT_APP_API_CLUSTERS_URL || 'clusters/').then(
            (r) => {
                return r.items.find((v: any) => v.id === id);
            }
        );
    }

    @cache()
    static async listClusters(): Promise<IClusterApiResponse> {
        return HttpApi.get(REACT_APP_API_CLUSTERS_URL || 'clusters/');
    }

    @cacheResetDomain('')
    static async deleteCluster(id: string): Promise<IClusterDeleteApiResponse> {
        return HttpApi.delete(
            sp(REACT_APP_API_CLUSTER_URL || 'clusters/%s', id)
        );
    }

    @cacheResetDomain('')
    static async resetCluster(id: string): Promise<IClusterDeleteApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_RESET_PROVISION_STATUS_URL ||
                    'clusters/%s/reset-provision-status',
                id
            ),
            { reset: true }
        );
    }

    @cacheResetDomain('')
    static async saveSettings(
        id: string,
        params: IProvisionConfiguration,
        provider: IaaSProvider
    ): Promise<IClusterProvisionApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_PROVISION_URL ||
                    'clusters/%s/provision/%s',
                id,
                provider
            ),
            convertProvisionParams(params, provider)
        );
    }

    @cacheResetDomain('')
    static async prepareDestroy(
        id: string,
        params: IProvisionConfiguration,
        provider: IaaSProvider
    ): Promise<IClusterPrepareDestroyApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_PREPARE_DESTROY_URL ||
                    'clusters/%s/destroy/%s',
                id,
                provider
            ),
            convertProvisionParams(params, provider)
        );
    }

    @cacheResetDomain('')
    static async destroyCluster(
        id: string,
        params: any
    ): Promise<IClusterDestroyApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_DESTROY_URL || 'clusters/%s/stand-down/',
                id
            ),
            params
        );
    }

    @cacheResetDomain('')
    static async provisionCluster(
        id: string,
        params: any
    ): Promise<IClusterProvisionApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_PROVISION_UP_URL ||
                    'clusters/%s/stand-up/',
                id
            ),
            params
        );
    }

    @cacheResetDomain('')
    static async updateCluster(
        cluster: ICluster
    ): Promise<IClusterProvisionApiResponse> {
        cluster = sanitizeClusterHostname(cluster);
        return HttpApi.put(
            sp(REACT_APP_API_CLUSTER_URL || 'clusters/%s/', cluster.id),
            {
                clusterName: cluster.clusterName,
                hostname: cluster.hostname,
                comments: cluster.comments,
            }
        );
    }

    @cache(10)
    static async getProvisionLogs(
        id: string
    ): Promise<IClusterProvisionLogsApiResponse> {
        return HttpApi.get(
            sp(
                REACT_APP_API_CLUSTER_PROVISION_LOGS_URL || 'clusters/%s/logs/',
                id
            )
        );
    }

    @cacheResetDomain('')
    static async pokeCluster(id: string): Promise<[]> {
        return HttpApi.post(
            sp(REACT_APP_API_CLUSTER_POKE_URL || 'clusters/%s/poke/', id),
            { poke: true }
        );
    }
}

function sanitizeClusterHostname(cluster: ICluster) {
    if (!cluster.hostname || cluster.hostname.length === 0) {
        cluster.hostname = sanitizeDomain(cluster.clusterName);
    }
    return cluster;
}

export function convertProvisionParams(
    params: IProvisionConfiguration,
    provider: IaaSProvider
) {
    const data: any = {};
    const mapper = (p: IFormParam) => {
        console.log('p:', p.type, p.k);
        if (isFormComponent(p)) {
            console.log('fc:', p.v);

            if (p.cast !== undefined) {
                switch (p.cast) {
                    case TypeCast.Number:
                        setProperty(data, p.k, parseInt(p.v, 10));
                        break;
                    case TypeCast.Boolean:
                        setProperty(data, p.k, p.v === 'true');
                        break;
                    case TypeCast.CSVStrings:
                        setProperty(
                            data,
                            p.k,
                            p.v
                                .split(',')
                                .map((s) => s.trim())
                                .filter((s) => !!s)
                        );
                        break;
                    case TypeCast.String:
                        setProperty(data, p.k, p.v.toString());
                        break;
                    default:
                        console.log('Unknown cast', p.cast);
                }
            } else {
                setProperty(data, p.k, p.v);
            }
        } else if (p.children) {
            console.log('params', p.children);
            p.children.map(mapper);
        } else {
            console.log('wtf?');
        }
    };
    params[SettingsGroup.Basic].map(mapper);
    params[SettingsGroup.Advanced].map(mapper);

    if (provider === IaaSProvider.AWS) {
        data['eksCtlPatch'] = JSON.stringify(filterEmptyValues({
            vpc: data['vpc'],
            privateNetworking: data['privateNetworking'],
        }) || {});
        ['vpc', 'privateNetworking'].forEach((prop) => {
            if (typeof data[prop] !== 'undefined') {
                delete data[prop];
            }
        });
    }

    data['infrastructureProvider'] = provider;
    return data;
}


function filterEmptyValues(obj: any) {
    if (typeof obj === 'object') {
        let nobj: any = {};
        for (let k in obj) {
            const r = filterEmptyValues(obj[k]);
            if (r) {
                nobj[k] = r;
            }
        }
        return Object.entries(nobj).length ? nobj : undefined;
    } else if (Array.isArray(obj)) {
        let narr: any = [];
        for (let k of obj) {
            const r = filterEmptyValues(narr[k]);
            if (r) {
                narr.push(r);
            }
        }
        return narr.length ? narr : undefined;
    } else if (obj) {
        return obj;
    }
}
