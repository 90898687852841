import { ItemRenderer } from '@blueprintjs/select';
import { IItemRendererProps } from '@blueprintjs/select/src/common/itemRenderer';
import { MenuItem } from '@blueprintjs/core';
import React from 'react';
import { ISolution } from '../../../../types/solution';

export const solutions: ISolution[] = [
    {
        title: 'ToF Edge | ARM64',
        description: 'Time of flight edge baseline.',
        components: [
            'gateway:arm64@1.0.0',
            'rabbitmq:arm64@3.8.0',
            'cradle-depth-compute:arm64@1.0.0',
            'cradle-http-bridge-client:arm64@0.1.1',
            'cradle-watch-rabbitmq:arm64@1.0.0',
        ],
    },

    {
        title: 'ToF Cloud | AMD64',
        description: 'Time of flight cloud baseline.',
        components: [
            'gateway:amd64@2.1.1',
            'cradle-shell:amd64@1.0.0',
            'rabbitmq:amd64@2.1.3',

            'ws-img-demo:amd64@0.1.0',
            'cradle-depth-compute:amd64@1.0.0',

            'box-dimensioning-pc:amd64@1.0.0',
            'box-dimensioning-gui:amd64@1.0.0',

            'cradle-http-bridge-server:amd64@1.0.0',
            'cradle-watch-rabbitmq:amd64@1.0.0',
        ],
    },

    {
        title: 'Minimal',
        description: 'Clean start with web-shell only.',
        components: ['gateway:amd64@1.0.0', 'cradle-shell:amd64@1.0.0'],
    },
    {
        title: 'Essentials',
        description:
            'Basic layer of components, including queue, NoSQL Database & cache',
        components: [
            'mongodb:amd64@0.2.2',
            'rabbitmq:amd64@2.1.1',
            'redis:amd64@1.0.0',
            'gateway:amd64@1.0.0',
            'cradle-shell:amd64@1.0.0',
        ],
    },
    {
        title: 'IoT Starter',
        description: 'Devices & Users Management, NoSQL DB, Cache, Queues.',
        components: [
            'mongodb:amd64@0.2.2',
            'rabbitmq:amd64@2.1.1',
            'redis:amd64@1.0.0',
            'gateway:amd64@1.0.0',
            'pipeline-json-parser:amd64@1.0.0',
            'device-management:amd64@1.0.0',
            'agc-user-api:amd64@1.0.0',
            'agc-user-ui:amd64@1.0.0',
            'cradle-shell:amd64@1.0.0',
        ],
    },
];
export const solutionsRenderer: ItemRenderer<ISolution> = (
    solution: ISolution,
    itemProps: IItemRendererProps
) => {
    const { handleClick, modifiers } = itemProps;
    if (!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            text={
                <div>
                    <b>{solution.title.toString()}</b>
                    <br />
                    <small>{solution.description}</small>
                </div>
            }
            key={solution.title}
            onClick={handleClick}
        />
    );
};

export function areSolutionsEqual(a: ISolution, b: ISolution) {
    return a.title === b.title;
}
