import { action, computed, observable } from 'mobx';
import { IBreadcrumb } from '../types/breadcrumb';

export class NavigationModel {
    @observable
    breadcrumbs: IBreadcrumb[];

    constructor() {
        this.breadcrumbs = [];
    }

    @computed
    get title() {
        if (this.breadcrumbs.length === 0) {
            return 'Clusters Management';
        }
        return this.breadcrumbs[this.breadcrumbs.length - 1].text + ' - Cradle';
    }

    @computed
    get items() {
        return this.breadcrumbs;
    }

    @action
    clear() {
        this.breadcrumbs = [];
    }

    @action
    add(href: string, text: string) {
        return this.push({
            href,
            text,
        });
    }

    @action
    push(breadcrumb: IBreadcrumb) {
        return this.breadcrumbs.push(breadcrumb);
    }
}

export const AppNavigator = new NavigationModel();
