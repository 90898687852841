import React, { ReactElement } from 'react';

export const tips: IProvisionTip[] = [
    {
        content: (
            <div key={'tip1'}>
                <p>
                    We're working on your Cradle Cluster, and you can follow
                    along by looking at the log messages.
                </p>
                <p>
                    Depending on your infrastructure, this process might take
                    30-45 minutes to complete, so go get yourself a coffee, kick
                    back and relax.
                </p>
                <p>
                    You can also use the arrow buttons below to check out some
                    useful links, videos, tips and tricks while you wait.
                </p>
            </div>
        ),
    },
    {
        content: (
            <div key={'tip2'}>
                <h5>Kubernetes in 5 mins? Nailed it!</h5>

                <iframe
                    width="220"
                    height="200"
                    title='K8s'
                    src="https://www.youtube.com/embed/PH-2FfFD2PU"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        ),
    },
    {
        content: (
            <div key={'tip3'}>
                <h5>Learn about Helm Charts </h5>
                <p>
                    Building great stuff is not easy so this video will help you
                    to get this ball rolling.
                </p>

                <iframe
                    width="220"
                    height="200"
                    src="https://www.youtube.com/embed/vQX5nokoqrQ"
                    frameBorder="0"
                    title='chars'
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        ),
    },
    {
        content: (
            <div key={'tip3'}>
                <h4>Useful links</h4>
                <ul>
                    <li>
                        <a
                            href="https://cradle.analoggarage.com/docs/#/?id=main"
                            target="_blank" rel="noreferrer"
                        >
                            Cradle documentation
                        </a>
                    </li>
                </ul>
            </div>
        ),
    },
];

export interface IProvisionTip {
    content: string | ReactElement;
}

export const PROVISION_LOG_TIPS_STORAGE_KEY = 'showProvisionLogTips';
