import { UIState } from '../../../types/uistate';
import { Intent, NonIdealState, Spinner } from '@blueprintjs/core';
import React from 'react';

export interface IStatefulComponentProps {
    state: UIState;
    className?: string;
    errorMessage?: string;
}

export class StatefulComponent extends React.PureComponent<
    IStatefulComponentProps,
    {}
> {
    public render() {
        const { state, children, className, errorMessage } = this.props;
        switch (state) {
            case UIState.Loading:
                return <Spinner intent={Intent.PRIMARY} />;

            case UIState.Failed:
                return (
                    <NonIdealState
                        title={
                            errorMessage ? errorMessage : 'No data available'
                        }
                    />
                );

            case UIState.Loaded:
                return <div className={className}>{children}</div>;

            case UIState.Empty:
                return <div />;
        }
    }
}
