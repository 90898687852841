import {
    Breadcrumb,
    Breadcrumbs,
    Classes,
    IBreadcrumbProps,
} from '@blueprintjs/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { IBreadcrumb } from '../../../types';

import './cms-breadcrumbs.scss';

export interface ICmsBreadcrumbsProps {
    breadcrumbs: IBreadcrumb[];
}

@observer
export class CmsBreadcrumbs extends Component<ICmsBreadcrumbsProps> {
    public render() {
        const { breadcrumbs } = this.props;
        return (
            <ul
                className={classNames(
                    Classes.BREADCRUMBS,
                    'cmsBreadcrumbsComponent'
                )}
            >
                {' '}
                {breadcrumbs.map((b, i) => {
                    const classes = classNames(
                        Classes.BREADCRUMB,
                        i !== breadcrumbs.length - 1
                            ? Classes.BREADCRUMB_CURRENT
                            : undefined
                    );
                    return (
                        <li key={i} className={classes}>
                            <Link to={b.href} title={b.text}>
                                {b.text}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

@observer
export class SubheaderBreadcrumbs extends Component<ICmsBreadcrumbsProps> {
    public render() {
        const { breadcrumbs } = this.props;
        return (
            <div style={{ marginTop: '0px', marginBottom: '1em' }}>
                <Breadcrumbs
                    currentBreadcrumbRenderer={this.renderCurrentBreadcrumb}
                    items={breadcrumbs}
                />
            </div>
        );
    }

    private renderCurrentBreadcrumb = ({
        text,
        ...restProps
    }: IBreadcrumbProps) => {
        // customize rendering of last breadcrumb
        return <Breadcrumb {...restProps}>{text} </Breadcrumb>;
    };
}
