import { HttpApi } from './http-api';
import { ICertificateAuthority } from '../types/ca';
import { Dictionary } from '../types/dictionary';
import { ICertificate } from '../types/certificate';
import { sp } from '../utils/formatters';
import { INewCertificate } from '../models/certs';
import {
    IClusterCreateKeyApiResponse,
    IClusterKeysApiResponse,
} from '../types/cluster-key';
import { cache, cacheResetDomain } from '../utils/cache';

export interface ICASApiResponse {
    items: ICertificateAuthority[];
}

export interface ICertificatesListApiResponse {
    items: Dictionary<string>[];
}

export interface ICreateCertificateApiResponse extends ICertificate {}

const {
    REACT_APP_API_CLUSTER_CERTIFICATE_AUTHORITIES,
    REACT_APP_API_CLUSTER_CERTIFICATES,
    REACT_APP_API_CLUSTER_CERTIFICATE,
    REACT_APP_API_CLUSTER_API_KEYS,
    REACT_APP_API_CLUSTER_API_KEY,
} = process.env;

export class KnoxService {
    @cache()
    static async listCertificateAuthorities(
        clusterId: string
    ): Promise<ICASApiResponse> {
        return HttpApi.get(
            sp(
                REACT_APP_API_CLUSTER_CERTIFICATE_AUTHORITIES ||
                    'clusters/%s/certificate-authorities/',
                clusterId
            )
        );
    }

    @cacheResetDomain('')
    static async createCertificateAuthorities(
        ca: ICertificateAuthority
    ): Promise<ICASApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_CERTIFICATE_AUTHORITIES ||
                    'clusters/%s/certificate-authorities/',
                ca.projectName || '-'
            ),
            ca
        );
    }

    @cache()
    static async listCertificates(
        clusterId: string,
        ca: string
    ): Promise<ICertificatesListApiResponse> {
        return HttpApi.get(
            sp(
                REACT_APP_API_CLUSTER_CERTIFICATES ||
                    'clusters/%s/certificate-authorities/%s/certificates/',
                clusterId,
                ca
            )
        );
    }

    @cache()
    static async getCertificate(
        clusterId: string,
        ca: string,
        cn: string
    ): Promise<ICreateCertificateApiResponse> {
        return HttpApi.get(
            sp(
                REACT_APP_API_CLUSTER_CERTIFICATE ||
                    'clusters/%s/certificate-authorities/%s/certificates/%s/',
                clusterId,
                ca,
                cn
            )
        );
    }

    @cacheResetDomain('')
    static async createCertificate(
        clusterId: string,
        data: INewCertificate
    ): Promise<ICreateCertificateApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_CERTIFICATES ||
                    'clusters/%s/certificate-authorities/%s/certificates/',
                clusterId,
                data.certificateAuthorityCN
            ),
            data
        );
    }

    @cacheResetDomain('')
    static async deleteCertificate(
        clusterId: string,
        ca: string,
        cn: string
    ): Promise<any> {
        return HttpApi.delete(
            sp(
                REACT_APP_API_CLUSTER_CERTIFICATE ||
                    'clusters/%s/certificate-authorities/%s/certificates/%s/',
                clusterId,
                ca,
                cn
            )
        );
    }

    @cache()
    static async listKeys(clusterId: string): Promise<IClusterKeysApiResponse> {
        return HttpApi.get(
            sp(REACT_APP_API_CLUSTER_API_KEYS || 'clusters/%s/keys/', clusterId)
        );
    }

    @cache()
    static async getKey(
        clusterId: string,
        keyName: string
    ): Promise<IClusterCreateKeyApiResponse> {
        return HttpApi.get(
            sp(
                REACT_APP_API_CLUSTER_API_KEY || 'clusters/%s/keys/%s/',
                clusterId,
                keyName
            )
        );
    }

    @cacheResetDomain('')
    static async createKey(
        clusterId: string,
        key: any
    ): Promise<IClusterCreateKeyApiResponse> {
        return HttpApi.post(
            sp(
                REACT_APP_API_CLUSTER_API_KEYS || 'clusters/%s/keys/',
                clusterId
            ),
            key
        );
    }

    @cacheResetDomain('')
    static async deleteKey(
        clusterId: string,
        keyName: string
    ): Promise<string> {
        return HttpApi.delete(
            sp(
                REACT_APP_API_CLUSTER_API_KEY || 'clusters/%s/keys/%s',
                clusterId,
                keyName
            )
        );
    }
}
