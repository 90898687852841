import { Intent, Position, Toaster } from '@blueprintjs/core';

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
    className: 'recipe-toaster',
    position: Position.TOP,
});

export const SuccessToast = (message: string = '') => {
    return function (r: any) {
        AppToaster.show({
            intent: Intent.SUCCESS,
            message,
        });
        return r;
    };
};

export const ErrorToast = (message: string = '') => {
    return (e: any) => {
        AppToaster.show({
            intent: Intent.DANGER,
            message: [message, e.message].join(' '),
        });
    };
};
