import React, { Component, FormEvent } from 'react';

import './clusters-list.scss';
import { observer } from 'mobx-react';
import {
    Alert,
    Button,
    ButtonGroup,
    Callout,
    Card,
    Classes,
    Collapse,
    Dialog,
    EditableText,
    FormGroup,
    H1,
    InputGroup,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    Position,
    Tag,
    Tooltip,
} from '@blueprintjs/core';
import classNames from 'classnames';
import { ButtonLink } from '../../common/links/button-link';
import { IconNames } from '@blueprintjs/icons';
import { ClustersModel } from '../../../models/clusters';
import { AppToaster, ErrorToast, SuccessToast } from '../../../service/toaster';
import {
    ActivationStatusEnum,
    ICluster,
    ProvisionStatusEnum,
    UIState,
} from '../../../types';
import { StatefulComponent } from '../../common/stateful/stateful';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppNavigator } from '../../../models/navigation';
import { Link } from 'react-router-dom';
import MenuItemLink from '../../common/links/menuitem-link';
import { camelize } from 'tslint/lib/utils';

export interface IClustersListParams extends RouteComponentProps<any> {}

@observer
class ClustersList extends Component<IClustersListParams, any> {
    cm: ClustersModel;

    constructor(props: any) {
        super(props);
        this.cm = new ClustersModel();
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');
        try {
            await this.cm.getClusters();
        } catch (e) {
            this.cm.uiLoadState = UIState.Failed;
            const { message } = e;
            console.log(e);
            AppToaster.show({
                intent: Intent.DANGER,
                message,
            });
        }
    }

    componentWillUnmount(): void {
        AppNavigator.clear();
    }

    componentDidMount() {
        this.load().then();
    }

    public render() {
        const { cm } = this;
        return (
            <div className="clustersListComponent">
                {this.renderNewClusterDialog()}
                {this.renderConfirmClusterDeleteDialog()}
                {this.renderConfirmClusterResetDialog()}

                <div className="clustersHeading">
                    <H1 className={Classes.HEADING}>Clusters</H1>
                    <Button
                        minimal={true}
                        icon={IconNames.ADD}
                        intent={Intent.PRIMARY}
                        onClick={() => this.cm.openCreateClusterDialog()}
                    >
                        Create New
                    </Button>
                </div>

                <div>
                    <StatefulComponent state={this.cm.uiLoadState}>
                        {cm.items.length === 0 ? (
                            <div className="clustersBox">
                                <p className={'lead'}>
                                    Hi there, looks like you are starting fresh.
                                    <br />
                                    Go ahead and{' '}
                                    <a
                                        href="#test"
                                        onClick={() =>
                                            this.cm.openCreateClusterDialog()
                                        }
                                    >
                                        create
                                    </a>{' '}
                                    your first cluster!
                                </p>
                            </div>
                        ) : (
                            <div className="clustersBox">
                                {cm.items.map((cluster) => (
                                    <Card
                                        key={cluster.id}
                                        className={'card'}
                                        interactive={true}
                                    >
                                        <Callout
                                            intent={this.getIntent(cluster)}
                                            icon={this.getIcon(cluster)}
                                            title={cluster.clusterName}
                                        >
                                            <div className={Classes.TEXT_SMALL}>
                                                <b>{cluster.hostname}</b>
                                            </div>
                                            <div className={Classes.TEXT_SMALL}>
                                                <b
                                                    className={
                                                        Classes.TEXT_MUTED
                                                    }
                                                >
                                                    Status:
                                                </b>{' '}
                                                {mapClusterProvisionStatus(
                                                    cluster.provisionStatus
                                                )}
                                                .
                                            </div>
                                            <div
                                                className={classNames(
                                                    Classes.TEXT_MUTED,
                                                    Classes.TEXT_SMALL
                                                )}
                                                title={'Cluster Identifier'}
                                            >
                                                {getExtendedStatus(cluster)}.
                                            </div>
                                        </Callout>
                                        <div title={'Description'}>
                                            <EditableText
                                                multiline={true}
                                                className={'description'}
                                                disabled={
                                                    cluster.isUpdating || false
                                                }
                                                value={cluster.comments}
                                                onChange={(e: string) => {
                                                    cluster.comments =
                                                        e.replace(/\n/g, '');
                                                }}
                                                onConfirm={() => {
                                                    if (cluster.isUpdating)
                                                        return;
                                                    cluster.isUpdating = true;
                                                    const { clusterName } =
                                                        cluster;
                                                    this.cm
                                                        .updateCluster(cluster)
                                                        .then(
                                                            SuccessToast(
                                                                `Cluster ${clusterName} has been updated!`
                                                            )
                                                        )
                                                        .catch(ErrorToast())
                                                        .finally(() => {
                                                            cluster.isUpdating =
                                                                false;
                                                        });
                                                }}
                                            />
                                        </div>
                                        <div className="flex-row">
                                            {this.clusterActions(cluster)}
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        )}
                    </StatefulComponent>
                </div>
            </div>
        );
    }

    getIcon(cluster: ICluster) {
        switch (cluster.provisionStatus) {
            case ProvisionStatusEnum.Unprovisioned:
                return IconNames.CUBE;
            case ProvisionStatusEnum.Destroying:
            case ProvisionStatusEnum.Provisioning:
                return IconNames.TIME;
            case ProvisionStatusEnum.Provisioned:
                return IconNames.TICK_CIRCLE;
            case ProvisionStatusEnum.DestroyFailed:
            case ProvisionStatusEnum.ProvisionFailed:
                return IconNames.CROSS;
        }
    }

    getIntent(cluster: ICluster): Intent {
        if (cluster.activeStatus === ActivationStatusEnum.Inactive) {
            return Intent.NONE;
        }
        switch (cluster.provisionStatus) {
            case ProvisionStatusEnum.Unprovisioned:
                return Intent.PRIMARY;
            case ProvisionStatusEnum.Destroying:
            case ProvisionStatusEnum.Provisioning:
                return Intent.WARNING;
            case ProvisionStatusEnum.Provisioned:
                return Intent.SUCCESS;
            case ProvisionStatusEnum.DestroyFailed:
            case ProvisionStatusEnum.ProvisionFailed:
                return Intent.DANGER;
        }
        return Intent.NONE;
    }

    renderNewClusterDialog() {
        const { cluster, isCreating, isOpen, isDisabled, isDescriptionOpen } =
            this.cm.newClusterDialog;
        return (
            <Dialog
                isOpen={isOpen}
                onClose={() => this.cm.dismissCreateClusterDialog()}
                canEscapeKeyClose={false}
                usePortal={true}
                autoFocus={true}
                canOutsideClickClose={false}
                isCloseButtonShown={!isCreating}
                title="Create New Cluster"
            >
                <form
                    onSubmit={(e) => {
                        this.cm.createCluster(e).then((r) => {
                            this.props.history.push(
                                `/clusters/${r.clusterId}/provision/`
                            );
                        });
                    }}
                    action={'#'}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label={'Cluster Name'}
                            labelFor="clusterName-input"
                            labelInfo={'(required)'}
                            helperText={
                                'Use a unique name. This cannot be changed later.'
                            }
                        >
                            <InputGroup
                                disabled={isDisabled}
                                required={true}
                                id="clusterName-input"
                                placeholder="my-project"
                                type={'text'}
                                name={'clusterName'}
                                value={cluster.clusterName}
                                onChange={(e: FormEvent<HTMLInputElement>) => {
                                    this.cm.newClusterFieldChanged(e);
                                }}
                                minLength={1}
                                autoFocus={true}
                            />
                        </FormGroup>
                        <FormGroup
                            label={'Hostname'}
                            labelFor="clusterHostname-input"
                            labelInfo={'(required)'}
                            helperText={'You have to use domain name here.'}
                        >
                            <InputGroup
                                disabled={isDisabled}
                                required={true}
                                id="clusterHostname-input"
                                placeholder="my.project.com"
                                type={'text'}
                                name={'hostname'}
                                minLength={1}
                                value={cluster.hostname}
                                onChange={(e: FormEvent<HTMLInputElement>) => {
                                    this.cm.newClusterFieldChanged(e);
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label={'Description'}
                            labelFor="comments-input"
                            labelInfo={'(required)'}
                            helperText={
                                <div id='toggleCreateClusterDescription'>
                                    Add your notes about this cluster
                                    <a
                                        onClick={() =>
                                            this.cm.toggleCreateClusterDescription()
                                        }
                                        href="#toggleCreateClusterDescription"
                                        hidden={isDescriptionOpen}
                                    >
                                        .
                                    </a>
                                    <Collapse
                                        component={'span'}
                                        isOpen={isDescriptionOpen}
                                    >
                                        Collapsed content
                                    </Collapse>
                                </div>
                            }
                        >
                            <InputGroup
                                disabled={isDisabled}
                                required={true}
                                minLength={1}
                                id="comments-input"
                                placeholder="Infrastructure for Factory A deployment."
                                type={'text'}
                                name={'comments'}
                                value={
                                    this.cm.newClusterDialog.cluster.comments
                                }
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    this.cm.newClusterFieldChanged(e)
                                }
                            />
                        </FormGroup>

                        <Callout
                            intent={
                                isCreating ? Intent.WARNING : Intent.PRIMARY
                            }
                        >
                            {isCreating ? (
                                <div>
                                    <p>
                                        Please be patient while we register and
                                        activate a new Cradle cluster for you.{' '}
                                    </p>
                                    <p>
                                        This might take up to 30 seconds to
                                        complete.
                                    </p>
                                </div>
                            ) : (
                                <div>
                                    <p>
                                        This action will register a new cluster
                                        for you.
                                    </p>
                                    <p>
                                        Next, we will help you deploy Cradle
                                        onto actual hardware or you can choose
                                        to do it later using the{' '}
                                        <Tag minimal={true}>Install</Tag>{' '}
                                        button.
                                    </p>
                                </div>
                            )}
                        </Callout>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() =>
                                    this.cm.dismissCreateClusterDialog()
                                }
                                disabled={this.cm.newClusterDialog.isDisabled}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    this.cm.newClusterDialog.isDisabled ||
                                    !this.cm.newClusterDialog.isValid
                                }
                                loading={this.cm.newClusterDialog.isCreating}
                                type={'submit'}
                                intent={Intent.PRIMARY}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }

    renderConfirmClusterDeleteDialog() {
        const { cluster } = this.cm.deleteClusterDialog;
        if (!cluster) return;
        return (
            <Alert
                intent={Intent.DANGER}
                icon={IconNames.TRASH}
                {...this.cm.deleteClusterDialog}
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
            >
                <p>
                    You are about to delete{' '}
                    {cluster && <b>{cluster.clusterName}</b>}
                </p>
            </Alert>
        );
    }

    renderConfirmClusterResetDialog() {
        const { cluster } = this.cm.resetClusterDialog;
        if (!cluster) return;
        return (
            <Alert
                intent={Intent.WARNING}
                icon={IconNames.WARNING_SIGN}
                {...this.cm.resetClusterDialog}
                cancelButtonText="Cancel"
                confirmButtonText="Reset"
            >
                <p>
                    Warning: This action will reset the{' '}
                    {cluster && <b>{cluster.clusterName}</b>} state, but does
                    not clean up any software that may be partially installed.
                </p>
                <p>Are you sure you want to proceed?</p>
            </Alert>
        );
    }

    public clusterActions(cluster: ICluster) {
        const { id } = cluster;

        const extraMenuPopover = (
            <Popover
                key={`ClusterExtraMenu${id}`}
                content={this.extraMenu(cluster)}
                position={Position.BOTTOM}
            >
                <Button icon={IconNames.MORE} text={''} />
            </Popover>
        );

        const viewSecretsButton = (
            <Tooltip
                key={`ClusterSecretsBtn${id}`}
                content={'View keys and certificates'}
            >
                <ButtonLink
                    to={`/clusters/${id}/secrets/`}
                    icon={IconNames.KEY}
                    text={'Secrets'}
                />
            </Tooltip>
        );


        const clusterManageSoftwareButton = (
            <Popover
                key={`ClusterMSBtn${id}`}
                content={this.manifestMenu(cluster)}
                position={Position.BOTTOM}
            >
                <Button
                    icon={IconNames.DOCUMENT}
                    text={
                        <span>
                            <span className={'hidden-xs'}>Manage</span> Software
                        </span>
                    }
                    rightIcon={IconNames.CARET_DOWN}
                />
            </Popover>
        );

        const installButton = (
            <Tooltip
                key={`ClusterInstallBtn${id}`}
                content="Start provisioning by specifying cluster's details"
                position={Position.TOP}
            >
                <ButtonLink
                    to={`/clusters/${id}/provision/`}
                    icon={IconNames.COMPRESSED}
                    text={'Install'}
                />
            </Tooltip>
        );

        const shellInButton = (
            <Tooltip
                key={`ClusterShell${id}`}
                content={
                    <div>
                        <p>Open web-shell to manage cluster.</p>
                        <p>
                            Login name is <kbd>user</kbd>.
                        </p>
                        <p>
                            Password is <kbd>cradle-shell-password</kbd> key in
                            Secrets.
                        </p>
                    </div>
                }
                position={Position.TOP}
            >
                <ButtonLink
                    onClick={() => {
                        window.open(`https://${cluster.hostname}/shell/`);
                    }}
                    icon={IconNames.CONSOLE}
                >
                    Shell-In
                </ButtonLink>
            </Tooltip>
        );

        const buttons: React.ReactNode[] = [];

        switch (cluster.provisionStatus) {
            case ProvisionStatusEnum.ProvisionFailed:
                buttons.push(
                    <Tooltip
                        key={`ClusterReinstall${id}`}
                        content="Restart provisioning by specifying cluster's details"
                        position={Position.TOP}
                    >
                        <ButtonLink
                            to={`/clusters/${id}/provision/`}
                            icon={IconNames.REDO}
                            text={'Reinstall'}
                        />
                    </Tooltip>,
                    viewSecretsButton,
                    clusterManageSoftwareButton,
                    extraMenuPopover
                );
                break;
            case ProvisionStatusEnum.Unprovisioned:
                buttons.push(
                    installButton,
                    viewSecretsButton,
                    clusterManageSoftwareButton,
                    extraMenuPopover
                );
                break;
            case ProvisionStatusEnum.Provisioning:
                buttons.push(
                    viewSecretsButton,
                    clusterManageSoftwareButton,
                    extraMenuPopover
                );
                break;
            case ProvisionStatusEnum.Provisioned:
                buttons.push(
                    viewSecretsButton,
                    clusterManageSoftwareButton,
                    shellInButton,
                    extraMenuPopover
                );
                break;
            case ProvisionStatusEnum.Destroying:
                buttons.push(
                    viewSecretsButton,
                    clusterManageSoftwareButton,
                    extraMenuPopover
                );
                break;
            case ProvisionStatusEnum.DestroyFailed:
                buttons.push(
                    <Tooltip
                        key={`ClusterDestroy${id}`}
                        content="Restart destroy"
                        position={Position.TOP}
                    >
                        <ButtonLink
                            to={`/clusters/${id}/destroy/`}
                            icon={IconNames.UNARCHIVE}
                            text={'Uninstall'}
                        />
                    </Tooltip>,
                    clusterManageSoftwareButton,
                    viewSecretsButton,
                    extraMenuPopover
                );
                break;
        }

        return <ButtonGroup key={`ClusterButtons${id}`} children={buttons} />;
    }

    extraMenu(cluster: ICluster) {
        const c = (
            <MenuItemLink
                key={`emc${cluster.id}`}
                icon={IconNames.CLIPBOARD}
                text={'Copy Id'}
                onClick={() => {
                    navigator.clipboard.writeText(cluster.id).then(() => {
                        AppToaster.show({
                            intent: Intent.SUCCESS,
                            message: 'Copied!',
                        });
                    });
                }}
            />
        );

        const d = <MenuDivider key={`emd${cluster.id}`} />;

        const pl = (
            <MenuItemLink
                key={`empl${cluster.id}`}
                icon={IconNames.LIST}
                text={'View Logs'}
                to={'/clusters/' + cluster.id + '/logs/'}
            />
        );

        const acl = (
            <MenuItemLink
                key={`acl${cluster.id}`}
                icon={IconNames.PEOPLE}
                text={'Share'}
                to={'/clusters/' + cluster.id + '/acl/'}
            />
        );

        const del = (
            <MenuItem
                key={`emdc${cluster.id}`}
                icon={IconNames.CUBE_REMOVE}
                text={'Delete'}
                intent={Intent.DANGER}
                onClick={() => {
                    this.cm.deleteClusterDialog.confirm(cluster);
                }}
            />
        );

        const un = (
            <MenuItemLink
                key={`emdc${cluster.id}`}
                icon={IconNames.CUBE_REMOVE}
                text={'Uninstall'}
                intent={Intent.DANGER}
                to={'/clusters/' + cluster.id + '/destroy/'}
            />
        );

        const rst = (
            <MenuItem
                key={`emdc${cluster.id}`}
                icon={IconNames.REDO}
                text={'Abort'}
                intent={Intent.WARNING}
                onClick={() => {
                    this.cm.resetClusterDialog.confirm(cluster);
                }}
            />
        );

        const items = [c, pl, acl, d];

        switch (cluster.provisionStatus) {
            case ProvisionStatusEnum.Unprovisioned:
                items.push(del);
                break;
            case ProvisionStatusEnum.Provisioning:
                items.push(rst);
                break;
            case ProvisionStatusEnum.Provisioned:
                items.push(un);
                break;
            case ProvisionStatusEnum.ProvisionFailed:
                items.push(rst);
                break;
            case ProvisionStatusEnum.Destroying:
                items.push(rst);

                break;
            case ProvisionStatusEnum.DestroyFailed:
                items.push(rst);
                break;
        }
        return <Menu key={`eM${cluster.id}`}>{items}</Menu>;
    }

    manifestMenu(cluster: ICluster) {
        return (
            <Menu>
                <MenuItemLink
                    icon={IconNames.EDIT}
                    text={'Edit Manifest'}
                    to={'/clusters/' + cluster.id + '/manifest/edit/'}
                />
                <MenuDivider />
                <MenuItemLink
                    disabled={false}
                    icon={IconNames.GRAPH}
                    text={'Design Studio'}
                    to={'/clusters/' + cluster.id + '/manifest/designer/'}
                />
                <MenuDivider />
                <MenuItemLink
                    icon={IconNames.TICK}
                    text={'Apply Changes'}
                    intent={Intent.DANGER}
                    to={'/clusters/' + cluster.id + '/poke/'}
                />
            </Menu>
        );
    }
}

export function getExtendedStatus(cluster: ICluster) {
    const provider = camelize(cluster.infrastructureProvider || 'unknown')
        .toString()
        .toUpperCase();
    return {
        [ProvisionStatusEnum.Unprovisioned]: (
            <span>
                Click{' '}
                <Link to={'/clusters/' + cluster.id + '/provision/'}>
                    Install
                </Link>{' '}
                to get started
            </span>
        ),
        [ProvisionStatusEnum.Provisioning]: `Installing on ${provider}`,
        [ProvisionStatusEnum.Provisioned]: `Installed on ${provider}`,
        [ProvisionStatusEnum.ProvisionFailed]: `Installation failed ${provider}`,
        [ProvisionStatusEnum.Destroying]: `Uninstalling the Cradle from ${provider}`,
        [ProvisionStatusEnum.DestroyFailed]: `Uninstall Failed on ${provider}`,
    }[cluster.provisionStatus];
}

export function mapClusterProvisionStatus(ps: ProvisionStatusEnum): string {
    return {
        [ProvisionStatusEnum.Unprovisioned]: 'Not Ready',
        [ProvisionStatusEnum.Provisioning]: 'Installing',
        [ProvisionStatusEnum.ProvisionFailed]: 'Installation Failed',
        [ProvisionStatusEnum.Provisioned]: 'Ready',
        [ProvisionStatusEnum.Destroying]: 'Uninstalling',
        [ProvisionStatusEnum.DestroyFailed]: 'Uninstall Failed',
    }[ps];
}

export const ClustersListComponent = withRouter<IClustersListParams, any>(
    ClustersList
);
