import { FormModel } from '../../../models/form-model';
import { sp } from '../../../utils/formatters';
import {
    AnchorButton,
    Classes,
    FormGroup,
    HTMLSelect,
    Icon,
    InputGroup,
    Switch,
    Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { Component } from 'react';
import {
    FormParamTypeEnum,
    IFormComponent,
    IFormParam,
    isFormComponent,
    isFormContainer,
} from '../../../types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
export interface IClusterFormParametersProps {
    fm: FormModel;
    p: IFormParam;
}

@observer
export class FormParameter extends Component<IClusterFormParametersProps, any> {

    render() {
        const { p } = this.props;

        if (isFormContainer(p)) {
            return (
                <div
                    className={classNames('container', `${p.type}`, {
                        indent: !!p.indent,
                    })}
                    key={`rfp_${p.k}`}
                >
                    {p.label ? (
                        <div className="label">{p.label}</div>
                    ) : undefined}
                    <div className="items">
                        {p.children
                            ? p.children.map((c) => (
                                  <FormParameter
                                      fm={this.props.fm}
                                      p={c}
                                      key={`fp_${c.k}`}
                                  />
                              ))
                            : ''}
                    </div>
                </div>
            );
        }

        const inputId = sp(
            'input_%s_%s_%s',
            this.props.fm.formId,
            this.props.fm.settingsGroup,
            p.k
        );

        let required =
            p.required === true
                ? '(Required)'
                : p.required === false
                ? '(Optional)'
                : p.required;
        if (required === undefined) required = '';

        switch (p.type) {
            case FormParamTypeEnum.Checkbox:
                return (
                    <FormGroup key={`div${inputId}`}>
                        <Switch
                            key={`switch${inputId}`}
                            defaultChecked={p.v !== 'false'}
                            label={p.label}
                            name={p.k}
                            disabled={p.disabled || this.props.fm.isDisabled}
                            children={this.renderHelper()}
                            onChange={this.props.fm.handleParameterChange(p)}
                        />
                        {this.renderHelperText()}
                    </FormGroup>
                );
            case FormParamTypeEnum.Select:
                return (
                    <FormGroup
                        key={`fpte-fg-${inputId}`}
                        disabled={p.disabled || this.props.fm.isDisabled}
                        label={p.label}
                        labelFor={inputId}
                        helperText={this.renderHelperText()}
                    >
                        <HTMLSelect
                            defaultValue={p.v}
                            id={inputId}
                            onChange={this.props.fm.handleSelectParameterChange(
                                p
                            )}
                            options={
                                p.list &&
                                p.list.map((value: string) => ({ value }))
                            }
                        ></HTMLSelect>
                        &nbsp;
                    </FormGroup>
                );

            case FormParamTypeEnum.Shelf:
            case FormParamTypeEnum.Stack:
                break;

            default: {
                const dataListId = `dl${inputId}`;
                return (
                    <FormGroup
                        key={`fg${inputId}`}
                        disabled={p.disabled || this.props.fm.isDisabled}
                        label={p.label}
                        labelInfo={required}
                        labelFor={inputId}
                    >
                        <InputGroup
                            key={`ig${inputId}`}
                            name={p.k}
                            defaultValue={p.v}
                            type={p.type}
                            disabled={this.props.fm.isDisabled}
                            id={inputId}
                            rightElement={this.renderHelper()}
                            placeholder={p.placeholder}
                            autoComplete={
                                p.autoComplete ? p.autoComplete : undefined
                            }
                            list={p.list ? dataListId : undefined}
                            required={p.required === true}
                            minLength={p.required ? 1 : undefined}
                            onChange={this.props.fm.handleParameterChange(p)}
                        />
                        <span>{renderDatalist(p, dataListId)}</span>
                        {this.renderHelperText()}
                    </FormGroup>
                );
            }
        }
    }

    makeKey(...abc: string[]) {
        return abc.join('_');
    }

    renderHelper() {
        const { p } = this.props;
        if (isFormContainer(p)) {
            return;
        }

        if (p.explanation) {
            return (
                <Tooltip
                    content={
                        <ReactMarkdown
                            className={Classes.FORM_HELPER_TEXT}
                            remarkPlugins={[remarkGfm]}
                            children={p.explanation.details.toString()}
                        />
                    }
                    key={this.makeKey('rptt', p.k)}
                >
                    {p.explanation.url ? (
                        <AnchorButton
                            disabled={!p.explanation.url}
                            target={'_blank'}
                            href={p.explanation.url}
                            small={true}
                            minimal={true}
                            icon={IconNames.INFO_SIGN}
                        />
                    ) : (
                        <span className={'formTooltipHelper'}>
                            <Icon icon={IconNames.INFO_SIGN} />
                        </span>
                    )}
                </Tooltip>
            );
        }
    }

    renderHelperText() {
        if (isFormComponent(this.props.p)) {
            const { description } = this.props.p;
            if (description) {
                return (
                    <ReactMarkdown
                        className={Classes.FORM_HELPER_TEXT}
                        remarkPlugins={[remarkGfm]}
                        children={description.toString()}
                    />
                );
            }
        }
    }
}

function renderDatalist(p: IFormComponent, dataListId: string) {
    if (p.list)
        return (
            <datalist id={dataListId} key={dataListId}>
                {p.list.map((opt) => (
                    <option value={opt} key={`dli${dataListId}${opt}`} />
                ))}
            </datalist>
        );
}
