import { action, observable } from 'mobx';
import {
    ICluster,
    ICradleComponent,
    IManifest,
    IManifestComponent,
} from '../../../../types';
import { ManifestService } from '../../../../service/manifest';
import { AppToaster } from '../../../../service/toaster';
import { Intent } from '@blueprintjs/core';
import { ClusterAwareModel } from '../../../../models/cluster-aware';
import { clone } from '../../../../utils/clone';
import { sanitizeString } from '../../../../utils/formatters';
import { AppNavigator } from '../../../../models/navigation';

const MANIFEST_CHANGED_MESSAGE =
    'You have unsaved changes. Would you like to save them?';

/***
 * Manifest model designed to handle interaction & state of the current manifest
 */
export class ManifestModel extends ClusterAwareModel {
    @observable activeManifestComponent?: IManifestComponent;

    @observable
    manifest: IManifest = {
        components: [],
        helmRepos: [],
        status: '',
    };

    @observable
    isManifestChanged: boolean = false;

    interpolateValue(source: string): string {
        if (!this.cluster) return source;

        const replaceMap = {
            clusterName: this.cluster.clusterName,
            clusterHostname: this.cluster.hostname,
            infrastructureProvider: this.cluster.infrastructureProvider || '',
        };
        return Object.entries(replaceMap).reduce((acc, [k, v]) => {
            // console.log('replacing values', k, v);
            const rgx = new RegExp(`{${k}}`, 'ig');
            acc = acc.replace(rgx, v);
            return acc;
        }, source);
    }

    interpolateObject(anything: any) {
        if (typeof anything === 'string') {
            anything = this.interpolateValue(anything);
        } else if (typeof anything === 'object') {
            for (let prop in anything) {
                if (Object.prototype.hasOwnProperty.call(anything, prop)) {
                    anything[prop] = this.interpolateObject(anything[prop]);
                }
            }
        }
        return anything;
    }

    @action
    setManifestChanged(status: boolean) {
        this.isManifestChanged = status;
        const bc =
            AppNavigator.breadcrumbs[AppNavigator.breadcrumbs.length - 1];
        if (bc) {
            if (status) {
                if (bc.text.indexOf('*') === -1) {
                    bc.text = `*${bc.text}`;
                }
            } else {
                bc.text = bc.text.replace('*', '');
            }
        }
    }

    public registerUnloadHook(w: Window) {
        w.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
            if (this.isManifestChanged) {
                (e || w.event).returnValue = MANIFEST_CHANGED_MESSAGE;
                return MANIFEST_CHANGED_MESSAGE;
            }
        });
    }

    public confirmChanges() {
        if (this.isManifestChanged) {
            if (window.confirm(MANIFEST_CHANGED_MESSAGE)) {
                this.saveManifest().then();
            }
        }
    }

    @action
    async loadManifest(cluster: ICluster) {
        this.manifest = await ManifestService.get(cluster.id);
        this.setManifestChanged(false);
        this.activeManifestComponent = this.manifest.components[0];
    }

    @action
    addComponent(c: ICradleComponent) {
        const { manifest } = this;
        if (!manifest) return;
        if (!this.cluster) return;

        const template = this.interpolateObject(clone(c.template));
        const component: IManifestComponent = {
            ...template,
            releaseName: sanitizeString(c.name).trim().replace(/\s+/g, '-'),
        };
        if (
            manifest.components.some(
                (m) => m.releaseName === component.releaseName
            )
        ) {
            component.releaseName += '-' + Math.random().toString(28).substr(2);
        }
        manifest.components.push(component);
        const compref = manifest.components[manifest.components.length - 1];
        this.activeManifestComponent = compref;
        return compref;
    }

    @action
    async saveManifest() {
        if (!this.cluster) return;
        return ManifestService.update(this.cluster.id, this.manifest)
            .then(() => {
                this.setManifestChanged(false);
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    message: 'Manifest has been saved successfully',
                    timeout: 1000,
                });
                return;
            })
            .catch((e) => {
                AppToaster.show({
                    intent: Intent.DANGER,
                    message: e.message,
                });
            });
    }
}
