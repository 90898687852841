import { IManifest } from '../../../types';

export const defaultManifest: IManifest = {
    components: [],
    helmRepos: [
        {
            repoName: 'adi',
            repoUrl: 'https://helm.analoggarage.com/charts/dev',
        },
        {
            repoName: 'stable',
            repoUrl: 'https://kubernetes-charts.storage.googleapis.com',
        },
    ],
    status: 'ACTIVE',
};
