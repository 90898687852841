export enum FormParamTypeEnum {
    Text = 'text',
    Password = 'text',
    Email = 'email',
    Checkbox = 'checkbox',
    Select = 'select',
    File = 'file',
    Number = 'number',
    URL = 'url',
    Shelf = 'shelf',
    Stack = 'stack',
    Collapsable = 'collapse',
}
