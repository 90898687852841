import awsLogo from './aws.svg';
import {
    cidrValidator,
    isNotEmpty,
    maxLimitValidator,
    minLimitValidator,
    rangeLimitValidator,
} from '../validators';
import React from 'react';
import { certManagerConfigParameter } from '../cert-manager-config-parameter';
import { ConsoleExample } from '../../../common/console-example/console-example';
import { renderTokenWarning } from './bare-metal';
import { maintainerEmailParameter } from '../maintainer-parameter';
import {
    awsInstanceTypes,
    supportedAvailabilityZonesMap,
} from './aws-instance-types';
import { Classes } from '@blueprintjs/core';
import { prometheusConfigParameter } from '../prometheus-config-parameter';
import {
    Architecture,
    FormParamTypeEnum,
    IaaSProvider,
    IClusterInfrastructureProvider,
    IFormParam,
    IInstructions,
    IProvisionParamMapper,
    SettingsGroup,
    TypeCast,
} from '../../../../types';

const { RUNNING_TEXT } = Classes;

const kni = {
    g: SettingsGroup.Advanced,
    k: 'cradleNodeImage',
    type: FormParamTypeEnum.Text,
};
export const regionToAMImap: IProvisionParamMapper = new Map([
    [
        'us-east-1',
        [{ ...kni, v: 'ami-0193ebf9573ebc9f7' }, createVpcMap('us-east-1')],
    ],
    [
        'us-east-2',
        [{ ...kni, v: 'ami-02bfcc04ebfd3bae5' }, createVpcMap('us-east-2')],
    ],
    [
        'us-west-1',
        [{ ...kni, v: 'ami-057de96ff04b57baf' }, createVpcMap('us-west-1')],
    ],
    [
        'us-west-2',
        [{ ...kni, v: 'ami-0bb07d9c8d6ca41e8' }, createVpcMap('us-west-2')],
    ],
    [
        'ap-south-1',
        [{ ...kni, v: 'ami-0c3900c58d3db111c' }, createVpcMap('ap-south-1')],
    ],
    [
        'ap-northeast-1',
        [
            { ...kni, v: 'ami-029497d58b956d4bd' },
            createVpcMap('ap-northeast-1'),
        ],
    ],
    [
        'ap-northeast-2',
        [
            { ...kni, v: 'ami-065032e477e2adb5e' },
            createVpcMap('ap-northeast-2'),
        ],
    ],
    [
        'ap-southeast-1',
        [
            { ...kni, v: 'ami-016e739e57ea162d9' },
            createVpcMap('ap-southeast-1'),
        ],
    ],
    [
        'ap-southeast-2',
        [
            { ...kni, v: 'ami-02fe2d9caaa592168' },
            createVpcMap('ap-southeast-2'),
        ],
    ],
    [
        'eu-central-1',
        [{ ...kni, v: 'ami-0e59fc1eb9ee8ba37' }, createVpcMap('eu-central-1')],
    ],
    [
        'eu-west-1',
        [{ ...kni, v: 'ami-044dfe22e0788d8ed' }, createVpcMap('eu-west-1')],
    ],
    [
        'eu-west-2',
        [{ ...kni, v: 'ami-07266000cd5ac483d' }, createVpcMap('eu-west-2')],
    ],
    [
        'eu-west-3',
        [{ ...kni, v: 'ami-01cce5e49c1a5f1c9' }, createVpcMap('eu-west-3')],
    ],
    [
        'eu-north-1',
        [{ ...kni, v: 'ami-03583715dd9f3d36b' }, createVpcMap('eu-north-1')],
    ],
    [
        'sa-east-1',
        [{ ...kni, v: 'ami-0070c6d01337601cf' }, createVpcMap('sa-east-1')],
    ],
]);

export const awsCredentials: IFormParam[] = [
    {
        type: FormParamTypeEnum.Text,
        label: 'AWS Access Key Id',
        description: `The access key id of an IAM user on your AWS account.
                    You should be able to get this from the IAM page on your AWS console.
                    Please review our [help documentation](https://confluence.analog.com/display/S2C/Cradle+User+Guides) to better understand the permissions
                    that this user must have.`,
        k: 'accessKeyId',
        v: '',
        placeholder: 'ABCDEF',
        autoComplete: 'off',
        required: true,
        isValid: isNotEmpty,
    },
    {
        type: 'text',
        label: 'AWS Secret Access Key',
        k: 'secretAccessKey',
        description: 'The secret access key corresponding to the access key id.',
        placeholder: 'ABCDEF',
        v: '',
        required: true,
        autoComplete: 'off',
        isValid: isNotEmpty,
    },
    {
        type: 'text',
        label: 'AWS Session Token',
        k: 'sessionToken',
        placeholder: 'ABCDEF',
        description: `The session token associated with your IAM user.
                This is optional, and depends on how your access has been configured.
                If you don’t know what this is, chances are you won’t need it.
                Talk to your AWS account administrator if you’re not sure.
                
You can use something like [assume-role](https://docs.aws.amazon.com/cli/latest/reference/sts/assume-role.html) to get a new token:
\`\`\`
aws sts assume-role
     --role-arn arn:aws:iam::226934529914:role/OrganizationAdminRole
     --role-session-name "CradleInstallSession"
     --duration-seconds 3600
     --profile dev_sandbox
\`\`\``,
        v: '',
        autoComplete: 'off',
        required: false,
    },
    {
        type: 'select',
        label: 'Region Id',
        k: 'awsRegion',
        v: 'us-east-1',
        description: `The AWS region (data center) that you want to deploy Cradle into.
                    Read up on [AWS Regions and Availability Zones](https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Concepts.RegionsAndAvailabilityZones.html) to learn more.`,
        required: true,
        isValid: isNotEmpty,
        list: Array.from(regionToAMImap.keys()),
        mapper: regionToAMImap,
    },
];

export const awsProvider: IClusterInfrastructureProvider = {
    id: IaaSProvider.AWS,
    name: 'AWS',
    supported: true,
    logo: awsLogo,
    description: (
        <span key="awsD">
            Install a high availability Cradle cluster on an AWS cloud account
            that <b>you own</b>.
        </span>
    ),
    details: (
        <div key="aswDet">
            <p className={RUNNING_TEXT}>
                We will deploy Cradle on AWS using Amazon's Elastic Kubernetes
                Service (EKS) and an autoscale group for EC2 instances. We can
                also create a new VPC and ssh keys for the EC2 instances, or you
                can use existing ones.
            </p>
            <p className={RUNNING_TEXT}>
                If you select this option, you can choose the AWS region where
                the Cradle cluster will be deployed, along with other advanced
                parameters such as EC2 instance types, Amazon Machine Image
                (AMI), and the size of the autoscale group.
            </p>
            <p className={RUNNING_TEXT}>
                The choices that you make on the configuration screen will have
                a bearing on the cost of operating the Cradle cluster. Please
                review{' '}
                <a target='_blank' rel="noreferrer" href="https://aws.amazon.com/eks/pricing/">
                    EKS pricing
                </a>{' '}
                and{' '}
                <a target='_blank' rel="noreferrer" href="https://aws.amazon.com/ec2/pricing/">
                    EC2 pricing
                </a>{' '}
                and to better understand what this cost might be.{' '}
            </p>
        </div>
    ),

    config: {
        [SettingsGroup.Basic]: [maintainerEmailParameter, ...awsCredentials],
        [SettingsGroup.Advanced]: [
            {
                type: FormParamTypeEnum.Shelf,
                k: 'ntarch',
                children: [
                    {
                        type: FormParamTypeEnum.Select,
                        label: 'Instance Type',
                        k: 'cradleNodeType',
                        v: 't3a.xlarge',
                        required: true,
                        isValid: isNotEmpty,
                        description: `The type of AWS instance you want to use to run Cradle on.
                    This choice will impact your AWS bill.
                    See [AWS Instance Types](https://aws.amazon.com/ec2/instance-types/) for more
                    information.
                `,
                        list: awsInstanceTypes,
                    },
                    {
                        type: FormParamTypeEnum.Select,
                        label: 'Architecture',
                        k: 'architecture',
                        v: 'amd64',
                        required: true,
                        isValid: isNotEmpty,
                        description: '',
                        list: Object.values(Architecture),
                    },
                ],
            },
            {
                type: FormParamTypeEnum.Shelf,
                k: 'sizes',
                children: [
                    {
                        type: 'number',
                        label: 'Minimum Cluster Size',
                        k: 'cradleMinSize',
                        v: '1',
                        description: `The minimum number of compute instances that
                    will power your Cradle cluster, no matter how low the load is.
                    This choice will impact your AWS bill.`,
                        required: true,
                        cast: TypeCast.Number,
                        isValid: minLimitValidator(1),
                    },
                    {
                        type: 'number',
                        label: 'Desired Cluster Size',
                        k: 'cradleDesiredSize',
                        v: '2',
                        description: `The number of compute instances that will power
                    your Cradle cluster under normal operating conditions.
                    This choice will impact your AWS bill.`,
                        required: true,
                        cast: TypeCast.Number,
                        isValid: minLimitValidator(1),
                    },
                    {
                        type: 'number',
                        label: 'Maximum Cluster Size',
                        k: 'cradleMaxSize',
                        v: '3',
                        description: `The maximum number of compute instances that
                    will power your Cradle cluster, irrespective of how high the load is.
                    This choice will impact your AWS bill.`,
                        required: true,
                        cast: TypeCast.Number,
                        isValid: maxLimitValidator(100),
                    },
                    {
                        type: 'number',
                        label: 'Volume Size, GiB',
                        k: 'volumeSize',
                        v: '80',
                        description: 'We don\'t recommend going below default value. This choice will impact your AWS bill.',
                        required: true,
                        cast: TypeCast.Number,
                        isValid: rangeLimitValidator(20, 16000),
                    },
                ],
            },
            {
                type: 'text',
                label: 'Node Image',
                k: 'cradleNodeImage',
                // @todo: use API call to get AMI
                //  aws ssm get-parameter --name /aws/service/eks/optimized-ami/1.19/amazon-linux-2/recommended/image_id --region us-east-1 --profile default
                v: 'ami-0193ebf9573ebc9f7',
                required: true,
                isValid: isNotEmpty,
                description: `The AWS AMI to use for deployment.
                    We have selected a default AMI based on your region.
                    A bad choice here can break your cluster. Use
                    caution when changing the recommended value. Use the following command to get ab image id
                    \`\`\`
                    aws ssm get-parameter 
                        --name /aws/service/eks/optimized-ami/1.19/amazon-linux-2/recommended/image_id 
                        --region us-east-1 --profile default
                    \`\`\`
                    
                `,
            },
            certManagerConfigParameter,
            prometheusConfigParameter,
            createVpc('us-east-1'),
        ],
    },
};

function createVpcMap(region: string) {
    return { g: SettingsGroup.Advanced, k: 'vpc', ...createVpc(region) };
}

function createVpc(region: string): IFormParam {
    return {
        type: FormParamTypeEnum.Stack,
        k: 'vpc',
        label: 'Custom Network Configuration. Empty values will be ignored.',
        children: [
            {
                type: FormParamTypeEnum.Shelf,
                k: 'vpc',
                indent: true,
                children: [
                    {
                        type: FormParamTypeEnum.Text,
                        label: 'VPC ID',
                        k: 'vpc.id',
                        description: 'Must match VPC ID used for each subnet below.',
                        v: '',
                        placeholder: 'vpc-0dd338ecf29863c55',
                    },
                    {
                        type: FormParamTypeEnum.Text,
                        label: 'CIDR used by the given VPC',
                        description: 'Must match used by the given VPC.',
                        k: 'vpc.cidr',
                        v: '',
                        placeholder: '192.168.0.0/16',
                        isValid: cidrValidator(),
                    },
                ],
            },
            {
                indent: true,
                type: FormParamTypeEnum.Shelf,
                k: 'vpc.subnets.public',
                label: 'Public Subnets',
                children: supportedAvailabilityZonesMap[region].map((az) =>
                    createSubnet('public', az)
                ),
            },
            {
                indent: true,
                type: FormParamTypeEnum.Shelf,
                k: 'networking.private',
                label: 'Private Subnets',
                children: supportedAvailabilityZonesMap[region].map((az) =>
                    createSubnet('private', az)
                ),
            },
            {
                indent: true,
                type: FormParamTypeEnum.Checkbox,
                label: 'Enable private networking for nodegroup',
                k: 'privateNetworking',
                v: 'false',
                cast: TypeCast.Boolean,
                required: true,
                isValid: isNotEmpty,
                description: 'if only \'Private\' subnets are given, this must be enabled.',
            },
        ],
    };
}

function createSubnet(type: string, az: string) {
    return {
        type: FormParamTypeEnum.Stack,
        indent: true,
        k: `vpc.subnets.${type}.${az}`,
        label: `AZ: ${az}`,
        children: [
            {
                indent: true,
                type: FormParamTypeEnum.Text,
                label: 'ID',
                k: `vpc.subnets.${type}.${az}.id`,
                description: ' ',
                v: '',
                placeholder: 'subnet-0b2512f8c6ae9bf30',
            },
            {
                type: FormParamTypeEnum.Text,
                label: 'CIDR',
                description: ' ',
                k: `vpc.subnets.${type}.${az}.cidr`,
                v: '',
                placeholder: '192.168.128.0/1',
                isValid: cidrValidator(),
            },
        ],
    };
}

export const awsDestroyExtras: IFormParam[] = [
    {
        type: FormParamTypeEnum.Checkbox,
        label: 'Delete VPC',
        k: 'deleteVpc',
        v: 'false',
        description: '',
        // cast: TypeCast.String,
        required: false,
        disabled: false,
    },
    {
        type: FormParamTypeEnum.Checkbox,
        label: 'Delete IAM User',
        k: 'deleteIamUser',
        v: 'false',
        description: '',
        // cast: TypeCast.String,
        required: false,
        disabled: false,
    },
    {
        type: FormParamTypeEnum.Checkbox,
        label: 'Delete Key Pair',
        k: 'deleteKeypair',
        v: 'false',
        description: '',
        cast: TypeCast.String,
        required: false,
        disabled: false,
    },
];

export function renderAwsProvisionInstructions(instructions: IInstructions) {
    const { REACT_APP_CRADLE_KNOX_URL } = process.env;
    const cradleCmd =
        `docker run --rm -e CRADLE_UP_TOKEN='${instructions.token}' \\\n` +
        `  -e CRADLE_KNOX_URL='${REACT_APP_CRADLE_KNOX_URL}' \\\n` +
        '  --entrypoint cradle_up analoggarage/agc-cradle-up:latest';

    return (
        <div>
            <h4>Manual installation steps for AWS ECS</h4>
            <ol>
                <li>
                    <p className={RUNNING_TEXT}>
                        In order to start cluster provision flow you have to
                        install{' '}
                        <a
                            href="https://docs.docker.com/install/"
                            target="_blank" rel="noreferrer"
                        >
                            Docker
                        </a>{' '}
                        on your local machine.
                    </p>
                </li>
                <li>
                    <p className={RUNNING_TEXT}>
                        On the machine with docker run cradle-up container which
                        will start your cluster provision:
                    </p>
                    <ConsoleExample text={cradleCmd} />
                    {renderTokenWarning()}
                </li>
            </ol>
        </div>
    );
}
