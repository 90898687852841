import React, { Component } from 'react';
import {
    Alignment,
    Button,
    Callout,
    Classes,
    Collapse,
    Dialog,
    EditableText,
    FormGroup,
    H1,
    InputGroup,
    Intent,
    Navbar,
    NavbarGroup,
    Spinner,
    Tooltip,
    Divider,
} from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router';
import { IClusterKey, IClusterProps, UIState } from '../../../types';

import './secure-storage.scss';
import { StatefulComponent } from '../../common/stateful/stateful';
import { AppNavigator } from '../../../models/navigation';
import { ButtonLink } from '../../common/links/button-link';
import { IconNames } from '@blueprintjs/icons';
import { KeysModel } from '../../../models/keys';
import { AppCopier } from '../../../service/copier';
import { TEXT_MUTED } from '@blueprintjs/core/lib/cjs/common/classes';
import { SubheaderBreadcrumbs } from '../../common/breadcrumbs/cms-breadcrumbs';
import { randomAlphaNumericString } from '../../../utils/formatters';

@observer
class SecureKeysStorage extends Component<IClusterProps> {
    @observable uiLoadState: UIState = UIState.Empty;
    private km: KeysModel;

    constructor(props: any) {
        super(props);
        this.km = new KeysModel();
    }

    componentWillUnmount(): void {
        AppNavigator.clear();
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');

        this.uiLoadState = UIState.Loading;
        try {
            const { clusterId } = this.props.match.params;
            await this.km.loadCluster(clusterId);
            if (this.km.cluster) {
                AppNavigator.add(
                    `/clusters/${clusterId}/`,
                    this.km.cluster.clusterName
                );
                await this.km.loadKeys();
                AppNavigator.add(`/clusters/${clusterId}/secrets/`, 'Secrets');
                AppNavigator.add(
                    `/clusters/${clusterId}/secrets/keys/`,
                    'Keys'
                );
            }
            this.uiLoadState = UIState.Loaded;
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    async componentDidMount() {
        this.load();
    }

    public render() {
        return (
            <div className={'secureStorageComponent'}>
                <StatefulComponent state={this.uiLoadState}>
                    <div>{this.km.cluster ? this.renderClusterUI() : ''}</div>
                </StatefulComponent>
                <p></p>
                <ButtonLink to={'../'} icon={IconNames.CHEVRON_LEFT}>
                    Back
                </ButtonLink>
            </div>
        );
    }

    renderClusterUI() {
        if (!this.km.cluster) return;
        return (
            <div>
                {this.renderKeysList()}
                {this.renderAddNewKey()}
            </div>
        );
    }

    renderKey(k: IClusterKey) {
        return (
            <div key={k.name} className={'row'}>
                <Navbar>
                    <NavbarGroup align={Alignment.LEFT}>
                        <Button
                            icon={this.renderAccordionIcon(k)}
                            minimal={true}
                            large={true}
                            fill={true}
                            disabled={k.uiIsLoading}
                            onClick={() => this.km.toggle(k)}
                        >
                            {k.name}
                        </Button>
                        <br />
                    </NavbarGroup>
                    <NavbarGroup align={Alignment.RIGHT}>
                        <Tooltip
                            interactionKind={'hover'}
                            content={
                                k.value === undefined ? 'Retrieve Key from the vault' : 'Copy Key'
                            }
                        >
                            <Button
                                style={{ width: '6em'}}
                                alignText={Alignment.LEFT}
                                minimal={true}
                                disabled={k.uiIsLoading}
                                intent={Intent.NONE}
                                icon={
                                    k.value === undefined
                                        ? IconNames.DOWNLOAD
                                        : IconNames.CLIPBOARD
                                }
                                onClick={() => {
                                    if (k.value === undefined) {
                                        this.km.hydrate(k).then();
                                    } else {
                                        AppCopier.copy(k.value);
                                    }
                                }}

                            >{k.value ? 'Copy' : 'Pull'}</Button>
                        </Tooltip>
                        <Divider />
                        <Tooltip
                            interactionKind={'hover'}
                            content={'Delete Key'}
                        >
                            <Button
                                loading={k.uiIsDeleting}
                                minimal={true}
                                intent={Intent.DANGER}
                                icon={IconNames.REMOVE}
                                onClick={() => this.km.delete(k)}
                            />
                        </Tooltip>
                    </NavbarGroup>
                </Navbar>
                <div className="flex-row">
                    <Collapse isOpen={k.uiIsOpen}>
                        {this.renderKeyEditor(k)}
                    </Collapse>
                </div>
            </div>
        );
    }

    renderAccordionIcon(key: IClusterKey) {
        if (key.uiIsLoading) {
            return <Spinner size={16} />;
        }
        if (key.uiIsOpen) {
            return IconNames.CHEVRON_UP;
        }
        return IconNames.CHEVRON_DOWN;
    }

    renderKeyEditor(k: IClusterKey) {
        if (k.value === undefined || k.isUpdating || k.uiIsDeleting) {
            return <Spinner />;
        }
        return (
            <div>
                <EditableText
                    isEditing={true}
                    multiline={true}
                    className={'keyArea code'}
                    disabled={k.isUpdating || false}
                    value={k.value}
                    onChange={(e: string) => {
                        if (k.value !== e) {
                            k.value = e;
                            k.hasChanged = true;
                        }
                    }}
                    onConfirm={() => {
                        if (k.isUpdating) return;
                        if (!k.hasChanged) return;
                        k.isUpdating = true;
                        this.km.updateKey(k).then();
                    }}
                />
            </div>
        );
    }

    renderKeysList() {
        if (!this.km.cluster) {
            return;
        }
        return (
            <div>
                <div className="flex-spaced">
                    <H1>
                        Keys for{' '}
                        <span className={Classes.TEXT_MUTED}>
                            {this.km.cluster.clusterName}
                        </span>{' '}
                    </H1>
                    <Button
                        icon={IconNames.ADD}
                        minimal={true}
                        onClick={() => this.km.showDialog()}
                        intent={Intent.PRIMARY}
                    >
                        Add
                    </Button>
                </div>
                <SubheaderBreadcrumbs breadcrumbs={AppNavigator.items} />

                <p className={TEXT_MUTED}>
                    Click on key name and make changes, remove field focus to
                    save. To rename key - delete existing and add new.
                </p>
                {this.km.keys.map(this.renderKey.bind(this))}
            </div>
        );
    }

    renderAddNewKey() {
        const { newDialog } = this.km;
        return (
            <Dialog
                isOpen={newDialog.isOpen}
                onClose={() => this.km.hideDialog()}
                canEscapeKeyClose={false}
                usePortal={true}
                autoFocus={true}
                canOutsideClickClose={false}
                isCloseButtonShown={!newDialog.isCreating}
                title="Create New Key"
            >
                <form onSubmit={(e) => this.km.submit(e)} action={'#'}>
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label={'Name'}
                            labelFor="commonName-input"
                            labelInfo={'(required)'}
                            helperText={'Use key name here.'}
                        >
                            <InputGroup
                                disabled={newDialog.isDisabled}
                                required={true}
                                id="commonName-input"
                                placeholder="jwt-key"
                                type={'text'}
                                name={'name'}
                                value={newDialog.key.name}
                                onChange={(e: any) => this.km.updateField(e)}
                                minLength={1}
                                pattern={'^[a-zA-Z0-9-.]+$'}
                                autoFocus={true}
                            />
                        </FormGroup>
                        <FormGroup
                            label={'Value'}
                            labelFor="value-input"
                            labelInfo={'(optional)'}
                            helperText={'Your value.'}
                        >
                            <InputGroup
                                disabled={newDialog.isDisabled}
                                required={false}
                                id="value-input"
                                placeholder=""
                                type={'text'}
                                name={'value'}
                                value={newDialog.key.value}
                                onChange={(e: any) => this.km.updateField(e)}
                                rightElement={
                                    <Button
                                        icon={IconNames.BUILD}
                                        onClick={() => {
                                            newDialog.key.value =
                                                randomAlphaNumericString(16);
                                        }}
                                        minimal={true}
                                    />
                                }
                            ></InputGroup>
                        </FormGroup>

                        <Callout intent={Intent.PRIMARY}>
                            This action will create a new key.
                            <br />
                            Use chart secrets to deliver keys to the cluster.
                        </Callout>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => this.km.hideDialog()}
                                disabled={newDialog.isDisabled}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    newDialog.isDisabled || !newDialog.isValid
                                }
                                loading={newDialog.isCreating}
                                type={'submit'}
                                intent={Intent.PRIMARY}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const SecureKeysStorageComponent = withRouter<IClusterProps, any>(
    SecureKeysStorage
);
