import { awsProvider } from './iaas/aws';
import { bareMetalIaaSProvider } from './iaas/bare-metal';
import { gcpIaasProvider } from './iaas/gcp';
import { azureIaaSProvider } from './iaas/azure';
import { edgeIaaSProvider } from './iaas/edge';
import { IClusterInfrastructureProvider } from '../../../types';

export const InfrastructureProviders: IClusterInfrastructureProvider[] = [
    awsProvider,
    bareMetalIaaSProvider,
    edgeIaaSProvider,
    gcpIaasProvider,
    azureIaaSProvider,
];
