import { FormModel } from './form-model';
import { action, computed, observable } from 'mobx';
import { ClustersService } from '../service/clusters';
import {
    awsCredentials,
    awsDestroyExtras,
} from '../components/clusters/provision/iaas/aws';
import { ErrorToast } from '../service/toaster';
import { IaaSProvider } from '../types/iaas-provider';
import { SettingsGroup } from '../types/settings-group';
import { AutomationType } from '../types/automation-type';
import { IInstructions } from '../types/instructions';
import { bareMetalCredentials } from '../components/clusters/provision/iaas/bare-metal';
import { LoggerAwareModel } from './logger-aware';
import { DestroyStepEnum } from '../types/destroy-step-enum';
import { edgeCredentials } from '../components/clusters/provision/iaas/edge';

export class DestroyModel extends LoggerAwareModel {
    @observable isRiskAccepted = false;
    @observable isAutomatedDestroy = false;
    @observable isDestroying = false;
    @observable step: DestroyStepEnum = DestroyStepEnum.Parameters;
    @observable automation: AutomationType = AutomationType.Automatic;
    @observable instructions: IInstructions = {
        token: '',
        details: '',
    };
    fm: FormModel;
    constructor() {
        super();
        this.fm = new FormModel();
    }

    @computed
    get isNextButtonDisabled(): boolean {
        return !this.fm.isValid || this.isDestroying;
    }

    @action
    nextStep() {
        switch (this.step) {
            case DestroyStepEnum.Parameters:
                if (!this.cluster || this.isDestroying) return;
                this.isDestroying = true;
                ClustersService.prepareDestroy(
                    this.cluster.id,
                    this.fm.fields,
                    this.cluster.infrastructureProvider || IaaSProvider.AWS
                )
                    .then((r) => {
                        this.instructions.token = r.cradleDownToken;
                        this.instructions.details = r.message;
                        this.step++;
                    })
                    .catch(ErrorToast('Unable to save removal parameters'))
                    .finally(() => {
                        this.isDestroying = false;
                    });
                break;
            case DestroyStepEnum.Automation:
                console.log(this.automation);
                if (this.automation === AutomationType.Automatic) {
                    this.step = DestroyStepEnum.Confirmation;
                } else {
                    this.step = DestroyStepEnum.Instructions;
                }
                break;
            case DestroyStepEnum.Confirmation:
                if (this.automation === AutomationType.Automatic) {
                    this.destroyCluster();
                } else {
                    this.step = DestroyStepEnum.Instructions;
                }
                break;
            case DestroyStepEnum.Instructions:
            case DestroyStepEnum.Done:
                if (!this.cluster) return;
                window.location.pathname = `/clusters/${this.cluster.id}/logs/`;
                break;
            case DestroyStepEnum.Error:
                this.step = DestroyStepEnum.Parameters;
                break;
        }
    }

    @action
    previousStep() {
        switch (this.step) {
            case DestroyStepEnum.Parameters:
                window.location.pathname = '/clusters/';
                return;

            case DestroyStepEnum.Automation:
                this.step--;
                break;

            case DestroyStepEnum.Confirmation:
                this.step--;
                break;
            case DestroyStepEnum.Instructions:
                this.step -= 2;
                break;
            case DestroyStepEnum.Done:
                this.step--;
                break;
            case DestroyStepEnum.Error:
                this.step = DestroyStepEnum.Parameters;
                break;
        }
    }

    @action
    async loadCluster(clusterId: string) {
        await super.loadCluster(clusterId);
        if (!this.cluster) return;
        switch (this.cluster.infrastructureProvider) {
            case IaaSProvider.AWS: {
                const basicConf = [...awsCredentials, ...awsDestroyExtras];
                this.fm.isEnabled = true;
                this.fm.fields = {
                    [SettingsGroup.Basic]: basicConf,
                    [SettingsGroup.Advanced]: [],
                };
                break;
            }
            case IaaSProvider.Bare:
                this.fm.isEnabled = true;
                this.fm.fields = {
                    [SettingsGroup.Basic]: [...bareMetalCredentials],
                    [SettingsGroup.Advanced]: [],
                };
                break;
            case IaaSProvider.Edge:
                this.fm.isEnabled = true;
                this.fm.fields = {
                    [SettingsGroup.Basic]: [...edgeCredentials],
                    [SettingsGroup.Advanced]: [],
                };
                break;
            default:
                console.log('Destroying', this.cluster.infrastructureProvider, 'is not supported');
            // nothing
        }
        this.fm.validate();
        return this.cluster;
    }

    @action
    destroyCluster() {
        if (!this.cluster) return;
        if (this.isDestroying) return;
        this.isDestroying = true;
        const { infrastructureProvider } = this.cluster;
        ClustersService.destroyCluster(this.cluster.id, {
            cradleDownToken: this.instructions.token,
            infrastructureProvider,
            automation: this.automation,
        })
            .then(() => {
                if (this.automation === AutomationType.Automatic) {
                    this.step = DestroyStepEnum.Done;
                } else {
                    this.step = DestroyStepEnum.Instructions;
                }
            })
            .catch(ErrorToast())
            .finally(() => {
                this.isDestroying = false;
            });
    }
}
