import { observer } from 'mobx-react';
import React, { Component, FormEvent } from 'react';
import {
    AnchorButton,
    Button,
    Callout,
    Classes,
    ControlGroup,
    FormGroup,
    HTMLSelect,
    InputGroup,
    Intent,
    IToastProps,
    Popover,
    PopoverInteractionKind,
    ProgressBar,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ClustersModel } from '../../../../models/clusters';
import { CradleComponents } from '../../../../models/cradle-components';
import { AppToaster } from '../../../../service/toaster';
import { Architecture, ICradleComponent } from '../../../../types';
import classNames from 'classnames';

export interface IComponentPanelProps {
    cm: ClustersModel;
    cpm: CradleComponents;
    isOpen: boolean;

    addComponent(c: ICradleComponent): void;

    newComponent(): void;
}

@observer
export class ComponentPanel extends Component<IComponentPanelProps, any> {
    private progressToastInterval?: number;

    constructor(props: any) {
        super(props);
        this.uploadChart = this.uploadChart.bind(this);
        this.renderComponentRow = this.renderComponentRow.bind(this);
    }

    public render() {
        const { cpm, isOpen } = this.props;
        if (!isOpen) return '';
        return (
            <div className="containersPane">
                <div className="chartsFilter">
                    <ControlGroup fill={true}>
                        <InputGroup
                            className={'chartsSearch'}
                            placeholder="Search for component..."
                            fill={true}
                            value={cpm.filter}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                cpm.filter = e.target.value;
                                e.stopPropagation();
                            }}
                        />
                        <Button
                            minimal={true}
                            icon={IconNames.SEARCH}
                            intent={Intent.NONE}
                        />

                        <Popover
                            interactionKind={PopoverInteractionKind.CLICK}
                            boundary="viewport"
                        >
                            <Button
                                minimal={true}
                                icon={IconNames.COG}
                                intent={Intent.NONE}
                                disabled={false}
                            />
                            <div className="chartsSettingsPopover">
                                <div className="flex-spaced">
                                    <div>
                                        <h3>Search options</h3>
                                    </div>
                                    <div>
                                        <AnchorButton
                                            intent={Intent.NONE}
                                            small={true}
                                            minimal={false}
                                            target={'_blank'}
                                            href={
                                                'https://confluence.analog.com/pages/viewpage.action?pageId=92737184#HelmCharts-HowtobuildchartsforCradle'
                                            }
                                        >
                                            Learn More
                                        </AnchorButton>
                                    </div>
                                </div>

                                <div>
                                    <FormGroup label={'Architecture'}>
                                        <HTMLSelect
                                            options={Object.values(
                                                Architecture
                                            )}
                                        ></HTMLSelect>
                                    </FormGroup>
                                </div>

                                {/*
                                <ControlGroup fill={true}>
                                    <Switch
                                        defaultChecked={cpm.configurator.repositoryTypes.public}
                                        onChange={switchOnChangeHandler((checked) => cpm.configurator.repositoryTypes.public = checked)}
                                    >Public</Switch>
                                    <Switch
                                        defaultChecked={cpm.configurator.repositoryTypes.adi}
                                        onChange={switchOnChangeHandler((checked) => cpm.configurator.repositoryTypes.adi = checked)}
                                    >Shared ADI</Switch>
                                    <Switch
                                        defaultChecked={cpm.configurator.repositoryTypes.private}
                                        onChange={switchOnChangeHandler((checked) => cpm.configurator.repositoryTypes.private = checked)}
                                    >Private</Switch>
                                </ControlGroup>
                                <div>
                                    <FileInput fill={true}
                                               text="Upload chart file to ADI dev repo..."
                                               onInputChange={this.uploadChart}/>

                                </div>

                                <div className={'addCustomHelmRepo'}>
                                    <Button minimal={true} icon={IconNames.ADD_TO_ARTIFACT}
                                            onClick={() => (cpm.configurator.isOpen = !cpm.configurator.isOpen)}
                                    >Add external charts repository</Button>
                                    <Collapse isOpen={cpm.configurator.isOpen}>
                                        <div className="addCustomHelmRepoSetup">
                                            <h3>Your Repository Setup</h3>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td><FormGroup
                                                        label="Name"
                                                        labelFor="chartName-input"
                                                    >
                                                        <InputGroup id="chartName-input"
                                                                    placeholder="bitnami"/>
                                                    </FormGroup>
                                                    </td>
                                                    <td><FormGroup
                                                        label="Url"
                                                        labelFor="chartUrl-input"
                                                    >
                                                        <InputGroup id="chartUrl-input"
                                                                    placeholder="https://charts.bitnami.com/bitnami"/>
                                                    </FormGroup></td>
                                                </tr>
                                                <tr>
                                                    <td><FormGroup
                                                        label="Login"
                                                        labelFor="chartLogin-input"
                                                    >
                                                        <InputGroup id="chartLogin-input"
                                                                    placeholder="Optional"/>
                                                    </FormGroup></td>
                                                    <td><FormGroup
                                                        label="Password"
                                                        labelFor="text-input"
                                                    >
                                                        <InputGroup id="text-input"
                                                                    placeholder="Optional"/>
                                                    </FormGroup></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><a
                                                            target={'_blank'}
                                                            href={'https://confluence.analog.com/display/S2C/Helm+Charts'}>Learn
                                                            more</a> about
                                                            private Helm repos.</p>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            intent={Intent.PRIMARY}>Setup</Button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Collapse>
                                </div>
                                */}
                            </div>
                        </Popover>
                    </ControlGroup>
                </div>
                <div className="charts">
                    {cpm.items.map(this.renderComponentRow)}
                    <div className="addCustomChart">
                        <Callout
                            intent={Intent.PRIMARY}
                            icon={IconNames.NEW_OBJECT}
                        >
                            <p>
                                Use our{' '}
                                <a onClick={this.props.newComponent} id="newComponent" href='#newComponent'>
                                    Component Wizard
                                </a>{' '}
                                to create components from existing Docker
                                containers.
                            </p>
                        </Callout>
                    </div>
                </div>
                {this.renderSelectedComponentPreview()}
            </div>
        );
    }

    renderComponentRow(c: ICradleComponent) {
        const { cpm } = this.props;
        return (
            <div
                draggable={true}
                onMouseDown={(event) =>
                    (event.currentTarget.style.cursor = 'grabbing')
                }
                onMouseUp={(event) =>
                    (event.currentTarget.style.cursor = 'grab')
                }
                onDragEnd={(event) =>
                    (event.currentTarget.style.cursor = 'grab')
                }
                onDragStart={(event) =>
                    event.dataTransfer.setData(
                        'application/json',
                        JSON.stringify(c)
                    )
                }
                key={c.id}
                className={
                    'chart ' +
                    (cpm.activeComponent && c.id === cpm.activeComponent.id
                        ? 'selected'
                        : '')
                }
                onClick={() => {
                    cpm.activeComponent = c;
                }}
            >
                <div className="name" title={c.description}>
                    {c.id}
                </div>
                <Button
                    icon={IconNames.ADD}
                    small={true}
                    minimal={true}
                    onClick={() => {
                        this.props.addComponent(c);
                    }}
                />
            </div>
        );
    }

    /**
     * *Idea
     * */
    uploadChart(e: FormEvent<HTMLInputElement>) {
        let progress = 0;
        const key = AppToaster.show(this.renderProgress(0));
        this.progressToastInterval = window.setInterval(() => {
            if (progress > 100) {
                window.clearInterval(this.progressToastInterval);
            } else {
                progress += 10 + Math.random() * 20;
                AppToaster.show(this.renderProgress(progress), key);
            }
        }, 1000);
    }

    renderSelectedComponentPreview() {
        const c: ICradleComponent | undefined = this.props.cpm.activeComponent;
        if (!c) return;
        return (
            <div className="chartDetails">
                <div>
                    <img
                        className="icon"
                        src={c.icon}
                        height={32}
                        width={32}
                        alt=""
                    />
                    <div className="name">
                        <b>{c.title}</b>
                    </div>
                    <div className="version">
                        {c.architecture} / v{c.version}
                    </div>
                    <div className="description" title={c.description}>
                        {c.description}
                    </div>
                    <div className="link">
                        <a href={c.home} target="_blank" rel="noreferrer">
                            {c.home}
                        </a>
                    </div>
                    <Button
                        icon={IconNames.ADD}
                        small={true}
                        intent={Intent.PRIMARY}
                        fill={true}
                        onClick={() => {
                            if (c) {
                                this.props.addComponent(c);
                            }
                        }}
                    >
                        Add Component to Pipeline
                    </Button>
                </div>
            </div>
        );
    }

    private renderProgress(amount: number): IToastProps {
        return {
            icon: 'cloud-upload',
            message: (
                <ProgressBar
                    className={classNames('docs-toast-progress', {
                        [Classes.PROGRESS_NO_STRIPES]: amount >= 100,
                    })}
                    intent={amount < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={amount / 100}
                />
            ),
            onDismiss: (didTimeoutExpire: boolean) => {
                if (!didTimeoutExpire) {
                    // user dismissed toast with click
                    window.clearInterval(this.progressToastInterval);
                }
            },
            timeout: amount < 100 ? 0 : 2000,
        };
    }
}
