export interface IManifest {
    components: IManifestComponent[];
    helmRepos: IManifestRepository[];
    status: string;
}

export interface IManifestComponent {
    component?: string;

    chartName: string;
    chartVersion: string;
    containerRepos: string[];
    namespace: string;
    releaseName: string;
    serviceServers?: string[];
    setFileOptions: IFileOption[];
    setOptions: IOption[];
    meta?: IComponentMetaData;

    [prop: string]: any;
}

export interface IComponentMetaData {
    x: number;
    y: number;
}

export interface IManifestRepository {
    repoName: string;
    repoUrl: string;
}

export interface IOption {
    key: string;
    value: string;
}

export interface IFileOption {
    fileId: string;
    key: string;
    source: string;
}

export enum Architecture {
    x86 = 'x86',
    amd64 = 'amd64',
    ARM64 = 'arm64',
    stm32 = 'stm32',
    PowerPC64le = 'ppc64le',
    S390x = 's390x',
    ARMv6 = 'arm/v6',
    ARMv7 = 'arm/v7',
    ARMv8 = 'arm/v8',
}
