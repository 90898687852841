import React, { Component, FormEvent } from 'react';
import { Architecture, ICradleComponent } from '../../../../types';
import { ClustersModel } from '../../../../models/clusters';
import { CradleComponents } from '../../../../models/cradle-components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import {
    Alignment,
    AnchorButton,
    Button,
    Classes,
    Dialog,
    Expander,
    FormGroup,
    HTMLSelect,
    HTMLTable,
    InputGroup,
    Intent,
    NumericInput,
    Switch,
    Tab,
    Tabs,
} from '@blueprintjs/core';

import { IconNames } from '@blueprintjs/icons';
import { clone } from '../../../../utils/clone';
import { UserModel } from '../../../../models/user-state';

export interface IComponentWizardProps {
    clustersModel: ClustersModel;
    cradleComponentsModel: CradleComponents;
    isOpen: boolean;

    onSave(newComponent: ICradleComponent): void;

    onClose(): void;
}

export interface IComponentEnvVariable {
    secure: boolean;
    name: string;
    value: string;
}

const emptyComponent = {
    architecture: Architecture.amd64,
    createdBy: '',
    createdAt: new Date(),
    description: '',
    home: 'https://cradle.analoggarage.com/',
    icon: 'https://hub.helm.sh/assets/images/placeholder.png',
    id: '',
    keywords: [],
    name: '',
    releaseName: '',
    template: {
        component: '',
        chartName: 'apc-component-chart',
        chartVersion: '0.0.2',
        namespace: 'app',
        containerRepos: [],
        serviceAccounts: ['default'],
        setFileOptions: [],
        setOptions: [
            {
                key: 'image.repository',
                value: '',
            },
            {
                key: 'image.tag',
                value: 'latest',
            },
            {
                key: 'replicaCount',
                value: '1',
            },
        ],
    },
    title: '',
    updatedAt: new Date(),
    updatedBy: '',
    values: [],
    version: '0.0.1',
};

@observer
export class ComponentWizard extends Component<IComponentWizardProps, any> {
    @observable
    isSaving = false;

    @observable
    selectedTab = 'main';

    @observable
    envVars: IComponentEnvVariable[] = [
        {
            name: 'NAME',
            value: 'Value',
            secure: false,
        },
    ];

    @observable
    newComponent: ICradleComponent = clone(emptyComponent);

    constructor(props: any) {
        super(props);
        this.handlePopupSave = this.handlePopupSave.bind(this);
        this.close = this.close.bind(this);
    }

    renderMainPanel() {
        return (
            <div>
                <FormGroup
                    label="Component Name"
                    labelFor="name-input"
                    helperText="Letters, numbers and dashes."
                    labelInfo="*"
                >
                    <InputGroup
                        disabled={this.isSaving}
                        defaultValue={this.newComponent.name}
                        required={true}
                        minLength={1}
                        pattern="[a-z]{1}[a-z0-9-]+"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.newComponent.name = e.target.value;
                        }}
                        id="text-input"
                        placeholder="your-new-component-name"
                    />
                </FormGroup>
                <FormGroup
                    label="Docker Image"
                    labelFor="docker-image-input"
                    labelInfo="*"
                    helperText="Ensure that Docker repository is accessible by the cluster."
                >
                    <InputGroup
                        disabled={this.isSaving}
                        required={true}
                        minLength={1}
                        defaultValue={
                            this.newComponent.template.setOptions[0].value
                        }
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            (this.newComponent.template.setOptions[0].value =
                                e.currentTarget.value)
                        }
                        id="docker-image-input"
                        placeholder="nginx"
                    />
                </FormGroup>
                <div className={'flex-row'}>
                    <div>
                        <FormGroup
                            label="Version"
                            labelFor="docker-image-version-input"
                            labelInfo="*"
                        >
                            <InputGroup
                                id="docker-image-version-input"
                                disabled={this.isSaving}
                                required={true}
                                minLength={1}
                                defaultValue={this.newComponent.version}
                                onChange={(e: FormEvent<HTMLInputElement>) => {
                                    this.newComponent.version =
                                        e.currentTarget.value;
                                    this.newComponent.template.setOptions[1].value =
                                        e.currentTarget.value;
                                }}
                                placeholder="stable-alpine"
                            />
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup
                            label={'Architecture'}
                            labelFor="name-input"
                            helperText=""
                        >
                            <HTMLSelect
                                disabled={this.isSaving}
                                required={true}
                                options={Object.values(Architecture)}
                                defaultValue={this.newComponent.architecture}
                                onChange={(event) =>
                                    this.newComponent.architecture
                                }
                            ></HTMLSelect>
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup
                            label="Number of copies"
                            labelFor="copies-number-input"
                            helperText=""
                        >
                            <NumericInput
                                disabled={this.isSaving}
                                id="copies-number-input"
                                value={
                                    this.newComponent.template.setOptions[2]
                                        .value
                                }
                                min={1}
                                size={7}
                                required={true}
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    (this.newComponent.template.setOptions[2].value =
                                        e.currentTarget.value)
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
                <small>
                    <i className={Classes.TEXT_MUTED}>
                        *Starred fields are required fields.
                    </i>
                </small>
            </div>
        );
    }

    renderExtraPanel() {
        return (
            <div>
                <FormGroup
                    label="Component Human Friendly Name"
                    labelFor="hfn-input"
                >
                    <InputGroup
                        disabled={this.isSaving}
                        defaultValue={this.newComponent.title}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            (this.newComponent.title = e.currentTarget.value)
                        }
                        id="hfn-input"
                        placeholder="Cats/Dogs Classifier"
                    />
                </FormGroup>

                <FormGroup label="Description" labelFor="description-input">
                    <InputGroup
                        disabled={this.isSaving}
                        defaultValue={this.newComponent.description}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            (this.newComponent.description =
                                e.currentTarget.value)
                        }
                        id="description-input"
                        placeholder="One sentence component description"
                    />
                </FormGroup>
                <FormGroup
                    label="Home Page URL"
                    labelFor="home-input"
                    helperText="URL to a page with component readme."
                >
                    <InputGroup
                        disabled={this.isSaving}
                        type={'url'}
                        defaultValue={this.newComponent.home}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                            (this.newComponent.home = e.currentTarget.value)
                        }
                        id="home-input"
                        placeholder="nginx"
                    />
                </FormGroup>
                <FormGroup>
                    <Switch
                        checked={this.newComponent.shared}
                        onChange={(event) =>
                            (this.newComponent.shared =
                                event.currentTarget.checked)
                        }
                    >
                        Shared
                    </Switch>
                </FormGroup>
            </div>
        );
    }

    renderEnvPanel() {
        return (
            <div>
                <HTMLTable condensed={true}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.envVars.map((evar, index) => {
                            return (
                                <tr key={`envVar${index}`}>
                                    <td>
                                        <InputGroup
                                            fill={true}
                                            disabled={this.isSaving}
                                            placeholder="VARIABLE_NAME"
                                            pattern={'[a-zA-Z]{1}[a-zA-Z_-]+'}
                                            defaultValue={evar.name}
                                            minLength={1}
                                            onChange={(
                                                e: FormEvent<HTMLInputElement>
                                            ) =>
                                                (evar.name =
                                                    e.currentTarget.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <InputGroup
                                            placeholder="Various Value"
                                            disabled={this.isSaving}
                                            fill={true}
                                            defaultValue={evar.value}
                                            onChange={(
                                                e: FormEvent<HTMLInputElement>
                                            ) =>
                                                (evar.value =
                                                    e.currentTarget.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                this.envVars.splice(index, 1);
                                            }}
                                            intent={Intent.DANGER}
                                            minimal={true}
                                            icon={IconNames.TRASH}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <Button
                                    small={true}
                                    minimal={true}
                                    intent={Intent.PRIMARY}
                                    onClick={() => this.addNewEnvVar()}
                                >
                                    Add Variable
                                </Button>
                            </td>
                        </tr>
                    </tfoot>
                </HTMLTable>
            </div>
        );
    }

    addNewEnvVar() {
        this.envVars.push({
            name: 'NEW_VAR_NAME',
            value: '',
            secure: false,
        });
    }

    close() {
        if (this.isSaving) return;
        this.props.onClose();
    }

    render() {
        return (
            <form onSubmit={this.handlePopupSave}>
                <Dialog
                    isOpen={this.props.isOpen}
                    icon={IconNames.NEW_OBJECT}
                    onClose={this.close}
                    title="Add New Component"
                >
                    <div className={Classes.DIALOG_BODY}>
                        <Tabs
                            selectedTabId={this.selectedTab}
                            onChange={(e: string) => (this.selectedTab = e)}
                        >
                            <Expander />
                            <Tab
                                id={'main'}
                                title={'General Information'}
                                panel={this.renderMainPanel()}
                            />
                            <Tab
                                id={'env'}
                                title={'Environment Variables'}
                                panel={this.renderEnvPanel()}
                            />
                            <Tab
                                id={'extra'}
                                title={'Extras'}
                                panel={this.renderExtraPanel()}
                            />
                        </Tabs>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <AnchorButton
                                alignText={Alignment.LEFT}
                                minimal={true}
                                icon={IconNames.HELP}
                                target="_blank"
                                href="https://cradle.analoggarage.com/docs/"
                            >
                                Learn More
                            </AnchorButton>
                            <Expander />
                            <Button
                                disabled={this.isSaving}
                                onClick={() => this.props.onClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                intent={Intent.SUCCESS}
                                type={'submit'}
                                onClick={this.handlePopupSave}
                                disabled={this.isSaving}
                            >
                                Save Component
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </form>
        );
    }

    async handlePopupSave() {
        this.isSaving = true;
        this.envVars.forEach((vr) => {
            this.newComponent.template.setOptions.push({
                key: 'env.' + vr.name,
                value: vr.value,
            });
        });
        this.newComponent.createdBy = UserModel.userId;
        this.newComponent.updatedAt = new Date();
        this.newComponent.updatedBy = UserModel.userId;
        if (this.newComponent.shared) {
            await this.props.cradleComponentsModel.addNew(this.newComponent);
        }
        this.props.onSave(this.newComponent);
        this.props.onClose();
        this.envVars = [];
        this.newComponent = clone(emptyComponent);
        this.isSaving = false;
    }
}
