import { action, computed, observable } from 'mobx';
import compareVersions from 'compare-versions';
import { ComponentTemplatesService } from '../service/charts';
import { ChartCard, ICmChart } from '../types/chart';
import { FormEvent } from 'react';
import { IOptionProps, ProgressBar } from '@blueprintjs/core';
import { AppToaster } from '../service/toaster';
import { Intent } from '@blueprintjs/core/lib/esnext';
import { IconNames } from '@blueprintjs/icons';

import React from 'react';
import { Storage } from '../service/storage';
const { REACT_APP_CHARTMUSEUM_BASE_URL } = process.env;

export class ChartMuseumModel {
    private cts: ComponentTemplatesService;
    @observable charts: ChartCard[];

    @observable company = 'adi';
    @observable department = 'garage';
    @observable environment = 'development';
    @observable filter = '';
    @observable repoUrl: string =
        REACT_APP_CHARTMUSEUM_BASE_URL + '/adi/garage/development';

    constructor() {
        this.cts = new ComponentTemplatesService();
        this.charts = [];
        this.company = Storage.get('company') || 'adi';
        this.department = Storage.get('department') || 'adi';
        this.environment = Storage.get('environment') || 'development';
    }

    @computed
    get departments(): IOptionProps[] {
        return [
            {
                value: 'garage',
                label: 'Analog Garage & Research',
            },
            {
                value: 'cradle',
                label: 'Cradle Infrastructure',
            },
            {
                value: 'automotive',
                label: 'Automotive',
            },
            {
                value: 'aerospace',
                label: 'Aerospace, Communications & Defense',
            },
            {
                value: 'healthcare',
                label: 'Digital Healthcare',
            },
            {
                value: 'industrial',
                label: 'Industrial Applications',
            },
            {
                value: 'consumer',
                label: 'Consumer Applications',
            },
            {
                value: 'corporate',
                label: 'Corporate, Financial & IT',
            },
            {
                value: 'other',
                label: 'Other',
            },
        ];
    }

    @computed
    get environments(): IOptionProps[] {
        return [
            { value: 'development', label: 'Development' },
            { value: 'qa', label: 'Testing & QA' },
            { value: 'staging', label: 'Staging' },
            { value: 'production', label: 'Production' },
        ];
    }

    @computed
    get items(): ChartCard[] {
        return this.charts.filter((chart) =>
            chart.name.toLowerCase().includes(this.filter.toLowerCase())
        );
    }

    @action
    async load() {
        const { company, department, environment } = this;
        this.repoUrl =
            REACT_APP_CHARTMUSEUM_BASE_URL +
            `/${company}/${department}/${environment}`;
        const result = await this.cts.listCharts({
            company,
            department,
            environment,
        });
        this.charts = Object.entries(result).map((chart) => ({
            name: chart[0],
            isSelected: false,
            versions: chart[1]
                .sort((a: ICmChart, b: ICmChart) =>
                    compareVersions(b.version, a.version)
                )
                .map((a: ICmChart) => {
                    a.created = new Date(a.created).toLocaleString();
                    if (a.urls) {
                        a.urls = a.urls.map((url: string) => {
                            if (!url.startsWith('http')) {
                                if (url.startsWith('/')) {
                                    url = url.substr(1);
                                }
                                url =
                                    this.repoUrl +
                                    (this.repoUrl.endsWith('/') ? '' : '/') +
                                    url;
                            }
                            return url;
                        });
                    }
                    return a;
                }),
            description: chart[1][0].description || '-',
        }));
    }

    @action
    delete(chart: ICmChart) {
        const r = window.confirm(
            `Do you want to delete version ${chart.version}\nof "${chart.name}" chart\nfrom ${this.department}/${this.environment}?`
        );
        if (r) {
            alert('To be implemented!');
        }
    }

    @action
    filterChangeHandler() {
        return (event: FormEvent<HTMLInputElement>) => {
            console.log(event);
            this.filter = event.currentTarget.value;
            this.load().then();
        };
    }

    @action
    uploadHandler() {
        return async (event: FormEvent<HTMLInputElement>) => {
            console.log('uploadHandler', event.currentTarget.files);

            const { company, department, environment } = this;

            const { files } = event.currentTarget;

            if (!files) {
                return;
            }
            const file = files[0];
            if (!file) {
                return;
            }

            const { name } = file;

            this.reportProgress(name, 0.1);
            try {
                await this.cts.uploadChart(file, {
                    company,
                    department,
                    environment,
                });
                this.reportProgress(name, 0.75);
                await this.load();
                this.reportProgress(name, 1);
            } catch (e) {
                await this.load();
                this.reportProgress(name, -1);
            }
        };
    }

    reportProgress(name: string, progress: number) {
        const toastId = `uploadToast${name}`;
        if (progress === -1) {
            AppToaster.show(
                {
                    message: `Cannot upload ${name}! Make sure that you have a correct file version`,
                    intent: Intent.DANGER,
                },
                toastId
            );
        } else if (progress === 1) {
            AppToaster.show(
                {
                    message: `Chart ${name} has been added!`,
                    intent: Intent.SUCCESS,
                },
                toastId
            );
        } else {
            AppToaster.show(
                {
                    message: (
                        <ProgressBar intent={Intent.PRIMARY} value={progress}>
                            `Uploading ${name}...`
                        </ProgressBar>
                    ),
                    icon: IconNames.CLOUD_UPLOAD,
                    intent: Intent.NONE,
                },
                toastId
            );
        }
    }

    @action
    setDepartment(department: string) {
        this.department = department;
        Storage.set('department', department);
    }

    @action
    setEnvironment(environment: string) {
        this.environment = environment;
        Storage.set('environment', environment);
    }

    @action
    selectChangeHandler(prop: string) {
        return (event: FormEvent<HTMLInputElement>) => {
            if (prop === 'department') {
                this.setDepartment(event.currentTarget.value);
            } else if (prop === 'environment') {
                this.setEnvironment(event.currentTarget.value);
            }
            this.load().then();
        };
    }

    @action
    select(name: string) {
        this.charts = this.charts.map((chart) => {
            return {
                ...chart,
                isSelected: chart.name === name && !chart.isSelected,
            };
        });
    }
}
