import { IFormParamValidator } from './provision';
import { SettingsGroup } from './settings-group';
import { FormParamTypeEnum } from './form-param-type';

export type FormFieldType = FormParamTypeEnum | string;

export enum TypeCast {
    Number,
    Boolean,
    CSVStrings,
    String,
}

export interface IFormComponent {
    type: FormFieldType;
    label: string;
    description: string;
    explanation?: {
        details: string;
        url?: string;
    };
    required?: string | boolean;
    placeholder?: string;
    k: string;
    v: string;
    min?: string;
    max?: string;
    autoComplete?: string;
    isValid?: IFormParamValidator;
    cast?: TypeCast;
    list?: string[];
    mapper?: IProvisionParamMapper;
    disabled?: boolean;
}

export interface IFormContainer {
    k: string;
    type: FormFieldType;
    indent?: boolean;
    label?: string;
    children?: IFormParam[];
}

export type IFormParam = IFormComponent | IFormContainer;

export type IProvisionParamMapper = Map<string, IFormFieldMapTarget[]>;

export type IFormFieldMapTarget = IFormParam & {
    g: SettingsGroup;
};

export function isFormContainer(o: IFormParam): o is IFormContainer {
    return (o as IFormContainer).children !== undefined;
}

export function isFormComponent(o: IFormParam): o is IFormComponent {
    return (o as IFormComponent).description !== undefined;
}
