import gcpLogo from './gcp.svg';
import { prometheusConfigParameter } from '../prometheus-config-parameter';
import { certManagerConfigParameter } from '../cert-manager-config-parameter';
import React from 'react';
import { IaaSProvider } from '../../../../types/iaas-provider';
import { SettingsGroup } from '../../../../types/settings-group';
import { IClusterInfrastructureProvider } from '../../../../types/cluster-infrastructure-provider-interface';

export const gcpIaasProvider: IClusterInfrastructureProvider = {
    id: IaaSProvider.GCP,
    name: 'GCP',
    logo: gcpLogo,
    description: (
        <span key="gcpD">
            Coming Soon: Install a Cradle cluster on Google Cloud Provider
            account that <b>you own</b>.
        </span>
    ),
    details: (
        <div key="gcpDet">
            <p>
                We are working hard on making this available. Please check in
                again soon to see if we achieved this goal.
            </p>

            <p>
                If you have a pressing need and can't wait, reach out to us via
                email:{' '}
                <a rel="noreferrer"
                    target="_blank"
                    href="mailto:cradle_maintainers@analog.com?Subject=AzureOnCradle"
                >
                    cradle_maintainers@analog.com
                </a>
                .<i> We'll do our best to help you.</i>
            </p>
        </div>
    ),
    supported: false,
    config: {
        [SettingsGroup.Basic]: [],
        [SettingsGroup.Advanced]: [
            prometheusConfigParameter,
            certManagerConfigParameter,
        ],
    },
};
