import React, { Component } from 'react';
import {
    Button,
    Callout,
    Card,
    Classes,
    Collapse,
    H1,
    Icon,
    Intent,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
} from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Redirect, withRouter } from 'react-router';
import {
    AutomationType,
    IaaSProvider,
    IClusterInfrastructureProvider,
    IClusterProps,
    IProvisionStage,
    ProvisionStage,
    ProvisionStatusEnum,
    SettingsGroup,
    UIState,
} from '../../../types';

import './provision.scss';
import { StatefulComponent } from '../../common/stateful/stateful';
import { AppNavigator } from '../../../models/navigation';
import { provisionModel } from '../../../models/provision';
import { IconNames } from '@blueprintjs/icons';
import { ButtonLink } from '../../common/links/button-link';
import {
    renderBareMetalConfirmationMessage,
    renderBareMetalProvisionInstructions,
} from './iaas/bare-metal';
import { renderAwsProvisionInstructions } from './iaas/aws';
import { ReactTerm } from '../../common/react-term/react-term';
import { FormParameter } from '../../common/form/form';

const { RUNNING_TEXT } = Classes;

@observer
class Provision extends Component<IClusterProps> {
    @observable isProvisionLogShown = false;

    @observable uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);
        // provisionModel = new ProvisionModel();
        this.renderProvisioning = this.renderProvisioning.bind(this);
    }

    useQuery() {
        return new URLSearchParams(this.props.location.search);
    }

    async load() {
        this.uiLoadState = UIState.Loading;
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');

        try {
            const clusterId = this.props.match.params.clusterId;
            if (clusterId) {
                await provisionModel.loadCluster(clusterId);
                AppNavigator.add(
                    `/clusters/${clusterId}/`,
                    provisionModel.cluster
                        ? provisionModel.cluster.clusterName
                        : 'Cluster'
                );
                AppNavigator.add(
                    `/clusters/${clusterId}/provision/`,
                    'Installation'
                );
                if (provisionModel.cluster) {
                    this.uiLoadState = UIState.Loaded;
                    return;
                }
            }
        } catch (e) {
            console.log(e);
        }
        this.uiLoadState = UIState.Failed;
    }

    async componentDidMount() {
        provisionModel.isProvisioningForced =
            !!this.useQuery().get('startOver');

        this.load().then();
    }

    componentWillUnmount(): void {
        provisionModel.clearLogRefreshTimer();
        AppNavigator.clear();
    }

    public render() {
        return (
            <div className="provisionComponent">
                <StatefulComponent state={this.uiLoadState}>
                    <div>{this.renderUi()}</div>
                </StatefulComponent>
            </div>
        );
    }

    renderProvisioned() {
        if (!provisionModel.cluster) return;

        return (
            <div className="provisioned">
                <H1>
                    Cradle installed on{' '}
                    <span className={Classes.TEXT_MUTED}>
                        {provisionModel.cluster.clusterName}
                    </span>
                </H1>
                <div className="flex-spaced">
                    {provisionModel.logs.isOpen
                        ? this.renderProvisionLogs()
                        : undefined}
                </div>
                <ButtonLink to={'/clusters/'}>Back</ButtonLink>
                <p>&nbsp;</p>
            </div>
        );
    }

    renderProvisionLogs() {
        return (
            <div className={'logList'}>
                <ReactTerm
                    text={provisionModel.logs.text}
                    autoScroll={true}
                    fullScreen={true}
                />
            </div>
        );
    }

    renderProvisioning() {
        console.log('renderProvisioning', provisionModel.cluster);

        if (!provisionModel.cluster) return;
        window.location.pathname = `/clusters/${provisionModel.cluster.id}/logs/`;
        return '';
    }

    redirect(to: string) {
        return <Redirect to={to} />;
    }

    renderUnprovisioned() {
        if (!provisionModel.cluster) return;
        return (
            <div className="unprovisioned">
                <H1>
                    Install Cradle on{' '}
                    <span className={Classes.TEXT_MUTED}>
                        {provisionModel.cluster.clusterName}
                    </span>
                </H1>
                <p>
                    This process will stand up a new Cradle cluster on the
                    infrastructure of your choice.
                </p>
                {this.renderSteps()}
                {provisionModel.provider
                    ? this.renderSelectedProvider()
                    : this.renderProviderList()}
            </div>
        );
    }

    renderUi() {
        if (!provisionModel.cluster) return;

        switch (provisionModel.cluster.provisionStatus) {
            case ProvisionStatusEnum.Provisioned:
                return this.renderProvisioned();

            case ProvisionStatusEnum.Provisioning:
                return this.renderProvisioning();

            case ProvisionStatusEnum.ProvisionFailed:
            case ProvisionStatusEnum.Unprovisioned:
                return this.renderUnprovisioned();
        }
    }

    renderSteps() {
        return (
            <div className={'steps'}>
                {provisionModel.stages.map((v, i) => this.renderStep(v, i))}
            </div>
        );
    }

    renderStep(v: IProvisionStage, i: number) {
        return (
            <div
                key={`step${i}`}
                className={`step status${v.stage}`}
                onClick={() => {
                    v.stage === ProvisionStage.Complete &&
                        provisionModel.setActiveStage(i);
                }}
            >
                <div key="s" className="number">
                    {v.stage === ProvisionStage.Complete ? '✔' : i + 1}
                </div>
                <div className="name">{v.name}</div>
            </div>
        );
    }

    renderProviderList() {
        return (
            <div>
                <div className={'providerList'}>
                    {provisionModel.providers.map(
                        this.renderProvider.bind(this)
                    )}
                </div>
                <div className={'footer'}>
                    <ButtonLink to={'/clusters/'}>Cancel</ButtonLink>
                </div>
            </div>
        );
    }

    renderProvider(provider: IClusterInfrastructureProvider) {
        const { supported } = provider;
        const isSelectedForPreview =
            provisionModel.previewProvider &&
            provisionModel.previewProvider.id === provider.id;
        return (
            <Card
                key={provider.name}
                elevation={isSelectedForPreview ? 2 : 1}
                interactive={supported}
                className="provider"
                onClick={() => provisionModel.setProviderPreview(provider)}
            >
                <div className="flex-spaced">
                    {this.renderLogo(provider)}
                    <div className="info">
                        <h3>{provider.name}</h3>
                        <p className={Classes.TEXT_MUTED}>
                            {provider.description}
                        </p>
                        <Collapse
                            isOpen={isSelectedForPreview}
                            className="intro"
                        >
                            <div className="details">{provider.details}</div>
                            <p>
                                {supported && (
                                    <Button
                                        disabled={!supported}
                                        intent={Intent.PRIMARY}
                                        onClick={() =>
                                            provisionModel.selectProvider(
                                                provider
                                            )
                                        }
                                    >
                                        Select {provider.name}
                                    </Button>
                                )}
                            </p>
                        </Collapse>
                    </div>
                    <div>
                        <Icon
                            icon={
                                isSelectedForPreview
                                    ? IconNames.CHEVRON_UP
                                    : IconNames.CHEVRON_DOWN
                            }
                        />
                    </div>
                </div>
            </Card>
        );
    }

    renderLogo(provider: IClusterInfrastructureProvider) {
        return (
            <div className="logo">
                <img
                    src={provider.logo}
                    className={provider.supported ? '' : 'unsupported'}
                    width={64}
                    height={64}
                    alt={provider.name}
                />
            </div>
        );
    }

    renderSettingsForm() {
        return (
            <form
                action="#"
                method={'POST'}
                onSubmit={(e) => {
                    e.preventDefault();
                    provisionModel.saveSettings();
                }}
            >
                <Tabs
                    className="settingsTabs"
                    selectedTabId={provisionModel.settingsGroup}
                    onChange={(newTabId) =>
                        (provisionModel.settingsGroup =
                            newTabId as SettingsGroup)
                    }
                >
                    <div className="settingsTabsTitle">Settings</div>
                    <Tabs.Expander />
                    <Tab
                        key={SettingsGroup.Basic}
                        id={SettingsGroup.Basic}
                        title={SettingsGroup.Basic}
                        panel={this.renderSettingsPanel(SettingsGroup.Basic)}
                    />
                    <Tab
                        key={SettingsGroup.Advanced}
                        id={SettingsGroup.Advanced}
                        title={SettingsGroup.Advanced}
                        panel={this.renderSettingsPanel(SettingsGroup.Advanced)}
                    />
                </Tabs>
                <div>
                    {provisionModel.isSaving && (
                        <Callout
                            intent={Intent.WARNING}
                            icon={IconNames.WARNING_SIGN}
                            title={'Preparing your installation'}
                        >
                            <p>
                                Please be patient while we encrypt and store
                                your installation parameters. This may take
                                several seconds to finish.
                            </p>
                        </Callout>
                    )}
                </div>
                <div className="flex-spaced footer">
                    <div className="bp3-flex-expanded">&nbsp;</div>
                    <div>
                        <Button
                            key={'rsfuiBack'}
                            disabled={provisionModel.isSaving}
                            onClick={() => provisionModel.cancel()}
                        >
                            Back
                        </Button>
                        <Button
                            key={'rsfuiOK'}
                            disabled={
                                !provisionModel.isFormValid ||
                                provisionModel.isSaving
                            }
                            loading={provisionModel.isSaving}
                            type={'submit'}
                            intent={Intent.PRIMARY}
                            rightIcon={IconNames.CHEVRON_RIGHT}
                        >
                            Install
                        </Button>
                    </div>
                </div>
            </form>
        );
    }

    renderAutomationSelectorUi() {
        if (!provisionModel.provider) return;
        return (
            <form
                action="#"
                method={'POST'}
                onSubmit={(e) => {
                    e.preventDefault();
                    provisionModel.selectAutomation();
                }}
            >
                <div className="confirmationDetails">
                    <div>
                        <p className={RUNNING_TEXT}>
                            Your installation configuration has been saved, and
                            we’re ready to install Cradle! You can now choose
                            from one of two ways to do this:
                        </p>
                        <RadioGroup
                            className={'advancedRadioGroup'}
                            onChange={(event) =>
                                (provisionModel.provisionType = event
                                    .currentTarget.value as AutomationType)
                            }
                            selectedValue={provisionModel.provisionType}
                        >
                            <Radio
                                value={AutomationType.Automatic}
                                className={
                                    provisionModel.provisionType ===
                                    AutomationType.Automatic
                                        ? 'selected'
                                        : undefined
                                }
                            >
                                <div className="radio">
                                    <h4>Automatic Installation</h4>
                                    <p className={RUNNING_TEXT}>
                                        We will automatically attempt to install
                                        your Cradle cluster for you. This will
                                        involve connecting to your
                                        infrastructure using the credentials
                                        that you have provided, and installing
                                        Cradle on it. Recommended for cloud
                                        based installations, or bare metal
                                        installations where the infrastructure
                                        is reachable over the public internet.
                                    </p>
                                    <p className={RUNNING_TEXT}>
                                        If you choose this option, you need to
                                        ensure that your infrastructure is
                                        reachable over the public cloud. Double
                                        check your firewall settings and
                                        security roles to ensure that your
                                        infrastructure is accessible.
                                    </p>
                                </div>
                            </Radio>
                            <Radio
                                value={AutomationType.Manual}
                                className={
                                    provisionModel.provisionType ===
                                    AutomationType.Manual
                                        ? 'selected'
                                        : undefined
                                }
                            >
                                <div className="radio">
                                    <h4>Manual Installation</h4>
                                    <p className={RUNNING_TEXT}>
                                        You can perform the installation by
                                        running a container image that we
                                        provide. The installation steps are
                                        really simple, and we will provide you
                                        with installation instructions.
                                        Recommended for bare metal installations
                                        or if your infrastructure is not
                                        reachable over the public internet.
                                    </p>
                                    <p className={RUNNING_TEXT}>
                                        With this option, you will need an{' '}
                                        <b>
                                            <i>installation node</i>
                                        </b>{' '}
                                        (computer) that can run Docker
                                        containers. The installation node must
                                        be on a network that allows it to
                                        connect to your infrastructure. You will
                                        have <b>12 hours</b> to complete the
                                        installation, after which your
                                        installation configuration will expire,
                                        and you will have to restart this
                                        process.
                                    </p>
                                </div>
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>
                <div className="flex-spaced footer">
                    <div className="bp3-flex-expanded">&nbsp;</div>
                    <div>
                        <Button
                            key={'rasuiBack'}
                            onClick={() => provisionModel.cancel()}
                        >
                            Back
                        </Button>
                        <Button
                            key={'rasuiContinue'}
                            type={'submit'}
                            intent={Intent.PRIMARY}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        );
    }

    renderProvisionInstructions() {
        if (!provisionModel.provider || !provisionModel.instructions) return;

        switch (provisionModel.provider.id) {
            case IaaSProvider.AWS:
                return renderAwsProvisionInstructions(
                    provisionModel.instructions
                );
            case IaaSProvider.Bare:
            case IaaSProvider.Edge:
                return renderBareMetalProvisionInstructions(
                    provisionModel.instructions
                );
        }
    }

    renderConfirmationUi() {
        if (!provisionModel.provider) return;
        if (!provisionModel.instructions) return;


        return (
            <form
                action="#"
                method={'POST'}
                onSubmit={(e) => {
                    e.preventDefault();
                    provisionModel.provisionCluster();
                }}
            >
                <div className="confirmationDetails">
                    <div className="instructions">
                        {provisionModel.provider.commonProvisionInstructions}
                    </div>
                    <div>
                        {provisionModel.provisionType ===
                        AutomationType.Automatic ? (
                            <div>
                                {provisionModel.provider.id ===
                                IaaSProvider.Bare ? (
                                    renderBareMetalConfirmationMessage()
                                ) : (
                                    <div>
                                        <p className={RUNNING_TEXT}>
                                            We’re ready to go, and will start
                                            the installation process as soon as
                                            you press the <i>Confirm</i> button.
                                            Remember that we will be creating
                                            components on your cloud account,
                                            and you will be charged for the
                                            duration that you keep these
                                            components running.
                                        </p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            this.renderProvisionInstructions()
                        )}
                    </div>
                </div>
                <div className="flex-spaced footer">
                    <div className="bp3-flex-expanded">&nbsp;</div>
                    <div>
                        <Button
                            key={'cuiCancel'}
                            onClick={() => provisionModel.cancel()}
                            disabled={provisionModel.isProvisioning}
                        >
                            Back
                        </Button>

                        <Button
                            key={'cuiContinue'}
                            loading={provisionModel.isProvisioning}
                            disabled={provisionModel.isProvisioning}
                            type={'submit'}
                            intent={Intent.PRIMARY}
                        >
                            {provisionModel.provisionType ===
                            AutomationType.Automatic ? (
                                <span>Confirm</span>
                            ) : (
                                <span>Done</span>
                            )}
                        </Button>
                    </div>
                </div>
            </form>
        );
    }

    renderConfirmedUi() {
        if (!provisionModel.provider) return;
        return (
            <div className={'provisionConfirmed'}>
                <Callout intent={Intent.SUCCESS} title="We’re on our way!">
                    <p className={RUNNING_TEXT}>
                        The installation process has been started, and can up to
                        30 - 45 minutes to complete. You can view the{' '}
                        <a  id="renderProvisioning"
                            onClick={(e) => {
                                e.preventDefault();
                                this.renderProvisioning();
                            }}
                            href={'#renderProvisioning'}
                        >
                            installation logs
                        </a>{' '}
                        to track progress, and learn a few neat tips and tricks
                        while you wait.
                    </p>
                </Callout>
                <div className="flex-spaced footer">
                    <div className="bp3-flex-expanded">&nbsp;</div>
                    <div>
                        <ButtonLink to="/clusters/" text="Clusters" />
                        <Button
                            icon={IconNames.LIST}
                            intent={Intent.PRIMARY}
                            loading={provisionModel.logs.isRefreshingLogs}
                            onClick={() => {
                                this.renderProvisioning();
                            }}
                        >
                            View logs
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderSelectedProvider() {
        if (!provisionModel.provider) return;
        const { provider } = provisionModel;
        return (
            <Card
                className="selectedProvider"
                key={`thisProvider${provider.id}`}
            >
                <div className="flex-spaced">
                    {this.renderLogo(provider)}
                    <div className="info">
                        <h3>{provider.name}</h3>
                        <div
                            className={Classes.TEXT_MUTED}
                            key={`pds${provider.id}`}
                        >
                            {provider.description}
                        </div>
                    </div>
                    <div>
                        <Button
                            minimal={true}
                            disabled={
                                provisionModel.isSaving ||
                                provisionModel.stages[1].stage ===
                                    ProvisionStage.Complete
                            }
                            intent={Intent.PRIMARY}
                            onClick={() =>
                                provisionModel.selectProvider(undefined)
                            }
                        >
                            {' '}
                        </Button>
                    </div>
                </div>
                <div>
                    {provisionModel.stages[1].stage === ProvisionStage.Active
                        ? this.renderSettingsForm()
                        : undefined}

                    {provisionModel.stages[2].stage === ProvisionStage.Active
                        ? this.renderAutomationSelectorUi()
                        : undefined}

                    {provisionModel.stages[3].stage === ProvisionStage.Active
                        ? this.renderConfirmationUi()
                        : undefined}

                    {provisionModel.stages[3].stage === ProvisionStage.Complete
                        ? this.renderConfirmedUi()
                        : undefined}
                </div>
            </Card>
        );
    }

    renderSettingsPanel(group: SettingsGroup) {
        return (
            <div className="settingsPanel" key={`rsp-${group}`}>
                {provisionModel.fm.fields[group].length > 0 ? (
                    this.renderProvisionParams(group)
                ) : (
                    <p className={Classes.TEXT_MUTED}>
                        There are no settings of such type.
                    </p>
                )}
            </div>
        );
    }

    renderProvisionParams(group: SettingsGroup) {
        if (!provisionModel.provider) return;
        return (
            <div key={`rpp-${group}`}>
                {provisionModel.fm.fields[group].map((p) => (
                    <FormParameter
                        fm={provisionModel.fm}
                        p={p}
                        key={`rpp-fp-${group}-${p.k}`}
                    />
                ))}
            </div>
        );
    }
}

export const ProvisionComponent = withRouter<IClusterProps, any>(Provision);
