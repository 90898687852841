import { IFormParamValidator } from '../../../types/provision';
import { IFormComponent } from '../../../types/form-param';

export function isNotEmpty(param: IFormComponent) {
    return param.v.length > 0;
}

export function minLimitValidator(m: number): IFormParamValidator {
    return (param: IFormComponent) => {
        const value = parseInt(param.v);
        return value >= m && value > 0;
    };
}

export function maxLimitValidator(m: number): IFormParamValidator {
    return (param: IFormComponent) => {
        const value = parseInt(param.v);
        return value <= m && value > 0;
    };
}

export function rangeLimitValidator(
    min: number,
    max: number
): IFormParamValidator {
    return (param: IFormComponent) => {
        const value = parseInt(param.v);
        return value <= max && value > min;
    };
}

export function cidrValidator(): IFormParamValidator {
    const CIDR_REGEX = new RegExp('^([0-9]{1,3}.){3}[0-9]{1,3}($|/(16|24))$');
    return (param: IFormComponent) => {
        return CIDR_REGEX.test(param.v);
    };
}
