import { FormParamTypeEnum, IFormParam, TypeCast } from '../../../types';

export const certManagerConfigParameter: IFormParam = {
    type: FormParamTypeEnum.Checkbox,
    label: 'Enable automatic certificate generation and renewal.',
    k: 'cradleInstallCertManager',
    v: 'true',
    description: 'Use CertManager to automatically issue Let\'s Encrypt certificates',
    cast: TypeCast.Boolean,
    required: false,
};
