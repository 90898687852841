import { action, computed, observable } from 'mobx';
import { IProvisionConfiguration } from '../types/provision';

import { InfrastructureProviders } from '../components/clusters/provision/iass-providers';
import { ClustersService } from '../service/clusters';
import { ErrorToast } from '../service/toaster';
import { UserModel } from './user-state';
import { FormModel } from './form-model';
import {
    AutomationType,
    IClusterInfrastructureProvider,
    IInstructions,
    IProvisionStage,
    isFormComponent,
    ProvisionStage,
    SettingsGroup,
} from '../types';
import { LoggerAwareModel } from './logger-aware';

const SELECT_INFA_PROVIDER = 'Infrastructure';
const SELECTED_INFA_PROVIDER = SELECT_INFA_PROVIDER;

export class ProvisionModel extends LoggerAwareModel {
    fm: FormModel;

    @observable provider?: IClusterInfrastructureProvider;
    @observable previewProvider?: IClusterInfrastructureProvider;
    @observable settingsGroup: SettingsGroup = SettingsGroup.Basic;
    @observable provisionType: AutomationType = AutomationType.Automatic;

    @observable isSaving = false;
    @observable isProvisioning = false;

    @observable provisionParams: IProvisionConfiguration = {
        [SettingsGroup.Basic]: [],
        [SettingsGroup.Advanced]: [],
    };

    @observable instructions?: IInstructions;

    @observable isProvisioningForced: boolean = false;

    providers = InfrastructureProviders;

    @observable
    stages: IProvisionStage[] = [
        { name: SELECT_INFA_PROVIDER, stage: ProvisionStage.Active },
        { name: 'Configuration', stage: ProvisionStage.Inactive },
        { name: 'Deployment', stage: ProvisionStage.Inactive },
        { name: 'Confirmation', stage: ProvisionStage.Inactive },
    ];

    constructor() {
        super();
        this.fm = new FormModel();
    }

    @computed
    get isFormValid() {
        return this.fm.isValid;
    }

    @action
    setProviderPreview(p: IClusterInfrastructureProvider) {
        if (this.previewProvider && this.previewProvider.id === p.id) {
            this.previewProvider = undefined;
            return;
        }
        this.previewProvider = p;
    }

    @action
    setActiveStage(num: number) {
        for (let i = 0; i < this.stages.length; i++) {
            if (i < num) {
                this.stages[i].stage = ProvisionStage.Complete;
            } else if (i > num) {
                this.stages[i].stage = ProvisionStage.Inactive;
            } else {
                this.stages[i].stage = ProvisionStage.Active;
            }
        }
        if (num === 0) {
            this.selectProvider(undefined);
        }
    }

    @action
    selectProvider(provider: IClusterInfrastructureProvider | undefined) {
        if (!provider) {
            this.provider = provider;
            this.previewProvider = undefined;
            this.stages[0].name = SELECT_INFA_PROVIDER;
            this.fm.setFields({
                [SettingsGroup.Basic]: [],
                [SettingsGroup.Advanced]: [],
            });
            return;
        }
        this.provider = provider;
        this.previewProvider = undefined;
        this.setActiveStage(1);
        this.stages[0].name = SELECTED_INFA_PROVIDER;
        this.fm.setFields({ ...provider.config });
        this.fm.fields[SettingsGroup.Basic].forEach((cfg) => {
            if (
                isFormComponent(cfg) &&
                cfg.k === 'maintainerEmail' &&
                UserModel.user
            ) {
                cfg.v = UserModel.user.email;
            }
        });
        this.fm.setEnabled(true);
    }

    @action
    selectAutomation() {
        this.setActiveStage(3);
    }

    @action
    cancel() {
        if (this.stages[2].stage === ProvisionStage.Active) {
            this.setActiveStage(1);
            return;
        } else if (this.stages[3].stage === ProvisionStage.Active) {
            this.setActiveStage(2);
            return;
        }
        if (this.stages[1].stage === ProvisionStage.Active) {
            this.setActiveStage(0);
        }
    }

    @action
    provisionCluster() {
        if (
            !this.cluster ||
            !this.provider ||
            !this.instructions ||
            this.isSaving ||
            this.isProvisioning
        )
            return;
        this.isProvisioning = true;
        const { token } = this.instructions;
        ClustersService.provisionCluster(this.cluster.id, {
            cradleUpToken: token,
            infrastructureProvider: this.provider.id,
            automation: this.provisionType,
        })
            .then(() => {
                this.setActiveStage(4);
            })
            .catch(ErrorToast())
            .finally(() => {
                this.isProvisioning = false;
            });
    }

    @action
    saveSettings() {
        if (
            !this.cluster ||
            this.isSaving ||
            this.isProvisioning ||
            !this.provider
        )
            return;

        this.isSaving = true;
        this.fm.setSubmitted(true);

        ClustersService.saveSettings(
            this.cluster.id,
            this.fm.fields,
            this.provider.id
        )
            .then((r) => {
                this.setActiveStage(2);
                this.instructions = {
                    token: r.cradleUpToken,
                    details: r.message,
                };
            })
            .catch(ErrorToast())
            .finally(() => {
                this.isSaving = false;
                this.fm.setSubmitted(false);
                this.fm.validate();
            });
    }
}

export const provisionModel = new ProvisionModel();
