import { action, observable } from 'mobx';
import { ICluster } from '../types/cluster';
import { KnoxService } from '../service/knox';
import { ICertificateAuthority } from '../types/ca';
import { ICertificate } from '../types/certificate';
import { ICommonName } from '../types/common-name';
import { FormEvent } from 'react';
import { AppToaster, ErrorToast, SuccessToast } from '../service/toaster';
import { Intent } from '@blueprintjs/core';
import { ClusterAwareModel } from './cluster-aware';

export interface INewCertificate {
    commonName: string;
    certificateAuthorityCN: string;
    savePrivateKey: boolean;

    [prop: string]: string | boolean;
}

export class CertificatesModel extends ClusterAwareModel {
    @observable
    certs: ICertificate[] = [];

    @observable
    activeCertificateAuthority?: ICertificateAuthority;

    @observable
    newDialog: {
        isOpen: boolean;
        isValid: boolean;
        isDisabled: boolean;
        isCreating: boolean;
        cert: INewCertificate;
    };

    constructor() {
        super();
        this.newDialog = {
            isOpen: false,
            isCreating: false,
            isValid: false,
            isDisabled: false,
            cert: {
                certificateAuthorityCN: '',
                savePrivateKey: true,
                commonName: '',
            },
        };
    }

    @action
    showDialog() {
        this.newDialog.isOpen = true;
        if (this.newDialog.cert.commonName === '') {
            this.newDialog.cert.commonName =
                '<new>.' + this.newDialog.cert.certificateAuthorityCN;
        }
        this.validate();
    }

    @action
    hideDialog() {
        this.newDialog.isOpen = false;
    }

    @action
    async toggle(cert: ICertificate) {
        await this.hydrate(cert);
        if (cert.uiIsOpen) {
            cert.uiIsOpen = false;
            return;
        } else {
            this.certs.forEach((mcert) => {
                mcert.uiIsOpen = false;
            });
            cert.uiIsOpen = true;
        }
    }

    @action
    async hydrate(cert: ICertificate) {
        if (!this.cluster) return;
        if (!this.activeCertificateAuthority) return;
        if (cert.uiIsLoading) return;
        if (!cert.data) {
            cert.uiIsLoading = true;
            KnoxService.getCertificate(
                this.cluster.id,
                this.activeCertificateAuthority.commonName,
                cert.commonName
            )
                .then(({ data }) => {
                    cert.data = data;
                })
                .catch(ErrorToast())
                .finally(() => {
                    cert.uiIsLoading = false;
                });
        }
    }

    @action
    async submit(e: FormEvent) {
        e.preventDefault();
        if (!this.cluster) return;
        if (!this.activeCertificateAuthority) return;

        if (!this.cluster) {
            return;
        }
        if (!this.newDialog.isValid) {
            return;
        }

        if (this.newDialog.isDisabled) {
            return;
        }

        if (this.newDialog.isCreating) {
            return;
        }

        this.newDialog.isCreating = true;

        return KnoxService.createCertificate(
            this.cluster.id,
            this.newDialog.cert
        )
            .then((r) => {
                this.hideDialog();
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    message: 'New Certificate Authority has been created!',
                });
                return this.loadCerts();
            })
            .catch(ErrorToast())
            .finally(() => {
                this.newDialog.isCreating = false;
                this.newDialog.isDisabled = false;
            });
    }

    @action
    validate() {
        this.newDialog.isValid = this.newDialog.cert.commonName.length > 0;
    }

    @action
    updateField(event: FormEvent<HTMLInputElement>) {
        this.newDialog.cert[event.currentTarget.name] =
            event.currentTarget.value;

        this.validate();
    }

    @action
    async setCluster(cluster: ICluster) {
        if (!this.cluster || this.cluster.id !== cluster.id) {
            this.cluster = cluster;
        }
    }

    @action
    async loadCerts() {
        if (!this.cluster) {
            return;
        }
        if (!this.activeCertificateAuthority) {
            return;
        }
        console.log('load certificates for', this.cluster.id);
        const certs: ICertificate[] = [];
        const { items } = await KnoxService.listCertificates(
            this.cluster.id,
            this.activeCertificateAuthority.commonName
        );
        items.forEach((apiCert) => {
            if (!this.activeCertificateAuthority) return;
            for (let p in apiCert) {
                if (
                    Object.prototype.hasOwnProperty.call(apiCert, p) &&
                    p.endsWith(this.activeCertificateAuthority.commonName)
                ) {
                    certs.push({
                        commonName: p,
                        fingerprint: apiCert[p],
                    });
                }
            }
        });

        this.certs = certs.sort(cnSorter);
    }

    @action
    async setActiveCertificateAuthority(ca: ICertificateAuthority) {
        if (ca) {
            console.log('setActiveCertificateAuthority', ca, ca.commonName);
            this.activeCertificateAuthority = ca;
            this.newDialog.cert.certificateAuthorityCN = ca.commonName;

            await this.loadCerts();
        }
    }

    @action
    async delete(k: ICertificate) {
        const { commonName } = k;
        if (window.confirm(`Do you really want to remove ${commonName}?`)) {
            if (!this.cluster) return;
            if (!this.activeCertificateAuthority) return;
            k.uiIsDeleting = true;
            return KnoxService.deleteCertificate(
                this.cluster.id,
                this.activeCertificateAuthority.commonName,
                commonName
            )
                .then(SuccessToast(`${commonName}  has been removed`))
                .catch(ErrorToast(`Error during ${commonName} key removal`))
                .then(this.loadCerts.bind(this));
        }
    }
}

const cnSorter = (a: ICommonName, b: ICommonName) => {
    return a.commonName.localeCompare(b.commonName);
};
