import { ClusterAwareModel } from './cluster-aware';
import { action, observable } from 'mobx';
import { ClustersService } from '../service/clusters';
import { Storage } from '../service/storage';
import {
    PROVISION_LOG_TIPS_STORAGE_KEY,
    tips,
} from '../components/clusters/provision/tips';

const { REACT_APP_API_LOG_REFRESH_INTERVAL } = process.env;

export class LoggerAwareModel extends ClusterAwareModel {
    @observable logs = {
        isOpen: false,
        isRefreshingLogs: false,
        text: '...',
    };
    @observable tip = {
        isOpen:
            Storage.get(PROVISION_LOG_TIPS_STORAGE_KEY) === null
                ? true
                : Storage.get(PROVISION_LOG_TIPS_STORAGE_KEY),
        index: 0,
        value: tips[0],
    };
    logRefreshTimer?: number;

    @action
    clearLogRefreshTimer() {
        if (this.logRefreshTimer) {
            clearTimeout(this.logRefreshTimer);
            this.logRefreshTimer = undefined;
        }
    }

    @action
    watchLogs() {
        this.refreshLogs();
    }

    @action
    setText(text: string) {
        this.logs.text = text;
    }

    @action
    refreshLogs() {
        if (!this.cluster || this.logs.isRefreshingLogs) return;
        this.logs.isRefreshingLogs = true;
        ClustersService.getProvisionLogs(this.cluster.id)
            .then((response) => {
                const { data } = response;
                this.setText(data || 'No logs...');
            })
            .finally(() => {
                this.logs.isRefreshingLogs = false;
                this.clearLogRefreshTimer();
                // @ts-ignore
                this.logRefreshTimer = setTimeout(
                    this.refreshLogs.bind(this),
                    parseInt(REACT_APP_API_LOG_REFRESH_INTERVAL || '60000')
                );
            });
    }

    @action
    toggleTips() {
        this.tip.isOpen = !this.tip.isOpen;
        Storage.set(PROVISION_LOG_TIPS_STORAGE_KEY, this.tip.isOpen);
    }

    @action
    nextTip() {
        if (this.tip.index < tips.length - 1) {
            this.tip.index++;
        } else {
            this.tip.index = 0;
        }
        this.tip.value = tips[this.tip.index];
    }

    @action
    previousTip() {
        if (this.tip.index > 0) {
            this.tip.index--;
        } else {
            this.tip.index = tips.length - 1;
        }
        this.tip.value = tips[this.tip.index];
    }
}
