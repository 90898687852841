import React from 'react';
import * as ReactDOM from 'react-dom';

export interface ICopier {
    copy(text: string): void;
}

export interface ICopierProps {
    text: string;
    className?: string;

    onCopy(): void;
}

export class Copier extends React.Component<ICopierProps> {
    public static defaultProps: ICopierProps = {
        text: '',
        onCopy: () => {},
    };
    private ref: HTMLTextAreaElement | null = null;

    public static create(props?: ICopierProps): ICopier {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        const copier = ReactDOM.render<ICopierProps>(
            <Copier {...props} />,
            containerElement
        ) as Copier;

        if (copier === null) {
            throw new Error('Unable to create Copier component');
        }
        return copier;
    }

    public copy(text: string) {
        if (this.ref && this.ref instanceof HTMLTextAreaElement) {
            this.ref.value = text;
            this.setState({ text });
            this.ref.select();
            document.execCommand('copy') && this.props.onCopy();
        }
    }

    render() {
        const { text } = this.props;
        return (
            <div className="copierComponent">
                <textarea
                    readOnly={true}
                    ref={(ref) => {
                        this.ref = ref;
                    }}
                    className="invisibleConsole"
                    defaultValue={text}
                />
            </div>
        );
    }
}
