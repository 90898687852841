import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import ResizeObserver from 'resize-observer-polyfill';

import './react-term.scss';
import { AppToaster } from '../../../service/toaster';
import classNames from 'classnames';

import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

import { ICluster } from '../../../types/cluster';
import { IconNames } from '@blueprintjs/icons';

export interface IReactTermProps {
    cluster?: ICluster;
    className?: string;
    text?: string;
    autoScroll?: boolean | string;
    fullScreen?: boolean | string;
}

export class ReactTerm extends React.Component<IReactTermProps> {
    private ref: HTMLDivElement | null = null;
    private taRef: HTMLTextAreaElement | null = null;
    private debounce = false;
    private readonly term: Terminal;
    private readonly fitAddon: FitAddon;
    private readonly ro: ResizeObserver;

    constructor(props: any) {
        super(props);
        this.term = new Terminal({
            convertEol: true,
            disableStdin: true,
            cursorStyle: 'underline',
            fontFamily:
                'source-code-pro, Menlo, Monaco, Consolas, \'Courier New\', monospace',
            fontSize: 12,
        });
        this.ro = new ResizeObserver(this.updateDimensions.bind(this));
        this.fitAddon = new FitAddon();
        this.term.loadAddon(this.fitAddon);
    }

    updateDimensions(entries: any) {
        this.fitAddon.fit();
    }

    copy() {
        if (this.taRef && this.taRef instanceof HTMLTextAreaElement) {
            this.taRef.select();
            document.execCommand('copy') &&
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    message: 'Copied!',
                    timeout: 1000,
                });
        }
    }

    toggleFullscreen() {
        if (!this.isFullScreenEnabled()) return;
        if (!this.ref) return;
        if (!(this.ref instanceof HTMLDivElement)) return;

        const container = this.ref.parentNode;
        if (container && container instanceof HTMLElement) {
            if (!document.fullscreenElement) {
                container
                    .requestFullscreen({
                        navigationUI: 'auto',
                    })
                    .then(() => {
                        this.updateFullScreenState();
                    });
                document.addEventListener('fullscreenchange', () => {
                    this.updateFullScreenState();
                });
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen().then(() => {
                        this.updateFullScreenState();
                    });
                }
            }
        }
    }

    updateFullScreenState() {
        if (this.ref) {
            const hasFullScreen =
                document.fullscreenEnabled && !!document.fullscreenElement;
            const hasScroll = this.ref.clientHeight < this.ref.scrollHeight;
            this.ref.classList.toggle('hasFullScreen', hasFullScreen);
            this.ref.classList.toggle('hasScroll', hasScroll);
            if (hasScroll && this.isAutoScroll()) {
                this.ref.scrollTop = this.ref.scrollHeight;
            }
        }
    }

    isAutoScroll() {
        return (
            this.props.autoScroll &&
            this.props.autoScroll !== 'false' &&
            this.props.autoScroll !== 'no'
        );
    }

    isFullScreenEnabled() {
        return (
            this.props.fullScreen &&
            this.props.fullScreen !== 'false' &&
            this.props.fullScreen !== 'no'
        );
    }

    componentWillUnmount(): void {
        this.term.dispose();
    }

    componentDidMount(): void {
        if (this.ref) {
            this.term.open(this.ref);
            this.term.clear();
            if (document.scrollingElement) {
                this.ro.observe(document.scrollingElement);
            }
            this.fitAddon.fit();
        }
        this.setComponentText();
    }

    setComponentText(): void {
        if (!this.props.text) return;
        if (this.debounce) return;

        console.log('test');
        this.debounce = true;
        this.term.clear();
        this.term.write(this.props.text.toString() + '\n', () => {
            this.fitAddon.fit();
            this.term.scrollToBottom();
            this.debounce = false;
        });
    }

    componentDidUpdate(): void {
        this.setComponentText();
    }

    render() {
        const { className, text } = this.props;
        return (
            <div className={classNames('reactTerminalComponent', className)}>
                <div
                    ref={(ref) => {
                        this.ref = ref;
                    }}
                />
                <textarea
                    readOnly={true}
                    ref={(taRef) => {
                        this.taRef = taRef;
                    }}
                    className="invisibleConsole"
                    value={text}
                />

                <div className={'consoleToolbar'}>
                    {this.isFullScreenEnabled() ? (
                        <Button
                            className={'fullscreenButton'}
                            onClick={() => this.toggleFullscreen()}
                            icon={IconNames.FULLSCREEN}
                        />
                    ) : undefined}
                    <Button
                        className={'copyButton'}
                        onClick={() => this.copy()}
                        icon={IconNames.CLIPBOARD}
                    >
                        Copy
                    </Button>
                </div>
            </div>
        );
    }
}
