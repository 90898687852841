import React, { Component } from 'react';
import { Classes, H1, Intent, Spinner } from '@blueprintjs/core';
import './manifest-view.scss';
import { ButtonLink } from '../../common/links/button-link';
import { localDate, localTime } from '../../../utils/formatters';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { IClusterProps } from '../../../types/serverparams';
import { ICluster } from '../../../types/cluster';
import { AppNavigator } from '../../../models/navigation';
import { ClustersModel } from '../../../models/clusters';

@observer
class ManifestView extends Component<IClusterProps> {
    @observable isMe = false;
    @observable isLoading = true;
    @observable isError = true;
    @observable cluster?: ICluster;

    cm: ClustersModel;

    constructor(props: any) {
        super(props);
        this.cm = new ClustersModel();
    }

    async load() {
        this.isLoading = true;
        this.cluster = await this.cm.getCluster(
            this.props.match.params.clusterId
        );
        this.isLoading = false;
    }

    async componentDidMount() {
        AppNavigator.clear();
        this.load();
    }

    componentWillUnmount(): void {
        AppNavigator.clear();
    }

    public render() {
        return (
            <div className="manifestViewComponent">
                {this.isLoading ? (
                    <Spinner intent={Intent.PRIMARY} />
                ) : (
                    <div>
                        <H1
                            className={classNames(Classes.HEADING, {
                                [Classes.SKELETON]: this.isLoading,
                            })}
                        >
                            &nbsp;
                        </H1>
                        {this.cluster ? (
                            <div>
                                <p>{this.cluster.email}</p>

                                <p>
                                    Created {localDate(this.cluster.createdAt)}{' '}
                                    at {localTime(this.cluster.createdAt)}
                                </p>
                                <p>
                                    Updated {localDate(this.cluster.createdAt)}{' '}
                                    at {localTime(this.cluster.createdAt)}
                                </p>
                                <ButtonLink
                                    to={'/users/'}
                                    intent={Intent.PRIMARY}
                                >
                                    Return
                                </ButtonLink>

                                {this.isMe ? (
                                    <div>
                                        <br />
                                        <br />
                                        <ButtonLink
                                            intent={Intent.SUCCESS}
                                            small={true}
                                            to={`/users/${this.cluster.id}/edit/`}
                                        >
                                            Edit
                                        </ButtonLink>
                                        <ButtonLink
                                            small={true}
                                            minimal={true}
                                            intent={Intent.WARNING}
                                            to={`/users/change-password/${this.cluster.id}`}
                                        >
                                            Change password
                                        </ButtonLink>
                                        <br />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export const ManifestViewComponent = withRouter<IClusterProps, any>(
    ManifestView
);
