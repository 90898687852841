import { Architecture, ICradleComponent } from '../types';
import { ICmChart } from '../types/chart';
import { HttpApi } from './http-api';
import { Storage } from './storage';

const {
    REACT_APP_API_COMPONENTS_LIST_URL,
    REACT_APP_API_CHARTMUSEUM_LIST_URL,
} = process.env;

export interface IChartValue {
    k: string;
    v: string;
}

export interface CtsFilterOptions {
    architecture?: Architecture;
    version?: string;
}

export interface ChartMuseumFilterOptions {
    company: string;
    department: string;
    environment: string;
}

export type IDLessCradleComponent = Omit<ICradleComponent, 'id'>;

export class ComponentTemplatesService {
    private headers(): Headers {
        const headers: any = { 'Content-Type': 'application/json' };
        const token = 'JohnJacobJingleHeimerSchmidt';
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        return headers;
    }

    private init(
        method = 'GET',
        body: string | undefined = undefined
    ): RequestInit {
        return {
            mode: 'cors',
            method,
            credentials: 'omit',
            body,
            headers: this.headers(),
        };
    }

    private async get(path: string) {
        return fetch(path, this.init())
            .then((r) => {
                if (r.ok) {
                    return r.json();
                }
                throw new Error(r.statusText);
            })
            .catch((e) => {
                console.log(e);
                throw e;
            });
    }

    private async put(path: string, data: any) {
        return fetch(path, this.init('PUT', JSON.stringify(data)))
            .then((r) => {
                if (r.ok) {
                    return r.json();
                }
                throw new Error(r.statusText);
            })
            .catch((e) => {
                console.log(e);
                throw e;
            });
    }

    async listCharts(
        options: ChartMuseumFilterOptions
    ): Promise<Map<string, ICmChart[]>> {
        const query = Object.entries(options)
            .map(
                (entry) =>
                    encodeURIComponent(entry[0]) +
                    '=' +
                    encodeURIComponent(entry[1])
            )
            .join('&');

        return HttpApi.get(
            (REACT_APP_API_CHARTMUSEUM_LIST_URL ||
                'https://store.cradle-dev.analoggarage.com/api/adi/garage/development/charts') +
                '?' +
                query
        );
    }

    async uploadChart(
        file: File,
        options: ChartMuseumFilterOptions
    ): Promise<any> {
        const url = await HttpApi.url(
            REACT_APP_API_CHARTMUSEUM_LIST_URL ||
                'https://store.cradle-dev.analoggarage.com/api/adi/garage/development/charts'
        );

        const token = Storage.get('token');
        const headers: Record<string, string> = {};
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        const fileReadPromise = new Promise((resolve, reject) => {
            reader.addEventListener('load', (event: ProgressEvent) => {
                console.log(event.target);
                if (!event.target) {
                    reject();
                    return;
                }
                if (event.target instanceof FileReader) {
                    resolve(event.target.result);
                } else {
                    reject();
                }
            });
            reader.addEventListener('error', reject);
        });

        const body = await fileReadPromise;

        return HttpApi.post(url, {
            ...options,
            chartBody: body,
            chartName: file.name,
        });
    }

    async listTemplates(
        options?: CtsFilterOptions
    ): Promise<{ items: IDLessCradleComponent[] }> {
        return this.get(
            REACT_APP_API_COMPONENTS_LIST_URL ||
                'http://localhost:8030/_config/components.json'
        );
    }

    async save(
        newComponent: ICradleComponent
    ): Promise<{ items: IDLessCradleComponent[] }> {
        return this.put(
            REACT_APP_API_COMPONENTS_LIST_URL ||
                'http://localhost:8030/_config/components.json',
            newComponent
        );
    }
}

// type ICmr = {string: any[]}
