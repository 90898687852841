import { action, observable } from 'mobx';
import { ClustersService } from '../service/clusters';
import {
    ActivationStatusEnum,
    ICluster,
    ProvisionStatusEnum,
    UIState,
} from '../types';
import { FormEvent } from 'react';
import { AppToaster, ErrorToast, SuccessToast } from '../service/toaster';
import { IAlertProps, Intent } from '@blueprintjs/core';
import { sanitizeDomain } from '../utils/formatters';

interface INewClusterDialog {
    cluster: ICluster;
    isValid: boolean;
    isOpen: boolean;
    isCreating: boolean;
    isHostnameTouched: boolean;
    isDisabled: boolean;
    isDescriptionOpen: boolean;
}

interface IConfirmClusterActionDialog extends IAlertProps {
    cluster: ICluster | undefined;
    isLoading: boolean;

    confirm(cluster: ICluster): void;
}

export class ClustersModel {
    @observable uiLoadState: UIState = UIState.Empty;

    @observable items: ICluster[] = [];

    @observable newClusterDialog: INewClusterDialog = {
        isValid: false,
        isOpen: false,
        isCreating: false,
        isHostnameTouched: false,
        isDescriptionOpen: false,
        isDisabled: true,
        cluster: {
            activeStatus: ActivationStatusEnum.Inactive,
            createDate: new Date(),
            createdBy: '',
            provisionStatus: ProvisionStatusEnum.Unprovisioned,
            updateDate: new Date(),
            updatedBy: '',
            accountId: '',
            comments: '',
            id: '',
            hostname: '',
            clusterName: '',
        },
    };

    @observable
    deleteClusterDialog: IConfirmClusterActionDialog = {
        cluster: undefined,
        isLoading: false,
        isOpen: false,
        confirm: (cluster: ICluster) => {
            this.deleteClusterDialog.cluster = cluster;
            this.deleteClusterDialog.isOpen = true;
        },
        onClose: () => {
            this.deleteClusterDialog.cluster = undefined;
            this.deleteClusterDialog.isOpen = false;
        },
        onConfirm: () => {
            const { cluster } = this.deleteClusterDialog;
            if (!cluster) return;
            this.uiLoadState = UIState.Loading;

            return ClustersService.deleteCluster(cluster.id)
                .then(() => {
                    cluster &&
                        SuccessToast(
                            `Cluster "${cluster.clusterName}" has been deleted.`
                        )(0);
                    this.deleteClusterDialog.isOpen = false;
                    this.deleteClusterDialog.cluster = undefined;
                    return this.getClusters();
                })
                .catch(
                    ErrorToast(
                        `Cluster "${cluster.clusterName}" removal has been failed!`
                    )
                )
                .finally(() => (this.uiLoadState = UIState.Loaded));
        },
    };

    @observable
    resetClusterDialog: IConfirmClusterActionDialog = {
        cluster: undefined,
        isLoading: false,
        isOpen: false,
        confirm: (cluster: ICluster) => {
            this.resetClusterDialog.cluster = cluster;
            this.resetClusterDialog.isOpen = true;
        },
        onCancel: () => {
            this.resetClusterDialog.isOpen = false;
            this.resetClusterDialog.cluster = undefined;
        },
        onConfirm: async () => {
            if (!this.resetClusterDialog.cluster) return;
            await this.reset(this.resetClusterDialog.cluster);
            this.resetClusterDialog.isOpen = false;
            this.resetClusterDialog.cluster = undefined;
        },
    };

    @action
    async getClusters() {
        this.uiLoadState = UIState.Loading;
        const { items } = await ClustersService.listClusters();
        if (Array.isArray(items)) {
            items.sort((a: ICluster, b: ICluster) => {
                if (clusterRanker(a) === clusterRanker(b)) {
                    return a.clusterName.localeCompare(b.clusterName);
                } else {
                    return clusterRanker(a) > clusterRanker(b) ? 10 : -10;
                }
            });
            this.items = items;
        }
        this.uiLoadState = UIState.Loaded;
    }

    @action
    async getCluster(clusterId: string) {
        await this.getClusters();
        return this.items.find((v: any) => v.id === clusterId);
    }

    @action
    validate() {
        this.newClusterDialog.isValid =
            this.newClusterDialog.cluster.clusterName.length > 0;
        this.newClusterDialog.isDisabled = this.newClusterDialog.isCreating;
    }

    @action
    async createCluster(event: FormEvent) {
        event.preventDefault();
        if (
            this.newClusterDialog.isDisabled ||
            this.newClusterDialog.isCreating
        )
            return;
        this.newClusterDialog.isDisabled = true;
        this.newClusterDialog.isCreating = true;

        const { clusterName, hostname, comments } =
            this.newClusterDialog.cluster;

        return ClustersService.registerCluster({
            clusterName,
            hostname,
            comments,
        })
            .then((r) => {
                if (r.clusterId) {
                    AppToaster.show({
                        message: `New cluster for "${clusterName}" has been created!`,
                        intent: Intent.SUCCESS,
                    });
                    this.newClusterDialog.isOpen = false;
                    this.newClusterDialog.cluster.clusterName = '';
                    this.newClusterDialog.cluster.hostname = '';
                    this.newClusterDialog.cluster.comments = '';
                    this.newClusterDialog.isCreating = false;
                    return r;
                } else {
                    AppToaster.show({
                        message:
                            'Sorry, we cannot create this cluster! ' +
                                r.error ||
                            r.message ||
                            '',
                        intent: Intent.DANGER,
                    });
                }
            })
            .catch(ErrorToast())
            .finally(() => {
                this.newClusterDialog.isDisabled = false;
                this.newClusterDialog.isCreating = false;
                this.newClusterDialog.isHostnameTouched = false;
            });
    }

    @action
    openCreateClusterDialog() {
        this.newClusterDialog.isOpen = true;
        this.validate();
    }

    @action
    dismissCreateClusterDialog() {
        this.newClusterDialog.isOpen = false;
        this.newClusterDialog.isHostnameTouched = false;
    }

    @action
    reset(cluster: ICluster) {
        this.uiLoadState = UIState.Loading;
        return ClustersService.resetCluster(cluster.id)
            .then(() => {
                cluster &&
                    SuccessToast(
                        `Cluster "${cluster.clusterName}" has been reset.`
                    )(0);
                return this.getClusters();
            })
            .catch(
                ErrorToast(
                    `Cluster "${cluster.clusterName}" reset has been failed!`
                )
            )
            .finally(() => (this.uiLoadState = UIState.Loaded));
    }

    @action
    newClusterFieldChanged(event: FormEvent<HTMLInputElement>) {
        this.newClusterDialog.cluster[event.currentTarget.name] =
            event.currentTarget.value;
        switch (event.currentTarget.name) {
            case 'clusterName':
                if (!this.newClusterDialog.isHostnameTouched) {
                    this.newClusterDialog.cluster.hostname = sanitizeDomain(
                        event.currentTarget.value
                    );
                }
                break;
            case 'hostname':
                this.newClusterDialog.isHostnameTouched =
                    !!event.currentTarget.value.length;
                break;
        }
        this.validate();
    }

    @action
    async updateCluster(cluster: ICluster) {
        return ClustersService.updateCluster(cluster);
    }

    @action
    toggleCreateClusterDescription() {
        this.newClusterDialog.isDescriptionOpen =
            !this.newClusterDialog.isDescriptionOpen;
    }
}

function clusterRanker(cluster: ICluster) {
    return (
        activationStatusRank(cluster.activeStatus) +
        provisionStatusRank(cluster.provisionStatus)
    );
}

function activationStatusRank(activationStatus: ActivationStatusEnum): number {
    return {
        [ActivationStatusEnum.Active]: 100,
        [ActivationStatusEnum.Inactive]: 200,
    }[activationStatus];
}

function provisionStatusRank(provisionStatus: ProvisionStatusEnum) {
    return {
        [ProvisionStatusEnum.Provisioning]: 10,
        [ProvisionStatusEnum.ProvisionFailed]: 20,
        [ProvisionStatusEnum.DestroyFailed]: 30,
        [ProvisionStatusEnum.Destroying]: 40,
        [ProvisionStatusEnum.Provisioned]: 70,
        [ProvisionStatusEnum.Unprovisioned]: 90,
    }[provisionStatus];
}
