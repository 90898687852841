import React, { Component } from 'react';

import './terminal.scss';
import { observer } from 'mobx-react';
import { H1 } from '@blueprintjs/core';
import { ButtonLink } from '../../common/links/button-link';
import { withRouter } from 'react-router';
import { IClusterProps } from '../../../types/serverparams';
import { ICluster } from '../../../types/cluster';
import { observable } from 'mobx';
import { UIState } from '../../../types/uistate';
import { StatefulComponent } from '../../common/stateful/stateful';
import { ReactTerm } from '../../common/react-term/react-term';
import { ClustersModel } from '../../../models/clusters';

@observer
class CmsTerminal extends Component<IClusterProps> {
    @observable cluster?: ICluster;
    cm: ClustersModel;
    @observable uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);
        this.cm = new ClustersModel();
    }

    async load() {
        this.uiLoadState = UIState.Loading;
        try {
            this.cluster = await this.cm.getCluster(
                this.props.match.params.clusterId
            );
            this.uiLoadState = UIState.Loaded;
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    componentDidMount(): void {
        this.load().then(() => {
            if (this.cluster && this.cluster.hostname) {
                window.location.href =
                    'http://' + this.cluster.hostname + '/shell/';
            }
        });
    }

    render() {
        return (
            <div>
                <StatefulComponent state={this.uiLoadState}>
                    {this.cluster ? (
                        <div>
                            <H1>
                                Shell into <kbd>{this.cluster.clusterName}</kbd>
                            </H1>

                            <p>Ssh component goes here</p>

                            <ReactTerm cluster={this.cluster} />
                        </div>
                    ) : (
                        ''
                    )}
                </StatefulComponent>

                <ButtonLink to={'/clusters/'}>Return</ButtonLink>
            </div>
        );
    }
}

export const TerminalComponent = withRouter<IClusterProps, any>(CmsTerminal);
