import React, { Component } from 'react';

import './clusters-list/clusters-list.scss';
import { observer } from 'mobx-react';
import { IClusterProps } from '../../types/serverparams';
import { withRouter } from 'react-router';
import { ClustersService } from '../../service/clusters';
import { AppToaster, ErrorToast } from '../../service/toaster';
import { Intent } from '@blueprintjs/core';

@observer
class Poke extends Component<IClusterProps> {

    componentDidMount(): void {
        ClustersService.pokeCluster(this.props.match.params.clusterId)
            .then((r) => {
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    message: 'Server has been told to apply changes.',
                });
            })
            .catch(ErrorToast())
            .finally(() => {
                this.props.history.replace({ pathname: '/clusters/' });
            });
    }

    render() {
        return (
            <div>
                <h1>Poking...</h1>
            </div>
        );
    }
}

export const PokeComponent = withRouter<IClusterProps, any>(Poke);
