import { DiagramWidget } from '@projectstorm/react-diagrams';

export class PipelineDiagramWidget extends DiagramWidget {
    /**
     * Custom handler to avoid conlfict with delete key in the other widgets
     * @param e
     */
    onKeyUp(e: any): void {
        this.props.deleteKeys &&
            -1 !== this.props.deleteKeys.indexOf(e.keyCode) &&
            this.props.diagramEngine
                .getDiagramModel()
                .getSelectedItems('link', 'point')
                .forEach((n) => {
                    !n.isLocked() && n.remove();
                });
        // this.forceUpdate();
    }
}
