import { IaaSProvider } from './iaas-provider';

export interface ICluster {
    id: string;
    clusterName: string;
    hostname: string;
    comments: string;
    knoxProject?: string;
    registeredBy?: string;
    accountId?: string;
    isUpdating?: boolean;
    activeStatus: ActivationStatusEnum;
    provisionStatus: ProvisionStatusEnum;
    infrastructureProvider?: IaaSProvider;
    createDate: Date;
    updateDate: Date;
    createdBy: string;
    updatedBy: string;

    [id: string]: any;
}

export enum ActivationStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'NOT_ACTIVE',
}

export enum ProvisionStatusEnum {
    Unprovisioned = 'NOT_PROVISIONED',
    Provisioning = 'PROVISIONING',
    Provisioned = 'PROVISIONED',
    ProvisionFailed = 'PROVISION_FAILED',
    Destroying = 'DESTROYING_PROVISIONED',
    DestroyFailed = 'DESTROY_FAILED',
}

export interface IClusterApiResponse {
    items: ICluster[];
}

export interface IClusterProvisionApiResponse {
    runId?: string;
    status: string;
    message: string;
    cradleUpToken: string;
}

export interface IClusterPrepareDestroyApiResponse {
    runId?: string;
    status: string;
    message: string;
    cradleDownToken: string;
}

export interface IClusterDestroyApiResponse {
    runId?: string;
    status: string;
    message: string;
}

export interface IClusterDeleteApiResponse {
    runId?: string;
    status: string;
    message: string;
}

export interface IClusterProvisionLogsApiResponse {
    id?: string;
    contentType?: string;
    data?: string;
}
