import React, { Component } from 'react';
import { Classes, H1, Intent, Spinner } from '@blueprintjs/core';
import './view.scss';
import { ButtonLink } from '../../common/links/button-link';
import { localDate, localTime } from '../../../utils/formatters';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import classNames from 'classnames';
import { UserModel } from '../../../models/user-state';
import { AppNavigator } from '../../../models/navigation';

interface IUserProps extends RouteComponentProps<any> {
    userId: string;
}

@observer
class UsersView extends Component<IUserProps> {
    @observable isMe = false;
    @observable isLoading = true;

    async load() {
        this.isLoading = true;
        await UserModel.init();
        this.isLoading = false;
        this.isMe = false;
    }

    async componentDidMount() {
        AppNavigator.clear();

        this.load();
    }

    public render() {
        const { user } = UserModel;
        return (
            <div>
                {this.isLoading ? (
                    <Spinner intent={Intent.PRIMARY} />
                ) : (
                    <div>
                        {user ? (
                            <div>
                                <H1
                                    className={classNames(Classes.HEADING, {
                                        [Classes.SKELETON]: this.isLoading,
                                    })}
                                >
                                    {user.firstName}
                                    &nbsp;
                                    {user.lastName}
                                </H1>

                                <p>{user.email}</p>

                                <p>
                                    Created {localDate(user.createdAt)} at{' '}
                                    {localTime(user.createdAt)}
                                </p>
                                <p>
                                    Updated {localDate(user.createdAt)} at{' '}
                                    {localTime(user.createdAt)}
                                </p>
                                <ButtonLink to={'/'} intent={Intent.PRIMARY}>
                                    Return
                                </ButtonLink>

                                {this.isMe ? (
                                    <div>
                                        <br />
                                        <br />
                                        <ButtonLink
                                            intent={Intent.SUCCESS}
                                            small={true}
                                            to={`/users/${user.id}/edit/`}
                                        >
                                            Edit
                                        </ButtonLink>
                                        <ButtonLink
                                            small={true}
                                            minimal={true}
                                            intent={Intent.WARNING}
                                            to={`/users/change-password/${user.id}`}
                                        >
                                            Change password
                                        </ButtonLink>
                                        <br />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export const UsersViewComponent = withRouter<IUserProps, any>(UsersView);
