import { DiagramModel } from '@projectstorm/react-diagrams';
import { ChartNodeModel } from './chart-node-model';

export class PipelineDiagramModel extends DiagramModel {
    public setSelectedNode(name: string) {
        console.log('setSelectedNode', name, this.nodes);
        this.clearSelection();
        for (let key in this.nodes) {
            console.log('this.nodes', key, this.nodes[key]);
            if (this.nodes[key] instanceof ChartNodeModel) {
                if ((this.nodes[key] as ChartNodeModel).getName() === name) {
                    this.nodes[key].setSelected(true);
                    console.log('setSelectedNode', key);
                }
            }
        }
    }
}
