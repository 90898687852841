import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import './console-example.scss';
import { AppToaster } from '../../../service/toaster';
import classNames from 'classnames';

export interface IConsoleExampleProps {
    text: string;
    className?: string;
    autoScroll?: boolean | string;
    fullScreen?: boolean | string;
}

export class ConsoleExample extends React.Component<IConsoleExampleProps> {
    private ref: HTMLTextAreaElement | null = null;

    copy() {
        if (this.ref && this.ref instanceof HTMLTextAreaElement) {
            this.ref.select();
            document.execCommand('copy') &&
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    message: 'Copied!',
                    timeout: 1000,
                });
        }
    }

    toggleFullscreen() {
        if (!this.isFullScreenEnabled()) return;

        if (this.ref && this.ref instanceof HTMLTextAreaElement) {
            const container = this.ref.parentNode;
            if (container && container instanceof HTMLElement) {
                if (!document.fullscreenElement) {
                    container
                        .requestFullscreen({
                            navigationUI: 'auto',
                        })
                        .then(() => {
                            this.updateFullScreenState();
                        });
                    document.addEventListener('fullscreenchange', () => {
                        this.updateFullScreenState();
                    });
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen().then(() => {
                            this.updateFullScreenState();
                        });
                    }
                }
            }
        }
    }

    updateFullScreenState() {
        if (this.ref) {
            const hasFullScreen =
                document.fullscreenEnabled && !!document.fullscreenElement;
            const hasScroll = this.ref.clientHeight < this.ref.scrollHeight;
            this.ref.classList.toggle('hasFullScreen', hasFullScreen);
            this.ref.classList.toggle('hasScroll', hasScroll);
            if (hasScroll && this.isAutoScroll()) {
                this.ref.scrollTop = this.ref.scrollHeight;
            }
        }
    }

    isAutoScroll() {
        return (
            this.props.autoScroll &&
            this.props.autoScroll !== 'false' &&
            this.props.autoScroll !== 'no'
        );
    }

    isFullScreenEnabled() {
        return (
            this.props.fullScreen &&
            this.props.fullScreen !== 'false' &&
            this.props.fullScreen !== 'no'
        );
    }

    componentDidUpdate(): void {
        if (this.ref) {
            this.updateFullScreenState();
        }
    }

    render() {
        const { text, className } = this.props;
        return (
            <div className={classNames('consoleExampleComponent', className)}>
                <textarea
                    readOnly={true}
                    ref={(ref) => {
                        this.ref = ref;
                    }}
                    rows={text.split('\n').length}
                    className="console"
                    defaultValue={text}
                />
                <div className={'consoleToolbar'}>
                    {this.isFullScreenEnabled() ? (
                        <Button
                            className={'fullscreenButton'}
                            onClick={() => this.toggleFullscreen()}
                            icon={IconNames.FULLSCREEN}
                        />
                    ) : undefined}
                    <Button
                        className={'copyButton'}
                        onClick={() => this.copy()}
                        icon={IconNames.CLIPBOARD}
                    >
                        Copy
                    </Button>
                </div>
            </div>
        );
    }
}
