import { DefaultNodeModel } from '@projectstorm/react-diagrams';

export class ChartNodeModel extends DefaultNodeModel {
    constructor(name?: string, color?: string) {
        super(name, color);
        this.type = 'chart';
    }

    public getName(): string {
        return this.name;
    }
}
