import { HttpApi } from './http-api';
import { sp } from '../utils/formatters';
import { cache } from '../utils/cache';
import { IACLRecord } from '../models/acl-model';
import { IUser } from '../models/users';

interface IACLListApiResponse {
    items: IACLRecord[];
}

interface IAclApiResponse extends IACLRecord {}

const {
    REACT_APP_API_CLUSTER_ACLS,
    REACT_APP_API_CLUSTER_ACL,
    REACT_APP_API_USERS_SEARCH,
} = process.env;

export class ACLService {
    static async listAcls(clusterId: string): Promise<IACLListApiResponse> {
        return HttpApi.get(
            sp(REACT_APP_API_CLUSTER_ACLS || 'clusters/%s/acl/', clusterId)
        );
    }

    static async getAcl(
        clusterId: string,
        id: string
    ): Promise<IAclApiResponse> {
        return HttpApi.get(
            sp(
                REACT_APP_API_CLUSTER_ACL || 'clusters/%s/acl/%s/',
                clusterId,
                id
            )
        );
    }

    static async createAcl(
        clusterId: string,
        data: IACLRecord
    ): Promise<IAclApiResponse> {
        // please, don't ask me why put has been used here
        return HttpApi.put(
            sp(REACT_APP_API_CLUSTER_ACLS || 'clusters/%s/acl/', clusterId),
            mapAclRecordToPayload(data)
        );
    }

    static async updateAcl(
        clusterId: string,
        data: IACLRecord
    ): Promise<IAclApiResponse> {
        return HttpApi.post(
            sp(REACT_APP_API_CLUSTER_ACLS || 'clusters/%s/acl/', clusterId),
            mapAclRecordToPayload(data)
        );
    }

    static async deleteAcl(clusterId: string, id: string): Promise<any> {
        return HttpApi.delete(
            sp(
                REACT_APP_API_CLUSTER_ACL || 'clusters/%s/acl/?userId=%s',
                clusterId,
                id
            )
        );
    }

    @cache(60)
    static async searchUsers(query: string): Promise<{ items: IUser[] }> {
        return HttpApi.get(
            sp(REACT_APP_API_USERS_SEARCH || 'users/?query=%s', query)
        );
    }
}

function mapAclRecordToPayload(data: IACLRecord) {
    const { config, manifest, own, user } = data;
    if (!user || !user.email) throw Error('Please, specify user email!');
    return {
        config,
        manifest,
        own,
        targetUserId: user.id,
        userId: user.id,
        audit: {
            userId: user.id,
        },
        version: data.__version || Math.round(Date.now() / 1000).toString(36),
    };
}
