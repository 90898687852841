import React, { Component } from 'react';

import './manifest-edit.scss';
import { observer } from 'mobx-react';
import { Button, Intent, TabId } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ICluster, IClusterProps, IManifest, UIState } from '../../../../types';
import { withRouter } from 'react-router';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/ext/language_tools';
import 'brace/ext/linking';
import 'brace/snippets/json';
import { observable } from 'mobx';
import { StatefulComponent } from '../../../common/stateful/stateful';
import { AppNavigator } from '../../../../models/navigation';
import { ManifestService } from '../../../../service/manifest';
import { ErrorToast, SuccessToast } from '../../../../service/toaster';
import { ClustersModel } from '../../../../models/clusters';
import { defaultManifest } from '../default-manifest';

export interface IManifestEditState {
    navbarTabId: TabId;
}

@observer
class ManifestEdit extends Component<IClusterProps, IManifestEditState> {
    public state: IManifestEditState = {
        navbarTabId: 't0',
    };

    @observable cluster?: ICluster;
    @observable manifest?: IManifest;
    @observable serverJson: string = '';
    @observable isSaving = false;
    @observable isValid = false;

    cm: ClustersModel;
    @observable uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);
        this.cm = new ClustersModel();
    }

    async load() {
        this.uiLoadState = UIState.Loading;
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');
        this.uiLoadState = UIState.Loading;
        try {
            this.cluster = await this.cm.getCluster(
                this.props.match.params.clusterId
            );

            if (this.cluster) {
                AppNavigator.add(
                    `/clusters/${this.cluster.id}/`,
                    this.cluster.clusterName
                );
                AppNavigator.add(
                    `/clusters/${this.cluster.id}/manifest/edit/`,
                    'Edit Manifest'
                );

                const manifest = await ManifestService.get(this.cluster.id);

                this.manifest = {
                    ...defaultManifest,
                    ...manifest,
                };
            }

            this.uiLoadState = UIState.Loaded;
            this.forceUpdate();
            this.serverJson = JSON.stringify(this.manifest, null, '  ');
        } catch (e) {
            console.log('different error', e);
            this.uiLoadState = UIState.Failed;
        }
    }

    componentDidMount(): void {
        AppNavigator.clear();
        this.load().then();
    }

    onChange(newValue: any) {
        console.log('change', newValue);
        this.serverJson = newValue;
        try {
            this.manifest = JSON.parse(this.serverJson);
            this.isValid = true;
        } catch (e) {
            console.log(e);
            this.isValid = false;
        }
    }

    saveManifest() {
        if (this.isSaving || !this.manifest || !this.cluster || !this.isValid)
            return;
        this.isSaving = true;
        ManifestService.update(this.cluster.id, this.manifest)
            .then(SuccessToast('Manifest has been saved!'))
            .catch(ErrorToast('Unable to save manifest.'))
            .finally(() => {
                this.isSaving = false;
            });
    }

    public render() {
        return (
            <div className={'manifestEditComponent'}>
                <StatefulComponent state={this.uiLoadState}>
                    {this.cluster && (
                        <div className={'clusterView'}>
                            <div className={'header'}>
                                <Button
                                    disabled={this.isSaving || !this.isValid}
                                    loading={this.isSaving}
                                    icon={IconNames.FLOPPY_DISK}
                                    intent={Intent.SUCCESS}
                                    onClick={() => {
                                        this.saveManifest();
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            <AceEditor
                                mode={'json'}
                                theme={'textmate'}
                                height={'calc(100vh - 50px)'}
                                width={'100%'}
                                value={this.serverJson}
                                onChange={this.onChange.bind(this)}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: true,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }}
                            />
                        </div>
                    )}
                </StatefulComponent>
            </div>
        );
    }
}

export const ManifestEditComponent = withRouter<IClusterProps, any>(
    ManifestEdit
);
