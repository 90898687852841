import { ucFirst } from '../../../../utils/formatters';

export function isEmptyExchangeName(name: string): boolean {
    if (name.length === 0) return true;
    return ['dummy', 'false'].includes(name.toLowerCase());
}

export function extractNameChain(name: string): string {
    const blacklist = ['agc', 'bls', 'pipeline', 'sink', 'component', 'chart'];
    return name
        .split(/\W+/)
        .filter(
            (word: string) => !blacklist.includes(word.toLowerCase()) && word
        )
        .map(ucFirst)
        .join('');
}
