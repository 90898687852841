import { IAppConfig } from '../types/app-config';
import { UserModel } from '../models/user-state';

export const ConfigService = {
    config: {} as IAppConfig,
    initialized: false,
    isFetching: false,
    ticks: 0,
    init: async () => {
        if (!ConfigService.initialized && !ConfigService.isFetching) {
            ConfigService.isFetching = true;
            const { API_DOMAIN } = process.env;
            ConfigService.config = {
                apiBaseUrl: API_DOMAIN,
            };

            await UserModel.init();

            ConfigService.isFetching = false;
            ConfigService.initialized = true;
        }
        return new Promise((resolve, reject) => {
            const int = setInterval(() => {
                if (ConfigService.initialized) {
                    clearInterval(int);
                    resolve();
                } else {
                    ConfigService.ticks++;
                    if (ConfigService.ticks > 1000) {
                        clearInterval(int);
                        reject();
                    }
                }
            }, 40);
        });
    },
};
ConfigService.init().then();
