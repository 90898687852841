import React, { Component, FormEvent } from 'react';
import '@projectstorm/react-diagrams/dist/style.min.css';
import './manifest-designer.scss';
import dagre from 'dagre';
import { observer } from 'mobx-react';
import {
    AnchorButton,
    Button,
    ButtonGroup,
    ControlGroup,
    Divider,
    HTMLSelect,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Overlay,
    Popover,
    PopoverPosition,
    Spinner,
    Tooltip,
} from '@blueprintjs/core';
import {
    IClusterProps,
    ICradleComponent,
    IManifestComponent,
    UIState,
} from '../../../../types';
import { withRouter } from 'react-router';
import { observable } from 'mobx';
import { StatefulComponent } from '../../../common/stateful/stateful';
import {
    BaseEvent,
    BaseModel,
    DefaultLinkModel,
    DefaultPortModel,
    DiagramEngine,
    LinkModel,
} from '@projectstorm/react-diagrams';
import { CradleComponents } from '../../../../models/cradle-components';
import { IconNames } from '@blueprintjs/icons';
import { getComponentColor } from './chart/chart-parameter';
import { ChartNodeModel } from './chart/chart-node-model';
import { PipelineDiagramModel } from './chart/pipeline-diagram-model';
import { PipelineDiagramWidget } from './chart/pipeline-diagram-widget';
import { AppNavigator } from '../../../../models/navigation';
import { ClustersModel } from '../../../../models/clusters';
import { IManifestEditState } from './manifest-edit-state';
import { getComponentOption, setComponentOption } from './component-options';
import { ComponentWizard } from './component-wizard';
import { ComponentPanel } from './component-panel';
import { ManifestModel } from './manifest-model';
import { extractNameChain, isEmptyExchangeName } from './utils';
import { InspectorPanel } from './inspector-panel';
import { SolutionsSearch } from './solutions-search';
import { ChartNodeFactory } from './chart/chart-node-factory';
import { AppToaster } from '../../../../service/toaster';

const CHART_NODE_WIDTH = 200;
const CHART_NODE_HEIGHT = 80;

@observer
class ManifestDesigner extends Component<IClusterProps, IManifestEditState> {
    engine: DiagramEngine;
    clustersModel: ClustersModel;
    diagramModel: PipelineDiagramModel;
    componentsModel: CradleComponents;
    manifestModel: ManifestModel;

    public state: IManifestEditState = {
        navbarTabId: 't0',
    };

    @observable popups = {
        addComponent: false,
        parameterEditor: false,
        loadOverlay: false,
        solutionSearch: false,
    };

    @observable panels = {
        isOpen: true,
    };

    @observable
    uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);

        this.addComponent = this.addComponent.bind(this);
        this.renderGraph = this.renderGraph.bind(this);
        this.solutionSelected = this.solutionSelected.bind(this);
        this.repaint = this.repaint.bind(this);
        this.addComponent = this.addComponent.bind(this);

        this.engine = new DiagramEngine();
        this.clustersModel = new ClustersModel();
        this.engine.installDefaultFactories();
        const chartNodeFactoryInstance = new ChartNodeFactory();
        this.engine.registerNodeFactory(chartNodeFactoryInstance);

        this.diagramModel = new PipelineDiagramModel();
        this.componentsModel = new CradleComponents();
        this.manifestModel = new ManifestModel();

        this.manifestModel.activeManifestComponent = {
            chartName: '',
            chartVersion: '',
            containerRepos: [],
            namespace: '',
            releaseName: '',
            serviceServers: [],
            setFileOptions: [],
            setOptions: [],
        };
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');
        this.uiLoadState = UIState.Loading;
        try {
            const cluster = await this.clustersModel.getCluster(
                this.props.match.params.clusterId
            );

            if (cluster) {
                AppNavigator.add(
                    `/clusters/${cluster.id}/`,
                    cluster.clusterName
                );
                AppNavigator.add(
                    `/clusters/${cluster.id}/manifest/designer/`,
                    'Cradle Design Studio'
                );
                this.manifestModel.setCluster(cluster);
                await this.manifestModel.loadManifest(cluster);
            }

            await this.componentsModel.load();
            this.uiLoadState = UIState.Loaded;
        } catch (e) {
            console.log('different error', e);
            this.uiLoadState = UIState.Failed;
        }
    }

    findComponentByName(name: string): IManifestComponent | undefined {
        if (!this.manifestModel.manifest) return;
        return this.manifestModel.manifest.components.find((comp) => {
            return comp.releaseName === name;
        });
    }

    componentDidMount() {
        this.load().then(() => {
            this.renderGraph();
        });

        this.manifestModel.registerUnloadHook(window);
    }

    componentWillUnmount(): void {
        this.manifestModel.confirmChanges();
    }

    getParameterDescription(component: ICradleComponent, name: string) {
        return component.values.find((value) => value.name === name);
    }

    async switchToManifestEditor() {
        if (!this.manifestModel.cluster) return;
        await this.saveManifest(
            `/clusters/${this.manifestModel.cluster.id}/manifest/edit/`
        );
    }

    async saveManifest(redirectAfter?: string) {
        this.popups.loadOverlay = true;
        await this.manifestModel.saveManifest();
        if (redirectAfter) {
            this.go(redirectAfter);
            return;
        }
        this.popups.loadOverlay = false;
    }

    async saveManifestAndExit() {
        await this.saveManifest('/clusters/');
    }

    go(pathname: string) {
        this.props.history.push(pathname);
    }

    repaint() {
        this.engine.repaintCanvas();
    }

    public render() {
        return (
            <div className="manifestDesignerComponent">
                <StatefulComponent state={this.uiLoadState}>
                    {this.manifestModel.cluster ? (
                        <div className="paneContainer">
                            <ComponentPanel
                                cpm={this.componentsModel}
                                cm={this.clustersModel}
                                addComponent={this.addComponent}
                                newComponent={() =>
                                    (this.popups.addComponent = true)
                                }
                                isOpen={this.panels.isOpen}
                            />
                            <div className="designerPane">
                                {this.renderToolbar()}
                                <div
                                    onDragOver={(event) =>
                                        event.preventDefault()
                                    }
                                    onDrop={(event) => {
                                        try {
                                            const data =
                                                event.dataTransfer.getData(
                                                    'application/json'
                                                );
                                            if (!data) return;
                                            const c: ICradleComponent =
                                                JSON.parse(data);
                                            c && this.addComponent(c);
                                        } catch (e) {
                                            console.log(e);
                                        }
                                    }}
                                >
                                    <PipelineDiagramWidget
                                        allowCanvasZoom={true}
                                        allowLooseLinks={true}
                                        diagramEngine={this.engine}
                                    />
                                </div>
                            </div>
                            <InspectorPanel
                                models={{
                                    diagramModel: this.diagramModel,
                                    clustersModel: this.clustersModel,
                                    componentsModel: this.componentsModel,
                                    manifestModel: this.manifestModel,
                                }}
                                isOpen={this.panels.isOpen}
                                repaint={this.repaint}
                                renderGraph={this.renderGraph}
                            />
                        </div>
                    ) : undefined}
                    <ComponentWizard
                        onSave={this.addComponent}
                        cradleComponentsModel={this.componentsModel}
                        clustersModel={this.clustersModel}
                        isOpen={this.popups.addComponent}
                        onClose={() => {
                            this.popups.addComponent = false;
                        }}
                    />
                    <SolutionsSearch
                        isOpen={this.popups.solutionSearch}
                        onClose={this.solutionSelected}
                    />
                    <div>
                        <Overlay isOpen={this.popups.loadOverlay}>
                            <div className="chartLoaderOverlay">
                                <Spinner />
                            </div>
                        </Overlay>
                    </div>
                </StatefulComponent>
            </div>
        );
    }

    solutionSelected(components: string[]) {
        this.popups.solutionSearch = false;
        this.manifestModel.manifest.components = [];
        components &&
            components.forEach((c) => {
                const fc = this.componentsModel.findById(c);
                fc && this.addComponent(fc);
            });
    }

    renderGraph() {
        if (!this.manifestModel.cluster) return;

        const { manifest } = this.manifestModel;
        if (!manifest) return;

        this.diagramModel = new PipelineDiagramModel();

        const linksUpdated = (
            event: BaseEvent<PipelineDiagramModel> & {
                link: DefaultLinkModel;
                isCreated: boolean;
            }
        ): void => {
            console.log('linksUpdated', event);

            const { link, isCreated } = event;
            if (isCreated) {
                const sourcePortChanged = (e: any) => {
                    console.log('sourcePortChanged', e);
                };
                const targetPortChanged = (
                    event: BaseEvent<PipelineDiagramModel> & {
                        port: DefaultPortModel;
                    }
                ) => {
                    console.log('targetPortChanged', event);

                    const { sourcePort, targetPort } =
                        event.entity as LinkModel;
                    console.log(
                        'sourcePort, targetPort, isCreated',
                        sourcePort,
                        targetPort,
                        isCreated
                    );

                    if (sourcePort && targetPort) {
                        const isEmptySource = isEmptyExchangeName(
                            sourcePort.clone().label
                        );
                        const isEmptyTarget = isEmptyExchangeName(
                            targetPort.clone().label
                        );

                        const sourceComponent = this.findComponentByName(
                            sourcePort.clone().parent.name
                        )!;
                        const targetComponent = this.findComponentByName(
                            targetPort.clone().parent.name
                        )!;
                        console.log(
                            'empty',
                            isEmptySource,
                            isEmptyTarget,
                            sourceComponent,
                            targetComponent
                        );

                        if (isEmptySource && isEmptyTarget) {
                            // find common denominator
                            const exchangeName =
                                extractNameChain(
                                    sourcePort.clone().parent.name
                                ) + 'FO';
                            setComponentOption(
                                sourceComponent,
                                'output.exchange.name',
                                exchangeName
                            );
                            setComponentOption(
                                sourceComponent,
                                'input.exchange.name',
                                exchangeName
                            );
                        } else if (isEmptySource && !isEmptyTarget) {
                            // copy from target
                            const exchangeName =
                                getComponentOption(
                                    targetComponent,
                                    'input.exchange.name'
                                ) || '';
                            setComponentOption(
                                sourceComponent,
                                'output.exchange.name',
                                exchangeName
                            );
                        } else if (!isEmptySource && isEmptyTarget) {
                            // copy from target
                            const exchangeName =
                                getComponentOption(
                                    sourceComponent,
                                    'output.exchange.name'
                                ) || '';
                            setComponentOption(
                                targetComponent,
                                'input.exchange.name',
                                exchangeName
                            );
                        } else {
                            // both not empty
                            const targetExchangeName =
                                getComponentOption(
                                    targetComponent,
                                    'input.exchange.name'
                                ) || '';
                            const sourceExchangeName =
                                getComponentOption(
                                    sourceComponent,
                                    'output.exchange.name'
                                ) || '';
                            if (targetExchangeName !== sourceExchangeName) {
                                console.log(
                                    targetExchangeName,
                                    sourceExchangeName
                                );
                            }
                        }
                        setTimeout(() => {
                            this.renderGraph();
                        }, 100);
                    }
                };
                link.setColor('green');
                link.addListener({ sourcePortChanged, targetPortChanged });
            }
        };

        this.diagramModel.addListener({ linksUpdated });

        this.diagramModel.setGridSize(10);
        this.diagramModel.setOffsetY(10);

        this.engine.setDiagramModel(this.diagramModel);
        const nmodels: ChartNodeModel[] = [];
        let g = new dagre.graphlib.Graph({
            directed: false,
            multigraph: true,
            compound: true,
        });
        g.setGraph({
            rankdir: 'LR',
            align: 'UL',
            nodesep: 2,
            edgesep: 10,
            ranksep: 200,
            marginy: 1,
            height: 5,
        });
        g.setDefaultEdgeLabel(() => ({}));
        manifest.components.forEach((c) => {
            const node1 = new ChartNodeModel(
                c.releaseName,
                getComponentColor(c.chartName)
            );
            const ien = c.setOptions.find(
                (o) => o.key === 'input.exchange.name'
            );
            if (ien) {
                const inPortName = (ien && ien.value) || 'In';
                const inPort = node1.addInPort(inPortName);
                this.diagramModel.addAll(inPort);
            }
            const oen = c.setOptions.find(
                (o) => o.key === 'output.exchange.name'
            );
            if (oen) {
                const outPort = node1.addOutPort((oen && oen.value) || 'Out');
                outPort.addListener({});
                this.diagramModel.addAll(outPort);
            }
            const selectionChanged = (
                event: BaseEvent<BaseModel> & { isSelected: boolean }
            ): void => {
                console.log('selectionChanged', event);
                if (event.isSelected) {
                    if (!manifest) return;

                    manifest.components.forEach((v, i) => {
                        const name = (event.entity as ChartNodeModel).getName();
                        if (v.releaseName === name) {
                            if (!manifest) return;
                            this.manifestModel.activeManifestComponent =
                                manifest.components[i];
                        }
                    });
                }
            };
            node1.addListener({
                selectionChanged,
            });
            nmodels.push(node1);

            this.diagramModel.addNode(node1);
        });
        let connected: string[] = [];
        nmodels.forEach((nm1) => {
            // console.log('nm1', nm1)
            nm1.y = 1;
            nm1.getOutPorts().forEach((op) => {
                nmodels.forEach((nm2) => {
                    nm2.getInPorts().forEach((ip) => {
                        if (op.label === ip.label) {
                            const link = new DefaultLinkModel();
                            link.setColor('green');
                            link.setSourcePort(op);
                            link.setTargetPort(ip);
                            link.addLabel(op.label);
                            if (!connected.includes(nm1.name)) {
                                connected.push(nm1.name);
                                g.setNode(nm1.name, {
                                    label: nm1.name,
                                    width: CHART_NODE_WIDTH,
                                    height: CHART_NODE_HEIGHT,
                                });
                            }
                            if (!connected.includes(nm2.name)) {
                                connected.push(nm2.name);
                                g.setNode(nm2.name, {
                                    label: nm2.name,
                                    width: CHART_NODE_WIDTH,
                                    height: CHART_NODE_HEIGHT,
                                });
                            }
                            g.setEdge(nm1.name, nm2.name);
                            this.diagramModel.addLink(link);
                        }
                    });
                });
            });
        });
        nmodels.forEach((nm) => {
            if (!connected.includes(nm.name)) {
                connected.push(nm.name);
                g.setNode(nm.name, {
                    label: nm.name,
                    width: CHART_NODE_WIDTH,
                    height: CHART_NODE_HEIGHT,
                });
            }
        });
        try {
            dagre.layout(g, {
                rankdir: 'TB',
                ranker: 'tight-tree',
                height: 10,
            });
            g.nodes().forEach(function (v) {
                // console.log('Node ' + v + ': ' + JSON.stringify(g.node(v)));
                nmodels.forEach((nm1) => {
                    if (nm1.name === v) {
                        const node = g.node(v);
                        /// console.log(node);
                        nm1.setPosition(node.x, node.y);
                    }
                });
            });
            /*
            g.edges().forEach(function(e) {
                // console.log('Edge ' + e.v + ' -> ' + e.w + ': ' + JSON.stringify(g.edge(e)));
            });
            */
            let y = (g.graph().height || 0) + 100,
                x = 10;
            nmodels.forEach((nm1) => {
                if (!connected.includes(nm1.name)) {
                    nm1.setPosition(x, y);
                    x += 200;
                    if (x > 500) {
                        x = 0;
                        y += 50;
                    }
                }
            });
        } catch (e) {
            console.log('dagre fails', e);
        }
        this.engine.recalculatePortsVisually();
        setTimeout(() => {
            this.engine.zoomToFit();
            this.engine.repaintCanvas();
        }, 1);
    }

    public addComponent(c: ICradleComponent) {
        this.popups.loadOverlay = true;
        this.manifestModel.addComponent(c);

        this.renderGraph();
        this.popups.loadOverlay = false;

        AppToaster.show({
            message: `Component ${c.name} has been added to the chart`,
            intent: Intent.SUCCESS,
            timeout: 1000,
        });
    }

    private renderToolbar() {
        return (
            <div className="toolbar">
                <ControlGroup fill={true}>
                    <ButtonGroup>
                        <Button
                            minimal={true}
                            title={'Zoom in'}
                            onClick={() => {
                                this.diagramModel.setZoomLevel(
                                    this.diagramModel.getZoomLevel() * 1.1
                                );
                                this.engine.repaintCanvas();
                            }}
                            icon={IconNames.ZOOM_IN}
                        />
                        <Button
                            minimal={true}
                            icon={IconNames.ZOOM_OUT}
                            title={'Zoom out'}
                            onClick={() => {
                                this.diagramModel.setZoomLevel(
                                    this.diagramModel.getZoomLevel() * 0.9
                                );
                                this.engine.repaintCanvas();
                            }}
                        />
                        <Button
                            icon={IconNames.ZOOM_TO_FIT}
                            title={'Fit'}
                            minimal={true}
                            onClick={() => {
                                this.engine.zoomToFit();
                                this.engine.repaintCanvas();
                            }}
                        />

                        <HTMLSelect
                            className="zoom"
                            minimal={true}
                            defaultValue={`${this.diagramModel.getZoomLevel().toFixed(0)}%`}
                            onChange={(e: FormEvent<HTMLSelectElement>) => {
                                this.diagramModel.setZoomLevel(
                                    parseInt(e.currentTarget.value, 10)
                                );
                                this.engine.repaintCanvas();
                            }}
                        >
                            <option value={this.diagramModel.getZoomLevel()}>
                                {this.diagramModel.getZoomLevel().toFixed(0)}%
                            </option>
                            <option value={'100'}>100%</option>
                            <option value={'50'}>50%</option>
                            <option value={'200'}>200%</option>
                        </HTMLSelect>
                        <Divider />
                        <Button
                            icon={IconNames.COLUMN_LAYOUT}
                            minimal={true}
                            title={
                                (this.panels.isOpen ? 'Hide' : 'Show') +
                                ' Side Panels'
                            }
                            active={this.panels.isOpen}
                            onClick={() => {
                                this.panels.isOpen = !this.panels.isOpen;
                            }}
                        />
                        <Divider />
                        <Tooltip
                            content={
                                <div>
                                    Find a ready solution that suites your
                                    needs!
                                </div>
                            }
                        >
                            <Button
                                icon={IconNames.SEARCH_TEMPLATE}
                                title={'Solutions search'}
                                active={this.popups.solutionSearch}
                                onClick={() => {
                                    this.popups.solutionSearch = true;
                                }}
                                minimal={true}
                            />
                        </Tooltip>
                    </ButtonGroup>
                    <ButtonGroup fill={true} />
                    <ButtonGroup>
                        <Button
                            icon={IconNames.CODE}
                            minimal={true}
                            title={
                                'Open in manifest Editor. Manifest will be saved before switching!'
                            }
                            onClick={() => this.switchToManifestEditor()}
                        />
                        <Button
                            icon={IconNames.FLOPPY_DISK}
                            title={'Save'}
                            disabled={!this.manifestModel.isManifestChanged}
                            minimal={true}
                            onClick={() => this.saveManifest()}
                            intent={Intent.SUCCESS}
                        >
                            Save
                        </Button>
                        <Popover
                            content={
                                <Menu>
                                    <MenuItem
                                        text={'Save & Exit'}
                                        onClick={() =>
                                            this.saveManifestAndExit()
                                        }
                                    />
                                    <MenuDivider />
                                    <MenuItem
                                        text={'Exit without saving'}
                                        intent={Intent.DANGER}
                                        onClick={() => this.go('/clusters/')}
                                    />
                                </Menu>
                            }
                            minimal={true}
                            position={PopoverPosition.BOTTOM}
                        >
                            <AnchorButton
                                minimal={true}
                                small={true}
                                intent={Intent.SUCCESS}
                                rightIcon="caret-down"
                            />
                        </Popover>
                    </ButtonGroup>
                </ControlGroup>
            </div>
        );
    }
}

export const ManifestDesignerComponent = withRouter<IClusterProps, any>(
    ManifestDesigner
);
