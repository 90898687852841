import { action, observable } from 'mobx';
import { ICluster } from '../types/cluster';
import { KnoxService } from '../service/knox';
import { FormEvent } from 'react';
import { AppToaster, ErrorToast, SuccessToast } from '../service/toaster';
import { Intent } from '@blueprintjs/core';
import { IClusterKey } from '../types/cluster-key';
import { ClusterAwareModel } from './cluster-aware';

export class KeysModel extends ClusterAwareModel {
    @observable
    keys: IClusterKey[] = [];

    @observable
    activeCertificateAuthority?: IClusterKey;

    @observable cluster?: ICluster;

    @observable
    newDialog: {
        isOpen: boolean;
        isValid: boolean;
        isDisabled: boolean;
        isCreating: boolean;
        key: IClusterKey;
    };

    constructor() {
        super();
        this.newDialog = {
            isOpen: false,
            isCreating: false,
            isValid: false,
            isDisabled: false,
            key: {
                name: '',
                value: '',
            },
        };
    }

    @action
    showDialog() {
        this.newDialog.isOpen = true;
        this.validate();
    }

    @action
    hideDialog() {
        this.newDialog.isOpen = false;
        this.newDialog.key = { name: '', value: '' };
    }

    @action
    async submit(e: FormEvent) {
        e.preventDefault();

        if (!this.cluster) {
            return;
        }
        if (!this.newDialog.isValid) {
            return;
        }

        if (this.newDialog.isDisabled) {
            return;
        }

        if (this.newDialog.isCreating) {
            return;
        }

        this.newDialog.isCreating = true;

        return KnoxService.createKey(this.cluster.id, this.newDialog.key)
            .then((r) => {
                this.hideDialog();
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    message: 'New Key has been created!',
                });
                return this.loadKeys();
            })
            .catch(ErrorToast())
            .finally(() => {
                this.newDialog.isCreating = false;
                this.newDialog.isDisabled = false;
            });
    }

    @action
    async updateKey(key: IClusterKey) {
        if (!this.cluster) {
            return;
        }
        return KnoxService.createKey(this.cluster.id, key)
            .then(() => {
                key.hasChanged = false;
            })
            .then(SuccessToast(`${key.name} has been updated`))
            .catch(ErrorToast())
            .finally(() => {
                key.isUpdating = false;
            });
    }

    @action
    validate() {
        this.newDialog.isValid = this.newDialog.key.name.length > 0;
    }

    @action
    updateField(event: FormEvent<HTMLInputElement>) {
        this.newDialog.key[event.currentTarget.name] =
            event.currentTarget.value;

        this.validate();
    }

    @action
    async setCluster(cluster: ICluster) {
        if (!this.cluster || this.cluster.id !== cluster.id) {
            this.cluster = cluster;
            await this.loadKeys();
        }
    }

    @action
    async loadKeys() {
        if (!this.cluster) {
            return;
        }
        const { items } = await KnoxService.listKeys(this.cluster.id);
        this.keys = items
            .map((item: string) => ({
                name: item,
            }))
            .sort(cnSorter);
    }

    @action
    async toggle(k: IClusterKey) {
        await this.hydrate(k);
        k.uiIsOpen = !k.uiIsOpen;
    }

    @action
    async hydrate(k: IClusterKey) {
        if (!this.cluster) return;
        if (k.value !== undefined) return;
        k.uiIsLoading = true;
        const keyValue = await KnoxService.getKey(this.cluster.id, k.name);
        k.uiIsLoading = false;
        k.value = keyValue[k.name] || 'n/a';
    }

    @action
    async delete(k: IClusterKey) {
        if (k.uiIsDeleting) return;
        if (window.confirm(`Do you really want to remove ${k.name}?`)) {
            if (!this.cluster) return;
            k.uiIsDeleting = true;
            return KnoxService.deleteKey(this.cluster.id, k.name)
                .then(SuccessToast(`${k.name} key has been removed`))
                .catch(ErrorToast(`Error during ${k.name} key removal`))
                .then(this.loadKeys.bind(this));
        }
    }
}

const cnSorter = (a: IClusterKey, b: IClusterKey) => {
    return a.name.localeCompare(b.name);
};
