export function localDate(ts: Date | number | string): string {
    const d = new Date(ts);
    return d.toLocaleDateString();
}

export function localTime(ts: Date | number | string): string {
    const d = new Date(ts);
    return d.toLocaleTimeString();
}

export function ucFirst(s: string): string {
    return s[0].toUpperCase() + s.slice(1);
}

export function sanitizeString(s: string): string {
    return s.toLowerCase().replace(/[^a-z]/gi, ' ');
}

export function sanitizeDomain(s: string): string {
    return s.toLowerCase().replace(/[^a-z0-9-.]/gi, '');
}

export function ensureTrailingSlash(url: string): string {
    if (url.endsWith('/')) {
        return url;
    }
    return url + '/';
}

/**
 * Interpolate string with variables.
 * Works similar to sprintf but missing all the formatting capabilities.
 * Use "%s" to indicate where variable should be inserted.
 *
 * @param {string} template
 * @param {string[]} rest
 */
export function sp(template: string, ...rest: string[]): string {
    return rest.reduce((acc, cv) => {
        return acc.replace('%s', cv);
    }, template);
}

export function randomAlphaNumericString(length: number): string {
    const buckets = [
        [48, 57],
        [65, 90],
        [97, 122],
    ];
    const codes = [];
    for (let i = 0; i < length; i++) {
        const bucket = buckets[Math.round(Math.random() * 2)];
        codes.push(
            Math.round(Math.random() * (bucket[1] - bucket[0])) + bucket[0]
        );
    }
    return String.fromCharCode(...codes);
}

export function makeUrl(path: string, base: string | undefined): string {
    const url = new URL(path, base);
    return url.href;
}
