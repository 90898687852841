import React, { Component } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import './app.scss';
import Welcome from '../common/welcome/welcome';
import {
    Alignment,
    AnchorButton,
    Button,
    Classes,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    NavbarGroup,
    Popover,
    Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import logo from './logo.png';
import { LoginComponent } from '../common/login/login';
import { Unknown } from '../common/unknown/unknown';
import { ButtonLink } from '../common/links/button-link';
import { observer } from 'mobx-react';
import { UserModel } from '../../models/user-state';
import { ClustersListComponent } from '../clusters/clusters-list/clusters-list';
import { DestroyClusterComponent } from '../clusters/destroy/destroy';
import { ManifestEditComponent } from '../clusters/manifest/editor/manifest-edit';
import { ManifestViewComponent } from '../clusters/manifest/manifest-view';
import { UsersResetPasswordComponent } from '../users/reset-password/reset-password';
import { UsersAddComponent } from '../users/add/add';
import { ManifestDesignerComponent } from '../clusters/manifest/designer/manifest-designer';
import { TerminalComponent } from '../clusters/terminal/terminal';
import { ProvisionComponent } from '../clusters/provision/provision';
import { PokeComponent } from '../clusters/poke';
import { SecureStorageComponent } from '../clusters/secrets/secure-storage';
import { UsersViewComponent } from '../users/view/view';
import { UsersEditComponent } from '../users/edit/edit';
import { MenuItemLink } from '../common/links/menuitem-link';
import { CertificateAuthoritiesComponent } from '../clusters/secrets/certificate-authorities';
import { CertificatesComponent } from '../clusters/secrets/certificates';
import { SecureKeysStorageComponent } from '../clusters/secrets/keys';
import { AppNavigator } from '../../models/navigation';
import { ConfigService } from '../../service/config';
import { ClusterLogsComponent } from '../clusters/logs/logs';
import { ClusterACLComponent } from '../clusters/acl/acl';
import { ChartMuseumComponent } from '../store/charts/chartmuseum';
import { makeUrl } from '../../utils/formatters';

const { REACT_APP_CRADLE_URL } = process.env;

@observer
class App extends Component {
    constructor(props: any) {
        super(props);
        this.viewUserPage = this.viewUserPage.bind(this);
        this.logOut = this.logOut.bind(this);
        AppNavigator.clear();
    }

    static getDerivedStateFromError(error: any) {
        console.log('AppError', error);
        return { hasError: true };
    }

    componentDidMount(): void {
        ConfigService.init().then();
        setInterval(() => {
            UserModel.assureCognitoSession().then();
        }, 60000);
    }

    logOut() {
        UserModel.signOut().finally(() => this.forceUpdate());
    }

    viewUserPage() {
        if (UserModel.isUserAuthenticated) {
            return (
                <Redirect
                    to={{ pathname: '/users/' + UserModel.userId + '' }}
                />
            );
        }
    }

    render() {
        document.title = AppNavigator.title;

        return (
            <div className="appComponent">
                <Navbar className={Classes.DARK} fixedToTop={true}>
                    {this.MainMenu()}

                    {this.AuthenticatedMenu()}
                </Navbar>
                <div className={'main'}>
                    <Switch>
                        <Route path="/" exact component={Welcome} />

                        <Route
                            path="/users/auth/reset-password"
                            component={UsersResetPasswordComponent}
                        />
                        <Route
                            path="/users/auth/sign-up"
                            component={UsersAddComponent}
                        />
                        <Route
                            path="/users/auth/sign-in"
                            component={LoginComponent}
                        />
                        <PrivateRoute
                            path="/users/:userId/edit"
                            component={UsersEditComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/users/:userId"
                            component={UsersViewComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />

                        <PrivateRoute
                            path="/clusters/:clusterId/provision/"
                            component={ProvisionComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/ssh/"
                            component={TerminalComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />

                        <PrivateRoute
                            path="/clusters/:clusterId/destroy/"
                            component={DestroyClusterComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/poke/"
                            component={PokeComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/manifest/view/"
                            component={ManifestViewComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/manifest/edit/"
                            component={ManifestEditComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/manifest/designer/"
                            component={ManifestDesignerComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />

                        <PrivateRoute
                            path="/clusters/:clusterId/secrets/keys/"
                            component={SecureKeysStorageComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/secrets/certs/:ca"
                            component={CertificatesComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/secrets/certs/"
                            component={CertificateAuthoritiesComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/secrets/"
                            component={SecureStorageComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/logs/"
                            component={ClusterLogsComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/:clusterId/acl/"
                            component={ClusterACLComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/clusters/"
                            component={ClustersListComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <PrivateRoute
                            path="/store/"
                            component={ChartMuseumComponent}
                            isAuthenticated={UserModel.isUserAuthenticated}
                        />
                        <Route component={Unknown} />
                    </Switch>
                </div>
            </div>
        );
    }

    renderDocumentationButton() {
        return (
            <AnchorButton
                href={makeUrl('/docs/', REACT_APP_CRADLE_URL)}
                minimal={true}
            >
                Documentation
            </AnchorButton>
        );
    }

    MainMenu() {
        if (!UserModel.isUserAuthenticated) {
            return (
                <NavbarGroup align={Alignment.LEFT}>
                    <Navbar.Heading className="hidden-xs logo">
                        <Link to={'/'}>
                            <img src={logo} alt={'Logo'} />
                        </Link>
                    </Navbar.Heading>

                    <Navbar.Heading>
                        <AnchorButton
                            href={makeUrl('/', REACT_APP_CRADLE_URL)}
                            minimal={true}
                        >
                            About Cradle
                        </AnchorButton>
                        {this.renderDocumentationButton()}
                    </Navbar.Heading>
                </NavbarGroup>
            );
        }

        return (
            <NavbarGroup align={Alignment.LEFT}>
                <Navbar.Heading className="hidden-xs logo">
                    <Link to={'/'}>
                        <img src={logo} alt={'Logo'} />
                    </Link>
                </Navbar.Heading>
                <Navbar.Heading className="hidden-xs">
                    <ButtonLink
                        to={'/clusters/'}
                        minimal={true}
                        icon={IconNames.CUBE}
                    >
                        Clusters
                    </ButtonLink>
                    <ButtonLink
                        to={'/store/'}
                        minimal={true}
                        icon={IconNames.APPLICATIONS}
                    >
                        Charts
                    </ButtonLink>
                </Navbar.Heading>
            </NavbarGroup>
        );
    }

    AuthenticatedMenu() {
        if (!UserModel.isUserAuthenticated) {
            return <NavbarGroup align={Alignment.RIGHT}></NavbarGroup>;
        }
        return (
            <NavbarGroup align={Alignment.RIGHT}>
                {this.renderDocumentationButton()}
                <Navbar.Divider />
                <Popover
                    content={
                        <Menu>
                            <MenuItemLink
                                className={Classes.MENU_ITEM}
                                icon={IconNames.LIST_DETAIL_VIEW}
                                text="View"
                                to={'/users/' + UserModel.userId + '/'}
                            />
                            <MenuItemLink
                                className={Classes.MENU_ITEM}
                                icon={IconNames.SETTINGS}
                                text="Settings"
                                to={'/users/' + UserModel.userId + '/edit/'}
                            />
                            <MenuDivider />
                            <MenuItem
                                text="Sign Out"
                                icon={IconNames.LOG_OUT}
                                onClick={() => this.logOut()}
                            />
                        </Menu>
                    }
                    position={Position.BOTTOM_RIGHT}
                >
                    <Button
                        icon={IconNames.USER}
                        rightIcon={IconNames.CARET_DOWN}
                        minimal={true}
                    >
                        <span className="hidden-xs">{UserModel.userName}</span>
                    </Button>
                </Popover>
            </NavbarGroup>
        );
    }
}

// @ts-ignore
function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

export default App;
