import { action, observable } from 'mobx';
import { ICluster } from '../types/cluster';
import { ClustersModel } from './clusters';

export type MaybeCluster = ICluster | undefined;

export class ClusterAwareModel {
    cm: ClustersModel;

    @observable
    cluster?: MaybeCluster = undefined;

    constructor() {
        this.cm = new ClustersModel();
    }

    @action
    setCluster(c: ICluster) {
        this.cluster = c;
    }

    @action
    async loadCluster(clusterId: string): Promise<MaybeCluster> {
        const cluster = await this.cm.getCluster(clusterId);
        if (cluster) {
            this.setCluster(cluster);
        }
        return this.cluster;
    }
}
