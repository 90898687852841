import { action, observable } from 'mobx';
import { ICluster } from '../types/cluster';
import { KnoxService } from '../service/knox';
import { ICertificateAuthority } from '../types/ca';
import { ICommonName } from '../types/common-name';
import { FormEvent } from 'react';
import { AppToaster, ErrorToast } from '../service/toaster';
import { Intent } from '@blueprintjs/core';
import { ClusterAwareModel } from './cluster-aware';

export class CAModel extends ClusterAwareModel {
    @observable
    cas: ICertificateAuthority[] = [];

    @observable
    activeCertificateAuthority?: ICertificateAuthority;

    @observable
    newDialog: {
        isOpen: boolean;
        isValid: boolean;
        isDisabled: boolean;
        isCreating: boolean;
        ca: ICertificateAuthority;
    };

    constructor() {
        super();
        this.newDialog = {
            isOpen: false,
            isCreating: false,
            isValid: false,
            isDisabled: false,
            ca: {
                description: '',
                commonName: '',
            },
        };
    }

    @action
    showDialog() {
        this.newDialog.isOpen = true;
        this.validate();
    }

    @action
    hideDialog() {
        this.newDialog.isOpen = false;
        this.newDialog.ca = { commonName: '', description: '' };
    }

    @action
    async submit(e: FormEvent) {
        e.preventDefault();

        if (!this.cluster) {
            return;
        }
        if (!this.newDialog.isValid) {
            return;
        }

        if (this.newDialog.isDisabled) {
            return;
        }

        if (this.newDialog.isCreating) {
            return;
        }

        this.newDialog.isCreating = true;

        this.newDialog.ca.projectName = this.cluster.id;

        return KnoxService.createCertificateAuthorities(this.newDialog.ca)
            .then((r) => {
                this.hideDialog();
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    message: 'New Certificate Authority has been created!',
                });
                return this.loadCAs();
            })
            .catch(ErrorToast())
            .finally(() => {
                this.newDialog.isCreating = false;
                this.newDialog.isDisabled = false;
            });
    }

    @action
    validate() {
        this.newDialog.isValid = this.newDialog.ca.commonName.length > 0;
    }

    @action
    updateField(event: FormEvent<HTMLInputElement>) {
        this.newDialog.ca[event.currentTarget.name] = event.currentTarget.value;

        this.validate();
    }

    @action
    async setCluster(cluster: ICluster) {
        if (!this.cluster || this.cluster.id !== cluster.id) {
            this.cluster = cluster;
            await this.loadCAs();
        }
    }

    @action
    async loadCAs() {
        if (!this.cluster) {
            return;
        }
        console.log('loadCAs', this.cluster);
        const { items } = await KnoxService.listCertificateAuthorities(
            this.cluster.id
        );
        console.log(items);
        this.cas = items.sort(cnSorter);
    }

    @action
    createCertificateAuthority(ca: ICertificateAuthority) {
        if (this.cluster) {
            return;
        }
    }

    @action
    async setActiveCertificateAuthority(name: string) {
        const ca = this.cas.find((item) => item.commonName === name);
        if (ca) {
            console.log('setActiveCertificateAuthority', ca, name);
            this.activeCertificateAuthority = ca;
        }
    }
}

const cnSorter = (a: ICommonName, b: ICommonName) => {
    return a.commonName.localeCompare(b.commonName);
};
