import React, { Component } from 'react';

import './destroy.scss';
import { observer } from 'mobx-react';
import {
    Button,
    ButtonGroup,
    Callout,
    Card,
    Classes,
    H1,
    H3,
    Intent,
    Radio,
    RadioGroup,
} from '@blueprintjs/core';
import { ButtonLink } from '../../common/links/button-link';
import { Redirect, withRouter } from 'react-router';
import {
    AutomationType,
    DestroyStepEnum,
    IClusterProps,
    IInstructions,
    ProvisionStatusEnum,
    SettingsGroup,
    UIState,
} from '../../../types';
import { observable } from 'mobx';
import { StatefulComponent } from '../../common/stateful/stateful';
import { IconNames } from '@blueprintjs/icons';
import { DestroyModel } from '../../../models/destroy';
import { AppNavigator } from '../../../models/navigation';
import { ConsoleExample } from '../../common/console-example/console-example';
import { renderTokenWarning } from '../provision/iaas/bare-metal';
import { FormParameter } from '../../common/form/form';

const { RUNNING_TEXT } = Classes;
const { REACT_APP_CRADLE_KNOX_URL } = process.env;

@observer
class Uninstall extends Component<IClusterProps> {
    dm: DestroyModel;

    @observable uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);
        this.dm = new DestroyModel();
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');
        this.uiLoadState = UIState.Loading;
        try {
            const { clusterId } = this.props.match.params;
            if (clusterId) {
                await this.dm.loadCluster(clusterId);
                AppNavigator.add(
                    `/clusters/${clusterId}/`,
                    this.dm.cluster ? this.dm.cluster.clusterName : 'Cluster'
                );
                AppNavigator.add(
                    `/clusters/${clusterId}/destroy/`,
                    'Uninstall'
                );
                if (this.dm.cluster) {
                    this.uiLoadState = UIState.Loaded;
                }
            }
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    componentDidMount(): void {
        this.load().then();
    }

    submit() {
        this.dm.nextStep();
    }

    renderParams() {
        return this.dm.fm.fields[SettingsGroup.Basic].map((p) => {
            return <FormParameter fm={this.dm.fm} p={p} key={p.k} />;
        });
    }

    renderParametersStep() {
        return (
            <div>
                <H3>Cluster parameters</H3>
                <Callout intent={Intent.DANGER}>
                    This process will uninstall Cradle and all its components
                    from your infrastructure. Please make sure that you have
                    your data backed up before proceeding.
                </Callout>

                <p>
                    In order to connect to your infrastructure provide the
                    following details:
                </p>
                {this.renderParams()}

                <div>
                    {this.dm.isDestroying && (
                        <Callout
                            intent={Intent.WARNING}
                            icon={IconNames.WARNING_SIGN}
                            title={'Preparing Uninstall'}
                        >
                            <p className={RUNNING_TEXT}>
                                Please be patient while we encrypt and store
                                your uninstall parameters. This may take several
                                seconds to finish.
                            </p>
                        </Callout>
                    )}
                </div>
            </div>
        );
    }

    renderAutomationPickerStep() {
        return (
            <div>
                <H3>Cluster automation</H3>
                <div className="confirmationDetails">
                    <div>
                        <p className={RUNNING_TEXT}>
                            Your configuration has been saved, and we’re ready
                            to uninstall Cradle. You can now choose from one of
                            two ways to do this:
                        </p>
                        <RadioGroup
                            className={'advancedRadioGroup'}
                            onChange={(event) =>
                                (this.dm.automation = event.currentTarget
                                    .value as AutomationType)
                            }
                            selectedValue={this.dm.automation}
                        >
                            <Radio
                                value={AutomationType.Automatic}
                                className={
                                    this.dm.automation ===
                                    AutomationType.Automatic
                                        ? 'selected'
                                        : undefined
                                }
                            >
                                <div className="radio">
                                    <h4>Automatic removal</h4>
                                    <p className={RUNNING_TEXT}>
                                        We will automatically attempt to
                                        uninstall your Cradle cluster for you.
                                        This will involve connecting to your
                                        infrastructure using the credentials
                                        that you have provided, and uninstalling
                                        software components from it.
                                    </p>
                                    <p className={RUNNING_TEXT}>
                                        If you choose this option, you need to
                                        ensure that your infrastructure is
                                        reachable over the public cloud. Double
                                        check your firewall settings and
                                        security roles to ensure that your
                                        infrastructure is accessible.
                                    </p>
                                </div>
                            </Radio>
                            <Radio
                                value={AutomationType.Manual}
                                className={
                                    this.dm.automation === AutomationType.Manual
                                        ? 'selected'
                                        : undefined
                                }
                            >
                                <div className="radio">
                                    <h4>Manual removal</h4>
                                    <p className={RUNNING_TEXT}>
                                        You can uninstall Cradle by running a
                                        container image that we provide and
                                        following a few simple instructions.
                                        This is the recommended approach for
                                        bare metal installations or if your
                                        infrastructure is not reachable over the
                                        public internet.
                                    </p>
                                    <p className={RUNNING_TEXT}>
                                        With this option, you will need a{' '}
                                        <b>
                                            <i>node</i>
                                        </b>{' '}
                                        (computer) that can run Docker
                                        containers. This node must be on a
                                        network that allows it to connect to
                                        your infrastructure. You will have{' '}
                                        <b>12 hours</b> to complete these steps,
                                        after which your configuration will
                                        expire, and you will have to restart
                                        this process.
                                    </p>
                                    <p className={RUNNING_TEXT}>
                                        This process can take <b>30-60</b>{' '}
                                        minutes depending on your configuration.
                                    </p>
                                </div>
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>
            </div>
        );
    }

    renderConfirmationStep() {
        return (
            <div>
                <H3>Confirm Uninstall</H3>
                <div>
                    <p className={RUNNING_TEXT}>
                        We're ready to uninstall Cradle software components from
                        your infrastructure.
                    </p>
                    <p className={RUNNING_TEXT}>
                        Please remember that this process will remove all data
                        and configuration that you may have deployed on to your
                        cluster. If you have not yet backed them up, and would
                        like to do so now, please click the cancel button and do
                        so now.
                    </p>
                </div>
            </div>
        );
    }

    renderInstructionsStep() {
        return (
            <div>
                <H3>Manual Uninstall</H3>
                {renderInstructions(this.dm.instructions)}
            </div>
        );
    }

    renderDoneStep() {
        return (
            <div>
                <Callout
                    intent={Intent.SUCCESS}
                    title="Cluster is uninstalling"
                >
                    <p className={RUNNING_TEXT}
                       id="uninstallLogs1"
                    >
                        The uninstall process has been started, and can up to 30
                        - 45 minutes to complete. You can view the{' '}
                        <a href="#uninstallLogs1"
                            onClick={(e) => {
                                e.preventDefault();
                                this.load().then();
                            }}

                        >
                            uninstall logs
                        </a>{' '}
                        to track progress, and learn a few neat tips and tricks
                        while you wait.
                    </p>
                </Callout>
            </div>
        );
    }

    renderStep() {
        switch (this.dm.step) {
            case DestroyStepEnum.Parameters:
                return this.renderParametersStep();
            case DestroyStepEnum.Automation:
                return this.renderAutomationPickerStep();
            case DestroyStepEnum.Confirmation:
                return this.renderConfirmationStep();
            case DestroyStepEnum.Instructions:
                return this.renderInstructionsStep();
            case DestroyStepEnum.Done:
                return this.renderDoneStep();
            default:
                return <div />;
        }
    }

    renderLogs() {
        return (
            <div className={'logList'}>
                <ConsoleExample
                    text={this.dm.logs.text}
                    autoScroll={true}
                    fullScreen={true}
                />
                <div className={'footer '}>
                    <ButtonGroup>
                        <ButtonLink to={'/clusters/'}>Back</ButtonLink>
                        <Button
                            loading={this.dm.logs.isRefreshingLogs}
                            disabled={this.dm.logs.isRefreshingLogs}
                            onClick={() => this.dm.refreshLogs()}
                            icon={IconNames.REFRESH}
                        >
                            Refresh logs
                        </Button>
                    </ButtonGroup>
                    <div>&nbsp;</div>
                </div>
            </div>
        );
    }

    renderParamForm() {
        return (
            <Card>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        this.dm.nextStep();
                    }}
                >
                    <div>{this.renderStep()}</div>
                    <div className="flex-spaced footer">
                        <div className="bp3-flex-expanded">&nbsp;</div>
                        <div>
                            {this.dm.step === DestroyStepEnum.Done ? (
                                <ButtonLink
                                    to={'/clusters/'}
                                    text="View clusters "
                                />
                            ) : (
                                <Button
                                    text={'Back'}
                                    onClick={() => {
                                        this.dm.previousStep();
                                    }}
                                    disabled={this.dm.isDestroying}
                                />
                            )}

                            <Button
                                type={'submit'}
                                intent={Intent.PRIMARY}
                                icon={
                                    this.dm.step === DestroyStepEnum.Done
                                        ? IconNames.LIST
                                        : undefined
                                }
                                text={this.renderContinueButtonText(
                                    this.dm.step
                                )}
                                disabled={this.dm.isNextButtonDisabled}
                                loading={this.dm.isDestroying}
                            />
                        </div>
                    </div>
                </form>
            </Card>
        );
    }

    componentWillUnmount(): void {
        AppNavigator.clear();
    }

    renderContinueButtonText(step: DestroyStepEnum) {
        switch (step) {
            case DestroyStepEnum.Confirmation:
                return 'Confirm';
            case DestroyStepEnum.Done:
                return 'View Logs';
            default:
                return 'Continue';
        }
    }

    renderCluster() {
        if (!this.dm.cluster) return;

        switch (this.dm.cluster.provisionStatus) {
            case ProvisionStatusEnum.Provisioned:
            case ProvisionStatusEnum.DestroyFailed:
                return this.renderParamForm();

            case ProvisionStatusEnum.Destroying:
            case ProvisionStatusEnum.Provisioning:
            case ProvisionStatusEnum.ProvisionFailed:
                return (
                    <Redirect to={`/clusters/${this.dm.cluster.id}/logs/`} />
                );
            default:
                return <p>Not implemented</p>;
        }
    }

    render() {
        return (
            <div className="destroyClusterComponent">
                <StatefulComponent state={this.uiLoadState}>
                    {this.dm.cluster && (
                        <div>
                            <H1>
                                Uninstall{' '}
                                <span className={Classes.TEXT_MUTED}>
                                    {this.dm.cluster.clusterName}
                                </span>
                            </H1>
                            {this.renderCluster()}
                        </div>
                    )}
                </StatefulComponent>
            </div>
        );
    }
}

export function renderInstructions(instructions: IInstructions) {
    const cradleCmd =
        `docker run --rm -e CRADLE_UP_TOKEN='${instructions.token}' \\\n` +
        `  -e CRADLE_KNOX_URL='${REACT_APP_CRADLE_KNOX_URL}' \\\n` +
        '  --entrypoint cradle_down analoggarage/agc-cradle-up:latest';

    return (
        <div>
            <h4>Manual Cradle removal steps</h4>
            <ol>
                <li>
                    <p className={RUNNING_TEXT}>
                        In order to start cluster removal flow you have to
                        install{' '}
                        <a
                            href="https://docs.docker.com/install/"
                            target="_blank" rel="noreferrer"
                        >
                            Docker
                        </a>{' '}
                        on your local machine.
                    </p>
                </li>
                <li>
                    <p className={RUNNING_TEXT}>
                        On the machine with docker run cradle-down container
                        which will start your cluster removal:
                    </p>
                    <ConsoleExample text={cradleCmd} />
                    {renderTokenWarning()}
                </li>
            </ol>
        </div>
    );
}

export const DestroyClusterComponent = withRouter<IClusterProps, any>(
    Uninstall
);
