import azureLogo from './azure.svg';
import React from 'react';
import { IaaSProvider } from '../../../../types/iaas-provider';
import { SettingsGroup } from '../../../../types/settings-group';
import { IClusterInfrastructureProvider } from '../../../../types/cluster-infrastructure-provider-interface';

export const azureIaaSProvider: IClusterInfrastructureProvider = {
    id: IaaSProvider.Azure,
    name: 'Azure',
    logo: azureLogo,
    description: (
        <span key="azureD">
            Coming Soon: Install a Cradle cluster on a Microsoft Azure cloud
            account that <b>you own</b>.
        </span>
    ),
    details: (
        <div key="azureDet">
            <p>
                We are working hard on making this available. Please check in
                again soon to see if we achieved this goal.
            </p>

            <p>
                If you have a pressing need and can't wait, reach out to us via
                email:{' '}
                <a
                    target="_blank" rel="noreferrer"
                    href="mailto:cradle_maintainers@analog.com?Subject=AzureOnCradle"
                >
                    cradle_maintainers@analog.com
                </a>
                .<i> We'll do our best to help you.</i>
            </p>
        </div>
    ),
    supported: false,
    config: {
        [SettingsGroup.Basic]: [],
        [SettingsGroup.Advanced]: [
            // prometheusConfigParameter,
            // certManagerConfigParameter
        ],
    },
};
