import React, { Component } from 'react';
import { Button, ButtonGroup, Intent, NonIdealState } from '@blueprintjs/core';
import './unknown.scss';
import { IconNames } from '@blueprintjs/icons';
import { Link } from 'react-router-dom';
import { AppNavigator } from '../../../models/navigation';

export class Unknown extends Component {
    public state = {
        title: 'Hey {username}, winter is coming!',
        description: 'Please start from the main page.',
    };

    componentDidMount(): void {
        AppNavigator.clear();
    }

    public render() {
        return (
            <div>
                <NonIdealState
                    icon={IconNames.SNOWFLAKE}
                    title={this.state.title}
                    description={this.state.description}
                >
                    <ButtonGroup>
                        <Link to={'/'}>
                            <Button
                                icon={IconNames.HOME}
                                intent={Intent.PRIMARY}
                            >
                                Go Home
                            </Button>
                        </Link>
                    </ButtonGroup>
                </NonIdealState>
            </div>
        );
    }
}

export default Unknown;
