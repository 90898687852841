import React, { Component } from 'react';
import { Card, H1, H2, Icon } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router';
import { ICluster, IClusterProps } from '../../../types';

import './secure-storage.scss';
import { UIState } from '../../../types/';
import { StatefulComponent } from '../../common/stateful/stateful';
import { Link } from 'react-router-dom';
import { AppNavigator } from '../../../models/navigation';
import { ButtonLink } from '../../common/links/button-link';
import { IconNames } from '@blueprintjs/icons';
import { ClustersModel } from '../../../models/clusters';
import { SubheaderBreadcrumbs } from '../../common/breadcrumbs/cms-breadcrumbs';

@observer
class SecureStorage extends Component<IClusterProps> {
    @observable cluster?: ICluster;

    @observable uiLoadState: UIState = UIState.Empty;

    cm: ClustersModel;

    constructor(props: any) {
        super(props);
        this.cm = new ClustersModel();
    }

    componentWillUnmount(): void {
        AppNavigator.clear();
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');
        this.uiLoadState = UIState.Loading;
        try {
            this.cluster = await this.cm.getCluster(
                this.props.match.params.clusterId
            );
            if (this.cluster) {
                AppNavigator.add(
                    `/clusters/${this.cluster.id}/`,
                    this.cluster.clusterName
                );
                AppNavigator.add(
                    `/clusters/${this.cluster.id}/secrets/`,
                    'Secrets'
                );
            }
            this.uiLoadState = UIState.Loaded;
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    async componentDidMount() {
        this.load().then();
    }

    public render() {
        return (
            <div className={'secureStorageComponent'}>
                <div>
                    <H1>Secrets Storage</H1>
                    <StatefulComponent state={this.uiLoadState}>
                        <SubheaderBreadcrumbs
                            breadcrumbs={AppNavigator.items}
                        />
                        {this.cluster ? this.renderClusterUI() : ''}
                    </StatefulComponent>
                </div>
            </div>
        );
    }

    renderClusterUI() {
        if (!this.cluster) return;
        return (
            <div>
                <Link
                    key={'cas'}
                    to={`/clusters/${this.cluster.id}/secrets/certs/`}
                >
                    <Card>
                        <div className="flex-spaced">
                            <div>Certificate authorities</div>
                            <div>
                                <Icon icon={IconNames.CHEVRON_RIGHT} />
                            </div>
                        </div>
                    </Card>
                </Link>
                <Link
                    key={'keys'}
                    to={`/clusters/${this.cluster.id}/secrets/keys/`}
                >
                    <Card>
                        <div className="flex-spaced">
                            <div>Keys</div>
                            <div>
                                <Icon icon={IconNames.CHEVRON_RIGHT} />
                            </div>
                        </div>
                    </Card>
                </Link>
                <p></p>
                <ButtonLink to={'/clusters/'} icon={IconNames.CHEVRON_LEFT}>
                    Back
                </ButtonLink>
            </div>
        );
    }

    renderCAList() {
        return (
            <div>
                <H2>Certificate authorities</H2>
                <p>Authority Description</p>
            </div>
        );
    }

    renderCertificatesList() {
        return (
            <div>
                <H2>Certificates</H2>
                <p>Common Name Description</p>
            </div>
        );
    }

    renderKeysList() {
        return (
            <div>
                <H2>Keys</H2>
            </div>
        );
    }
}

export const SecureStorageComponent = withRouter<IClusterProps, any>(
    SecureStorage
);
