import React, { Component } from 'react';
import './welcome.scss';
import { Redirect } from 'react-router-dom';
import { UserModel } from '../../../models/user-state';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AppNavigator } from '../../../models/navigation';
import LoginComponent from '../login/login';

@observer
class Welcome extends Component {
    @observable
    isRedirect = false;

    public state = {
        title: 'Welcome to Clusters Management Service',
        description:
            'Sign in with your credentials to manage accounts or users.',
    };

    checkAuthState() {
        if (UserModel.isUserAuthenticated) {
            this.isRedirect = true;
        }
    }

    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/', 'Cradle');

        this.checkAuthState();
        setTimeout(() => {
            this.checkAuthState();
        }, 1000);
    }

    public render() {
        if (this.isRedirect) {
            return <Redirect to={'/clusters/'} />;
        }

        return (
            <div className="welcomeComponent">
                <div className="mainBlock">
                    <div className="textBlock">
                        <h1>Cradle</h1>
                        <LoginComponent
                            accountId={''}
                            redirectToReferrer={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Welcome;
