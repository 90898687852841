import React, { Component } from 'react';
import {
    Button,
    Callout,
    Card,
    Classes,
    Dialog,
    FormGroup,
    H1,
    H4,
    Icon,
    InputGroup,
    Intent,
} from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router';
import './cas.scss';
import { UIState } from '../../../types/uistate';
import { StatefulComponent } from '../../common/stateful/stateful';
import { Link } from 'react-router-dom';
import { CAModel } from '../../../models/cas';
import { ICertificateAuthority } from '../../../types/ca';
import { ICAsParams } from '../../../types/casparams';
import { ICertificate } from '../../../types/certificate';
import { AppNavigator } from '../../../models/navigation';
import { IconNames } from '@blueprintjs/icons';
import { ButtonLink } from '../../common/links/button-link';
import { SubheaderBreadcrumbs } from '../../common/breadcrumbs/cms-breadcrumbs';

@observer
class CertificateAuthorities extends Component<ICAsParams> {
    @observable uiLoadState: UIState = UIState.Empty;
    casModel: CAModel;

    constructor(props: any) {
        super(props);
        this.casModel = new CAModel();
        this.renderCertificateAuthority =
            this.renderCertificateAuthority.bind(this);
        this.renderCertificate = this.renderCertificate.bind(this);
        this.renderNewCADialog = this.renderNewCADialog.bind(this);
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');

        this.uiLoadState = UIState.Loading;
        try {
            const { clusterId } = this.props.match.params;
            if (clusterId) {
                const cluster = await this.casModel.loadCluster(clusterId);
                await this.casModel.loadCAs();
                if (cluster) {
                    AppNavigator.add(
                        `/clusters/${clusterId}/`,
                        this.casModel.cluster
                            ? this.casModel.cluster.clusterName
                            : ''
                    );
                    AppNavigator.add(
                        `/clusters/${clusterId}/secrets/`,
                        'Secrets'
                    );
                    AppNavigator.add(
                        `/clusters/${clusterId}/secrets/certs/`,
                        'CAs'
                    );
                    this.uiLoadState = UIState.Loaded;
                }
            }
        } catch (e) {
            console.log(e);
            this.uiLoadState = UIState.Failed;
        }
    }

    componentDidMount() {
        this.load().then();
    }

    public render() {
        return (
            <div className={'certificateAuthoritiesComponent'}>
                <StatefulComponent state={this.uiLoadState}>
                    <div>
                        <div className="flex-spaced">
                            <H1>Certificate authorities</H1>
                            <Button
                                icon={IconNames.ADD}
                                minimal={true}
                                onClick={() => this.casModel.showDialog()}
                                intent={Intent.PRIMARY}
                            >
                                Add
                            </Button>
                        </div>
                        <SubheaderBreadcrumbs
                            breadcrumbs={AppNavigator.items}
                        />

                        <p></p>
                        {this.casModel.cluster ? this.renderCAList() : '-'}

                        {this.renderNewCADialog()}
                    </div>
                </StatefulComponent>
                <p></p>
                <ButtonLink to={'../'} icon={IconNames.CHEVRON_LEFT}>
                    Back
                </ButtonLink>
            </div>
        );
    }

    renderCAList() {
        return (
            <div>
                {this.casModel.activeCertificateAuthority
                    ? '  '
                    : this.casModel.cas.map(this.renderCertificateAuthority)}
            </div>
        );
    }

    renderCertificate(cert: ICertificate) {
        return (
            <div key={cert.commonName}>
                <Card>
                    <div className="flex-row">
                        <div>
                            <H4>{cert.commonName}</H4>
                            <p>{cert.fingerprint}</p>
                        </div>
                        <div>
                            <Button>View Certificate</Button>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    renderCertificateAuthority(ca: ICertificateAuthority) {
        if (!this.casModel.cluster) {
            return '';
        }
        return (
            <Link
                key={ca.commonName}
                to={`/clusters/${this.casModel.cluster.id}/secrets/certs/${ca.commonName}/`}
            >
                <Card>
                    <div className="flex-spaced">
                        <div>
                            <H4>{ca.commonName}</H4>
                            <p>{ca.mountPath}</p>
                        </div>
                        <div>
                            <Icon icon={IconNames.CHEVRON_RIGHT} />
                        </div>
                    </div>
                </Card>
            </Link>
        );
    }

    renderNewCADialog() {
        const { newDialog } = this.casModel;
        return (
            <Dialog
                isOpen={newDialog.isOpen}
                onClose={() => this.casModel.hideDialog()}
                canEscapeKeyClose={false}
                usePortal={true}
                autoFocus={true}
                canOutsideClickClose={false}
                isCloseButtonShown={!newDialog.isCreating}
                title="Create New Certificate Authority"
            >
                <form onSubmit={(e) => this.casModel.submit(e)} action={'#'}>
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label={'Common Name'}
                            labelFor="commonName-input"
                            labelInfo={'(required)'}
                            helperText={'Use domain name here.'}
                        >
                            <InputGroup
                                disabled={newDialog.isDisabled}
                                required={true}
                                id="commonName-input"
                                placeholder="myca.project.com"
                                type={'domain'}
                                name={'commonName'}
                                value={newDialog.ca.commonName}
                                onChange={(e: any) =>
                                    this.casModel.updateField(e)
                                }
                                minLength={1}
                                pattern={'^[a-z0-9-.]+$'}
                                autoFocus={true}
                            />
                        </FormGroup>
                        <FormGroup
                            label={'Description'}
                            labelFor="description-input"
                            labelInfo={'(optional)'}
                            helperText={'Your description.'}
                        >
                            <InputGroup
                                disabled={newDialog.isDisabled}
                                required={false}
                                id="description-input"
                                placeholder=""
                                type={'text'}
                                name={'description'}
                                value={newDialog.ca.description}
                                onChange={(e: any) =>
                                    this.casModel.updateField(e)
                                }
                            />
                        </FormGroup>

                        <Callout intent={Intent.PRIMARY}>
                            This action will create a certificate authority.
                            After you should be able to issue new certificate.
                            <br />
                            Use chart secrets to deliver certificates to the
                            cluster.
                        </Callout>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => this.casModel.hideDialog()}
                                disabled={newDialog.isDisabled}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    newDialog.isDisabled || !newDialog.isValid
                                }
                                loading={newDialog.isCreating}
                                type={'submit'}
                                intent={Intent.PRIMARY}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const CertificateAuthoritiesComponent = withRouter<ICAsParams, any>(
    CertificateAuthorities
);
