import { observer } from 'mobx-react';
import React, { Component, FormEvent } from 'react';
import {
    AnchorButton,
    Button,
    Classes,
    ControlGroup,
    Dialog,
    FormGroup,
    HTMLSelect,
    Icon,
    InputGroup,
    Intent,
    Switch,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ClustersModel } from '../../../../models/clusters';
import { CradleComponents } from '../../../../models/cradle-components';
import { AppToaster } from '../../../../service/toaster';
import {
    ICradleComponent,
    ICradleComponentValue,
    IFileOption,
    IManifestComponent,
    IOption,
} from '../../../../types';
import { ManifestModel } from './manifest-model';
import { PipelineDiagramModel } from './chart/pipeline-diagram-model';
import { ChartParameterRow } from './chart/chart-parameter';
import { observable } from 'mobx';
import {
    createNewItemRendererFromQuery,
    createNewValueFromQuery,
    extractNames,
    extractValues,
    filterParameterName,
    filterValue,
    filterValues,
    itemsAreEqual,
    renderSuggestedValue,
} from './suggest';
import { Suggest } from '@blueprintjs/select';

const ValuesSuggest = Suggest.ofType<string>();

export interface IInspectorPanelProps {
    models: {
        clustersModel: ClustersModel;
        componentsModel: CradleComponents;
        manifestModel: ManifestModel;
        diagramModel: PipelineDiagramModel;
    };

    isOpen: boolean;

    repaint(): void;

    renderGraph(): void;
}

@observer
export class InspectorPanel extends Component<IInspectorPanelProps, any> {
    @observable parameterEditorPopup = {
        index: -1,
        isOpen: false,
        secure: false,
        name: '',
        value: '',
    };

    @observable suggestedValues: string[] = [];
    @observable suggestedNames: string[] = [];

    constructor(props: any) {
        super(props);
        this.changeActiveComponentHandler =
            this.changeActiveComponentHandler.bind(this);
        this.saveParameter = this.saveParameter.bind(this);
        this.getOnSecureChange = this.getOnSecureChange.bind(this);
        this.updateSuggestions = this.updateSuggestions.bind(this);
    }

    public render() {
        const { isOpen } = this.props;
        if (!isOpen) return '';
        return (
            <div>
                {this.renderChartsInspectorPane()}
                {this.renderParameterEditorDialog()}
            </div>
        );
    }

    renderParameterEditorDialog() {
        const { cluster } = this.props.models.manifestModel;
        return (
            <Dialog
                isOpen={this.parameterEditorPopup.isOpen}
                icon={IconNames.ADD_TO_ARTIFACT}
                onOpened={this.updateSuggestions}
                onClose={() => {
                    this.parameterEditorPopup.index = -1;
                    this.parameterEditorPopup.name = '';
                    this.parameterEditorPopup.value = '';
                    this.parameterEditorPopup.secure = false;
                    this.parameterEditorPopup.isOpen = false;
                }}
                title="Add New Parameter"
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        helperText="Whether to use secure storage or not. Use it for secrets, credentials & API keys."
                        labelFor="secured-input"
                    >
                        <Switch
                            checked={this.parameterEditorPopup.secure}
                            id="secured-input"
                            label="Secure"
                            onChange={this.getOnSecureChange}
                        />
                    </FormGroup>

                    <FormGroup
                        label={'Name'}
                        labelFor="name-input"
                        helperText="Use path for the Helm chart parameters like it is used in --set options."
                    >
                        <ValuesSuggest
                            defaultSelectedItem={this.parameterEditorPopup.name}
                            createNewItemFromQuery={createNewValueFromQuery}
                            createNewItemRenderer={createNewItemRendererFromQuery(
                                'name',
                                this.parameterEditorPopup.secure
                            )}
                            itemsEqual={itemsAreEqual}
                            itemListPredicate={filterValues}
                            itemPredicate={filterValue}
                            popoverProps={{ minimal: true }}
                            inputValueRenderer={(v: string) =>
                                (this.parameterEditorPopup.name =
                                    filterParameterName(v))
                            }
                            itemRenderer={renderSuggestedValue}
                            onItemSelect={(v: string) =>
                                (this.parameterEditorPopup.name =
                                    filterParameterName(v))
                            }
                            items={this.suggestedNames}
                            fill={true}
                        />
                    </FormGroup>
                    <FormGroup
                        label={
                            this.parameterEditorPopup.secure
                                ? 'Value source'
                                : 'Value'
                        }
                        labelFor="value-input"
                        helperText={
                            this.parameterEditorPopup.secure
                                ? 'If you want to avoid automatic secret generation, use secrets management tool.'
                                : 'Search and pick existing value or type new one and hit Enter to add.'
                        }
                    >
                        <ControlGroup>
                            <ValuesSuggest
                                defaultSelectedItem={
                                    this.parameterEditorPopup.value
                                }
                                createNewItemFromQuery={createNewValueFromQuery}
                                createNewItemRenderer={createNewItemRendererFromQuery(
                                    'value',
                                    this.parameterEditorPopup.secure
                                )}
                                itemsEqual={itemsAreEqual}
                                itemListPredicate={filterValues}
                                itemPredicate={filterValue}
                                popoverProps={{ minimal: true }}
                                inputValueRenderer={(v: string) =>
                                    (this.parameterEditorPopup.value = v)
                                }
                                itemRenderer={renderSuggestedValue}
                                onItemSelect={(v: string) =>
                                    (this.parameterEditorPopup.value = v)
                                }
                                items={this.suggestedValues}
                                fill={true}
                            />

                            {cluster && this.parameterEditorPopup.secure ? (
                                <AnchorButton
                                    icon={IconNames.KEY}
                                    target={'_blank'}
                                    href={`/clusters/${cluster.id}/secrets/`}
                                >
                                    <Icon icon={IconNames.SMALL_PLUS} />
                                </AnchorButton>
                            ) : undefined}
                        </ControlGroup>
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            onClick={() =>
                                (this.parameterEditorPopup.isOpen = false)
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            intent={Intent.SUCCESS}
                            onClick={this.saveParameter}
                        >
                            {this.parameterEditorPopup.index === -1
                                ? 'Add'
                                : 'Save'}{' '}
                            Parameter
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }

    changeActiveComponentHandler(e: FormEvent<HTMLSelectElement>) {
        const { manifest } = this.props.models.manifestModel;
        if (!manifest) return;

        const component = manifest.components[parseInt(e.currentTarget.value)];
        console.log('component', component);
        if (component) {
            this.setActiveComponent(component);
        }
        this.props.repaint();
    }

    setActiveComponent(mc: IManifestComponent) {
        if (this.props.models.manifestModel.activeManifestComponent) {
            this.props.models.diagramModel.clearSelection();
        }
        this.props.models.manifestModel.activeManifestComponent = mc;
        if (this.props.models.manifestModel.activeManifestComponent) {
            this.props.models.diagramModel.setSelectedNode(
                this.props.models.manifestModel.activeManifestComponent
                    .releaseName
            );
            this.props.repaint();
        }
    }

    getActiveComponentIndex() {
        const { manifestModel } = this.props.models;
        const { manifest } = manifestModel;
        if (!manifest) return;
        return manifestModel.activeManifestComponent
            ? manifest.components.indexOf(manifestModel.activeManifestComponent)
            : undefined;
    }

    renderChartsInspectorPane() {
        let { manifest, activeManifestComponent } =
            this.props.models.manifestModel;

        return (
            <div className="inspectorPane">
                <ControlGroup fill={true} className="componentSelector">
                    <HTMLSelect
                        className="activeComponent"
                        onChange={this.changeActiveComponentHandler}
                        value={this.getActiveComponentIndex()}
                    >
                        {manifest &&
                            manifest.components.map((c, i) => (
                                <option value={i} key={i}>
                                    {c.namespace}/{c.releaseName}
                                </option>
                            ))}
                    </HTMLSelect>
                    <Button
                        icon={IconNames.TRASH}
                        onClick={() => {
                            if (activeManifestComponent && manifest) {
                                manifest.components.splice(
                                    manifest.components.indexOf(
                                        activeManifestComponent
                                    ),
                                    1
                                );
                                activeManifestComponent = undefined;
                                this.props.models.manifestModel.setManifestChanged(
                                    true
                                );
                                this.props.renderGraph();
                            }
                        }}
                    />
                </ControlGroup>
                <div className="header">
                    <div className="p">Parameter</div>
                    <div className="v">Value</div>
                </div>
                <div className="values">{this.renderActiveComponent()}</div>
            </div>
        );
    }

    renderActiveComponent() {
        const { activeManifestComponent } = this.props.models.manifestModel;
        if (!activeManifestComponent) return;

        const component = this.props.models.componentsModel.componentsList.find(
            (item) =>
                activeManifestComponent &&
                item.id === activeManifestComponent.component
        );

        return (
            <div>
                {activeManifestComponent.component && (
                    <div className="group">
                        <div className="p">Component Name</div>
                        <div className="v">
                            <span className="vst">
                                {activeManifestComponent.component}
                            </span>
                        </div>
                    </div>
                )}
                <div className="group isRequired">
                    <div className="p">Chart Name</div>
                    <div className="v">
                        <span className="vst">
                            {activeManifestComponent.chartName} @&nbsp;
                            {activeManifestComponent.chartVersion}
                        </span>
                    </div>
                </div>
                <div className="group isRequired">
                    <div className="p g">Namespace</div>
                    <div className="v">
                        <InputGroup
                            fill={true}
                            round={false}
                            small={true}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (activeManifestComponent)
                                    activeManifestComponent.namespace =
                                        e.target.value;

                                this.props.models.manifestModel.setManifestChanged(
                                    true
                                );
                                this.renderGraph();
                            }}
                            value={activeManifestComponent.namespace}
                        />
                    </div>
                </div>
                <div className="group isRequired">
                    <div className="p g">releaseName</div>
                    <div className="v">
                        <InputGroup
                            fill={true}
                            round={false}
                            small={true}
                            value={activeManifestComponent.releaseName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (activeManifestComponent)
                                    activeManifestComponent.releaseName =
                                        e.target.value;

                                this.props.models.manifestModel.setManifestChanged(
                                    true
                                );
                                this.renderGraph();
                            }}
                        />
                    </div>
                </div>
                {activeManifestComponent.setFileOptions.map((o, index) => {
                    return (
                        <ChartParameterRow
                            secure={true}
                            key={o.key}
                            name={o.key}
                            componentValue={this.getComponentValue(
                                component,
                                o.key
                            )}
                            value={o.fileId}
                            setValue={(v) => {
                                o.fileId = v;
                                this.props.models.manifestModel.setManifestChanged(
                                    true
                                );
                                this.renderGraph();
                            }}
                            rename={() => {
                                this.parameterEditorPopup.secure = true;
                                this.parameterEditorPopup.index = index;
                                this.parameterEditorPopup.name = o.key;
                                this.parameterEditorPopup.value = o.fileId;
                                this.parameterEditorPopup.isOpen = true;
                            }}
                            delete={() => {
                                if (activeManifestComponent) {
                                    activeManifestComponent.setFileOptions.splice(
                                        index,
                                        1
                                    );
                                }
                                this.renderGraph();
                            }}
                        />
                    );
                })}

                {activeManifestComponent.setOptions.map((o, index) => {
                    return (
                        <ChartParameterRow
                            secure={false}
                            key={o.key}
                            name={o.key}
                            value={o.value}
                            componentValue={this.getComponentValue(
                                component,
                                o.key
                            )}
                            setValue={(v) => {
                                if (!activeManifestComponent) return;
                                activeManifestComponent.setOptions[
                                    index
                                ].value = v;
                                this.props.models.manifestModel.setManifestChanged(
                                    true
                                );
                                this.renderGraph();
                            }}
                            rename={() => {
                                this.parameterEditorPopup.secure = false;
                                this.parameterEditorPopup.index = index;
                                this.parameterEditorPopup.name = o.key;
                                this.parameterEditorPopup.value = o.value;
                                this.parameterEditorPopup.isOpen = true;
                            }}
                            delete={() => {
                                if (activeManifestComponent) {
                                    activeManifestComponent.setOptions.splice(
                                        index,
                                        1
                                    );
                                    this.renderGraph();
                                }
                            }}
                        />
                    );
                })}
                <div className="info">
                    <div>
                        <Button
                            icon={IconNames.ADD_TO_ARTIFACT}
                            small={true}
                            intent={Intent.PRIMARY}
                            onClick={() =>
                                (this.parameterEditorPopup.isOpen = true)
                            }
                            minimal={true}
                        >
                            Add custom parameter
                        </Button>
                    </div>
                </div>
                <div className="info">
                    <p>
                        Bold parameters are required. <br />
                        Green parameters are stored securely.
                        <br />
                        Click onto parameter name to view additional options.
                    </p>
                </div>
            </div>
        );
    }

    renderGraph() {
        this.props.renderGraph();
    }

    getComponentValue(
        component: ICradleComponent | undefined,
        name: string
    ): ICradleComponentValue | undefined {
        if (!component) return;
        return component.values.find((value) => value.name === name);
    }

    saveParameter(e: FormEvent<HTMLElement>) {
        e.preventDefault();
        if (!this.props.models.manifestModel.activeManifestComponent) {
            return;
        }
        if (this.parameterEditorPopup.name.trim().length === 0) {
            AppToaster.show({
                intent: Intent.DANGER,
                message: 'Parameter clusterName is incorrect',
            });
            return;
        }
        if (this.parameterEditorPopup.value.trim().length === 0) {
            AppToaster.show({
                intent: Intent.DANGER,
                message: 'Parameter value is incorrect',
            });
            return;
        }

        this.props.models.manifestModel.setManifestChanged(true);

        const { activeManifestComponent } = this.props.models.manifestModel;

        const key = this.parameterEditorPopup.name.trim();
        const value = this.parameterEditorPopup.value.trim();
        const index = this.parameterEditorPopup.index;
        const changes = this.parameterEditorPopup.secure
            ? {
                  key,
                  fileId: value,
                  source: 'knox',
              }
            : {
                  key,
                  value,
              };
        if (index === -1) {
            if (this.parameterEditorPopup.secure) {
                activeManifestComponent.setFileOptions.push(
                    changes as IFileOption
                );
            } else {
                activeManifestComponent.setOptions.push(changes as IOption);
            }
        } else {
            if (this.parameterEditorPopup.secure) {
                activeManifestComponent.setFileOptions[index] =
                    changes as IFileOption;
            } else {
                activeManifestComponent.setOptions[index] = changes as IOption;
            }
        }
        this.parameterEditorPopup.index = -1;
        this.parameterEditorPopup.name = '';
        this.parameterEditorPopup.value = '';
        this.parameterEditorPopup.secure = false;
        this.parameterEditorPopup.isOpen = false;
    }

    private updateSuggestions() {
        const { manifest } = this.props.models.manifestModel;
        if (!manifest) return;
        const source = this.parameterEditorPopup.secure
            ? 'setFileOptions'
            : 'setOptions';
        this.suggestedValues = extractValues(manifest.components, source);
        this.suggestedNames = extractNames(manifest.components, source);
    }

    private getOnSecureChange() {
        return (e: FormEvent<HTMLInputElement>) => {
            this.parameterEditorPopup.secure = e.currentTarget.checked;
            this.updateSuggestions();
        };
    }
}
