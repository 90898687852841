import * as React from 'react';
import { ChartNodeModel } from './chart-node-model';
import {
    AbstractNodeFactory,
    DiagramEngine,
} from '@projectstorm/react-diagrams';
import { ChartNodeWidget } from './chart-node-widget';

export class ChartNodeFactory extends AbstractNodeFactory<ChartNodeModel> {
    constructor() {
        super('chart');
    }

    generateReactWidget(
        diagramEngine: DiagramEngine,
        node: ChartNodeModel
    ): JSX.Element {
        return React.createElement(ChartNodeWidget, {
            node: node,
            diagramEngine: diagramEngine,
        });
    }

    getNewInstance(initialConfig?: any): ChartNodeModel {
        return new ChartNodeModel();
    }
}
