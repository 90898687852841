export enum Permissions {
    None = 'no',
    Read = 'r',
    ReadAndWrite = 'rw',
}

export enum OwnershipPermissions {
    Owner = 'yes',
    None = 'no',
}

export const PermissionNames = {
    [Permissions.None]: 'None',
    [Permissions.Read]: 'Read Only',
    [Permissions.ReadAndWrite]: 'Read & Write',
};
