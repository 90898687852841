import React from 'react';
import {
    ItemListPredicate,
    ItemPredicate,
    ItemRenderer,
} from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import { IManifestComponent } from '../../../../types';
import { dedupe } from '../../../../utils/dedupe';

export function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter((word) => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens: React.ReactNode[] = [];

    let match = regexp.exec(text);
    if (match) {
        // const match = regexp.exec(text);
        // if (!match) {
        //     break;
        // }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}

export function createNewValueFromQuery<T>(query: T): T {
    return query;
}

export function itemsAreEqual(a: string, b: string) {
    return a === b;
}

export function filterParameterName(value: string) {
    return value.trim().replace(/[^[\].a-z0-9]/gi, '');
}

export const filterValues: ItemListPredicate<string> = (query, items) => {
    const normalizedQuery = query.toLowerCase();
    return items
        .filter((v) => v.toLowerCase().includes(normalizedQuery))
        .slice(0, 10);
};

export const filterValue: ItemPredicate<string> = (
    query,
    value,
    _index,
    exactMatch
) => {
    const normalizedTitle = value.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return `${value}`.indexOf(normalizedQuery) >= 0;
    }
};

export const renderSuggestedValue: ItemRenderer<string> = (
    value,
    { handleClick, modifiers, query }
) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${value}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={value}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const createNewRendererFromQuery = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
) => (
    <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
);

export type ManifestSource = 'setOptions' | 'setFileOptions';

export function extractValues(
    components: IManifestComponent[],
    manifestSource: ManifestSource
): string[] {
    let rv: string[] = [];
    components.forEach((c) => {
        switch (manifestSource) {
            case 'setOptions':
                rv = rv.concat(c.setOptions.map((so) => so.value));
                break;
            case 'setFileOptions':
                rv = rv.concat(c.setFileOptions.map((sfo) => sfo.fileId));
                break;
        }
    });
    return dedupe(rv);
}

interface IKey {
    key: string;
}

declare type Keyable = IKey[];

export function extractKeys(o: Keyable): string[] {
    return o.map(extractKey);
}

export function extractKey(o: IKey): string {
    return o.key;
}

export function extractNames(
    components: IManifestComponent[],
    manifestSource: ManifestSource
): string[] {
    let rv: string[] = [];
    components.forEach((c: IManifestComponent) => {
        switch (manifestSource) {
            case 'setOptions':
                rv = rv.concat(extractKeys(c.setOptions));
                break;
            case 'setFileOptions':
                rv = rv.concat(extractKeys(c.setFileOptions));
                break;
        }
    });
    return dedupe(rv);
}

export function createNewItemRendererFromQuery(field: string, secure: boolean) {
    return (
        query: string,
        active: boolean,
        handleClick: React.MouseEventHandler<HTMLElement>
    ) => {
        return (
            <MenuItem
                icon="add"
                text={
                    field === 'value'
                        ? secure
                            ? `Create "${query}" secret`
                            : `Add "${query}" value`
                        : `Add "${query}" name`
                }
                active={active}
                onClick={handleClick}
                shouldDismissPopover={false}
            />
        );
    };
}
