import React, { Component } from 'react';

import './logs.scss';
import { observer } from 'mobx-react';
import { Button, ButtonGroup, Classes, Divider, H1 } from '@blueprintjs/core';
import { withRouter } from 'react-router';
import { IClusterProps } from '../../../types/serverparams';
import { observable } from 'mobx';
import { UIState } from '../../../types/uistate';
import { AppNavigator } from '../../../models/navigation';
import { ProvisionStatusEnum } from '../../../types';
import { ReactTerm } from '../../common/react-term/react-term';
import { LoggerAwareModel } from '../../../models/logger-aware';
import { IconNames } from '@blueprintjs/icons';
import { ButtonLink } from '../../common/links/button-link';

@observer
class ClusterLogs extends Component<IClusterProps> {
    lm: LoggerAwareModel;

    @observable uiLoadState: UIState = UIState.Empty;

    constructor(props: any) {
        super(props);
        this.lm = new LoggerAwareModel();
    }

    async load() {
        AppNavigator.clear();
        AppNavigator.add('/clusters/', 'Clusters');
        this.uiLoadState = UIState.Loading;
        try {
            const clusterId = this.props.match.params.clusterId;
            if (clusterId) {
                await this.lm.loadCluster(clusterId);
                AppNavigator.add(
                    `/clusters/${clusterId}/`,
                    this.lm.cluster ? this.lm.cluster.clusterName : 'Cluster'
                );
                AppNavigator.add(`/clusters/${clusterId}/logs/`, 'Logs');
                if (this.lm.cluster) {
                    this.uiLoadState = UIState.Loaded;
                    this.lm.watchLogs();
                }
            }
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    componentDidMount(): void {
        this.load().then();
    }

    componentWillUnmount(): void {
        this.lm.clearLogRefreshTimer();
    }

    render() {
        return (
            <div className="clusterLogsComponent">
                <H1>{this.renderTitle()}</H1>
                <div className="flex-spaced">
                    {this.renderProvisionLogs()}
                    {this.lm.tip.isOpen ? (
                        <div className={'tips'}>
                            <div className={'tip'} key={'cnt'}>
                                {this.lm.tip.value.content}
                            </div>
                            <ButtonGroup className="footerButtons">
                                <Button
                                    minimal={true}
                                    icon={IconNames.CHEVRON_LEFT}
                                    onClick={() => this.lm.previousTip()}
                                />
                                <Button
                                    minimal={true}
                                    icon={IconNames.CHEVRON_RIGHT}
                                    onClick={() => this.lm.nextTip()}
                                />
                                <Divider />
                                <Button
                                    minimal={true}
                                    icon={IconNames.CROSS}
                                    onClick={() => this.lm.toggleTips()}
                                />
                            </ButtonGroup>
                        </div>
                    ) : undefined}
                </div>
                <div className="flex-spaced">
                    <ButtonGroup>
                        <ButtonLink to={'../'}>Back</ButtonLink>
                        <Button
                            loading={this.lm.logs.isRefreshingLogs}
                            disabled={this.lm.logs.isRefreshingLogs}
                            onClick={() => this.lm.refreshLogs()}
                            icon={IconNames.REFRESH}
                        >
                            Refresh logs
                        </Button>
                    </ButtonGroup>
                    <div>&nbsp;</div>
                    {this.lm.tip.isOpen ? undefined : (
                        <Button
                            minimal={true}
                            icon={IconNames.LIGHTBULB}
                            onClick={() => this.lm.toggleTips()}
                        />
                    )}
                </div>
            </div>
        );
    }

    renderTitle() {
        if (!this.lm.cluster)
            return <span>Logs aren't available at this time</span>;
        switch (this.lm.cluster.provisionStatus) {
            case ProvisionStatusEnum.Unprovisioned:
                break;
            case ProvisionStatusEnum.Provisioning:
            case ProvisionStatusEnum.Provisioned:
            case ProvisionStatusEnum.ProvisionFailed:
                return (
                    <span>
                        Install Logs for{' '}
                        <span className={Classes.TEXT_MUTED}>
                            {this.lm.cluster.clusterName}
                        </span>
                    </span>
                );
            case ProvisionStatusEnum.Destroying:
            case ProvisionStatusEnum.DestroyFailed:
                return (
                    <span>
                        Uninstall Logs for{' '}
                        <span className={Classes.TEXT_MUTED}>
                            {this.lm.cluster.clusterName}
                        </span>
                    </span>
                );
        }
    }

    renderProvisionLogs() {
        return (
            <div className={'logList'}>
                <ReactTerm
                    text={this.lm.logs.text}
                    autoScroll={true}
                    fullScreen={true}
                />
            </div>
        );
    }
}

export const ClusterLogsComponent = withRouter<IClusterProps, any>(ClusterLogs);
