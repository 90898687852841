import React, { Component, FormEvent } from 'react';
import {
    Button,
    ButtonGroup,
    Callout,
    Classes,
    FormGroup,
    InputGroup,
    Intent,
} from '@blueprintjs/core';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import './login.scss';
import { UserModel } from '../../../models/user-state';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AppToaster } from '../../../service/toaster';

import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ButtonLink } from '../links/button-link';
import { AppNavigator } from '../../../models/navigation';
import { ConfigService } from '../../../service/config';

interface CustomState {
    from: {
        pathname?: string;
    };
}

interface IProps extends RouteComponentProps<any, any, CustomState> {
    accountId: string;
    redirectToReferrer: boolean;
}

enum LoginUiState {
    Login,
    MFA,
    NewPasswordRequested,
    NewUserConfirmationRequested,
}

@observer
class Login extends Component<IProps> {
    @observable isFormDisabled = true;
    @observable isFormValid = false;
    @observable isNewPasswordsMatched = false;
    @observable uiState: LoginUiState = LoginUiState.Login;
    @observable redirectToReferrer = false;
    @observable cognitoUser?: CognitoUser | any;

    constructor(props: any) {
        super(props);

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleAwsException = this.handleAwsException.bind(this);
        this.handleSuccessfulAuthentication =
            this.handleSuccessfulAuthentication.bind(this);
        this.releaseForm = this.releaseForm.bind(this);
        this.processAwsSignChain = this.processAwsSignChain.bind(this);
        this.submit = this.submit.bind(this);
        this.startSaml = this.startSaml.bind(this);
    }

    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/', 'Login');
        ConfigService.init()
            .then(() => {
                if (UserModel.isAuthenticated) {
                    this.redirectToReferrer = true;
                }
            })
            .finally(() => {
                this.isFormDisabled = false;
            });
        if (this.props.location.hash) {
            const hd = this.props.location.hash
                .substr(1)
                .split('&')
                .map((attr) => {
                    const [k, v] = attr.split('=');
                    return { [k]: decodeURIComponent(v).replace(/\+/g, ' ') };
                })
                .reduce((a, v) => ({ ...a, ...v }));

            if (hd['error_description']) {
                AppToaster.show({
                    intent: Intent.DANGER,
                    message: hd.error_description,
                });
            }
        }
    }

    async awsSignIn() {
        const user: CognitoUser | any = await Auth.signIn(
            UserModel.fields.email,
            UserModel.fields.password
        );
        switch (user.challengeName) {
            case 'SMS_MFA':
            case 'SOFTWARE_TOKEN_MFA':
                this.uiState = LoginUiState.MFA;
                break;
            case 'NEW_PASSWORD_REQUIRED':
                this.uiState = LoginUiState.NewPasswordRequested;
                break;
            case 'MFA_SETUP':
                await Auth.setupTOTP(user);
                break;
            default:
                console.log('user', user);
                return user;
        }
    }

    handleAwsException(e: any) {
        const UNAUTHORIZED_MESSAGE = 'Invalid credentials';
        switch (e.code) {
            case 'UserNotConfirmedException':
                this.uiState = LoginUiState.NewUserConfirmationRequested;
                break;
            case 'PasswordResetRequiredException':
                this.props.location.state = {
                    from: {
                        pathname: '/users/auth/reset-password/',
                    },
                };
                this.redirectToReferrer = true;
                break;
            case 'UserNotFoundException':
                AppToaster.show({
                    message: UNAUTHORIZED_MESSAGE,
                    intent: Intent.DANGER,
                });
                break;
            case 'NotAuthorizedException':
            default:
                AppToaster.show({
                    message: UNAUTHORIZED_MESSAGE,
                    intent: Intent.DANGER,
                });
        }
    }

    handleSuccessfulAuthentication(user: CognitoUser) {
        UserModel.init().then(() => {
            this.redirectToReferrer = true;
        });
    }

    releaseForm() {
        this.isFormDisabled = false;
    }

    processAwsSignChain(promise: Promise<any>) {
        promise
            .then(this.handleSuccessfulAuthentication)
            .catch(this.handleAwsException)
            .finally(this.releaseForm);
    }

    startSaml() {
        Auth.federatedSignIn({
            customProvider: 'ADI',
        }).then((r) => console.log(r));
    }

    submit(event: FormEvent) {
        event.preventDefault();
        this.isFormDisabled = true;

        switch (this.uiState) {
            case LoginUiState.Login:
                this.processAwsSignChain(this.awsSignIn());

                break;
            case LoginUiState.MFA:
                if (!this.cognitoUser) {
                    this.uiState = LoginUiState.Login;
                    this.releaseForm();
                    return;
                }
                if (UserModel.fields.code.length > 0) {
                    this.processAwsSignChain(
                        Auth.confirmSignIn(
                            this.cognitoUser,
                            UserModel.fields.code,
                            this.cognitoUser.challengeName
                        )
                    );
                } else {
                    this.releaseForm();
                }
                break;
            case LoginUiState.NewPasswordRequested:
                break;
            case LoginUiState.NewUserConfirmationRequested:
                Auth.resendSignUp(UserModel.fields.email)
                    .then(() => {
                        AppToaster.show({
                            intent: Intent.SUCCESS,
                            message: 'Confirmation email has been sent.',
                            onDismiss: () => {
                                this.uiState = LoginUiState.Login;
                            },
                            timeout: 10000,
                        });
                    })
                    .catch(this.handleAwsException)
                    .finally(this.releaseForm);

                break;
        }
    }

    handleFieldChange(event: FormEvent<HTMLInputElement>) {
        UserModel.fields[event.currentTarget.name] = event.currentTarget.value;
    }

    renderLoginForm() {
        return (
            <div className={'oldForm'}>
                <p className={Classes.TEXT_MUTED}>
                    <i>or use classic login:</i>
                </p>

                <FormGroup
                    disabled={this.isFormDisabled}
                    label={'E-mail'}
                    labelFor="email-input"
                    labelInfo={'(required)'}
                >
                    <InputGroup
                        id="email-input"
                        name={'email'}
                        type={'email'}
                        placeholder="first.last@analog.com"
                        disabled={this.isFormDisabled}
                        required={true}
                        value={UserModel.fields.email}
                        onChange={this.handleFieldChange}
                    />
                </FormGroup>
                <FormGroup
                    disabled={this.isFormDisabled}
                    label={'Password'}
                    labelFor="password-input"
                    labelInfo={'(required)'}
                >
                    <InputGroup
                        id="password-input"
                        name="password"
                        type={'password'}
                        placeholder={'Password'}
                        disabled={this.isFormDisabled}
                        minLength={1}
                        required={true}
                        value={UserModel.fields.password}
                        onChange={this.handleFieldChange}
                    />
                </FormGroup>
                <FormGroup inline={true}>
                    <Button
                        disabled={this.isFormDisabled}
                        intent="primary"
                        text="Login"
                        type={'submit'}
                    />
                </FormGroup>
                <p className={'footerButton'}>
                    <ButtonLink
                        intent={Intent.PRIMARY}
                        to={'/users/auth/sign-up'}
                        minimal={true}
                    >
                        Sign Up
                    </ButtonLink>
                    <ButtonLink
                        intent={Intent.PRIMARY}
                        to={'/users/auth/reset-password'}
                        minimal={true}
                    >
                        Restore Password
                    </ButtonLink>
                </p>
            </div>
        );
    }

    renderUi() {
        switch (this.uiState) {
            case LoginUiState.Login:
                return (
                    <div>
                        <Button
                            intent={Intent.PRIMARY}
                            fill={true}
                            disabled={UserModel.isAuthenticating}
                            loading={UserModel.isAuthenticating}
                            onClick={() => {
                                this.startSaml();
                            }}
                        >
                            <b>Sign in with ADI Credentials</b>
                        </Button>
                    </div>
                );
            case LoginUiState.MFA:
                return (
                    <div>
                        <FormGroup
                            disabled={this.isFormDisabled}
                            label={'Confirmation code'}
                            labelFor="code-input"
                            labelInfo={'(required)'}
                        >
                            <InputGroup
                                id="code-input"
                                name={'code'}
                                type={'code'}
                                placeholder="Code from email or SMS"
                                disabled={this.isFormDisabled}
                                required={true}
                                value={UserModel.fields.code}
                                onChange={this.handleFieldChange}
                            />
                        </FormGroup>
                        <FormGroup inline={true}>
                            <Button
                                disabled={this.isFormDisabled}
                                intent="primary"
                                text="Confirm"
                                type={'submit'}
                            />
                        </FormGroup>
                    </div>
                );
            case LoginUiState.NewPasswordRequested:
                return (
                    <div>
                        <FormGroup
                            disabled={this.isFormDisabled}
                            label={'New password'}
                            labelFor="newPassword-input"
                            labelInfo={'(required)'}
                        >
                            <InputGroup
                                id="newPassword-input"
                                name="newPassword"
                                type={'password'}
                                placeholder={'Password'}
                                autoComplete={'new-password'}
                                minLength={5}
                                disabled={this.isFormDisabled}
                                required={true}
                                value={UserModel.fields.newPassword}
                                onChange={this.handleFieldChange}
                            />
                        </FormGroup>
                        <FormGroup
                            disabled={this.isFormDisabled}
                            label={'Repeat password'}
                            labelFor="newPasswordConfirmation-input"
                            labelInfo={'(required)'}
                            helperText={
                                !this.isNewPasswordsMatched &&
                                'Password must match new password.'
                            }
                        >
                            <InputGroup
                                id="newPasswordConfirmation-input"
                                name="newPasswordConfirmation"
                                type={'password'}
                                disabled={this.isFormDisabled}
                                placeholder={'Password'}
                                minLength={5}
                                autoComplete={'new-password'}
                                required={true}
                                value={UserModel.fields.newPasswordConfirmation}
                                onChange={this.handleFieldChange}
                            />
                        </FormGroup>
                        <FormGroup inline={true}>
                            <Button
                                disabled={this.isFormDisabled}
                                intent="primary"
                                text="Change"
                                type={'submit'}
                            />
                        </FormGroup>
                    </div>
                );
            case LoginUiState.NewUserConfirmationRequested:
                return (
                    <div>
                        <Callout
                            intent={Intent.WARNING}
                            title={'Confirmation Required'}
                        >
                            <div className="confirmationCallout">
                                <p>New User confirmation requested.</p>
                                <p>
                                    Please check your email for confirmation
                                    instructions.
                                </p>
                                <p>
                                    If you didn't get the verification email,
                                    you can request a new one.
                                </p>
                                <ButtonGroup>
                                    <Button
                                        disabled={this.isFormDisabled}
                                        intent={Intent.PRIMARY}
                                        text="Login"
                                        onClick={(
                                            event:
                                                | React.MouseEvent<HTMLElement>
                                                | React.MouseEvent<HTMLButtonElement>
                                        ) => {
                                            event.preventDefault();
                                            this.uiState = LoginUiState.Login;
                                        }}
                                    />
                                    <Button
                                        disabled={this.isFormDisabled}
                                        intent={Intent.WARNING}
                                        text="Resend confirmation email"
                                        type={'submit'}
                                    />
                                </ButtonGroup>
                            </div>
                        </Callout>
                    </div>
                );
        }
    }

    render() {
        if (this.redirectToReferrer) {
            let { from } = this.props.location.state || {
                from: { pathname: '/' },
            };
            if ((from && from.pathname === '/') || from.pathname === '') {
                from.pathname = '/clusters/';
            }
            return <Redirect to={from} />;
        }

        return (
            <div className="loginComponent">
                <form className="login" onSubmit={this.submit}>
                    {this.renderUi()}
                </form>
            </div>
        );
    }
}

export const LoginComponent = withRouter<IProps, any>(Login);
export default LoginComponent;
