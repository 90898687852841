import {
    areSolutionsEqual,
    ISolution,
    solutions,
    solutionsRenderer,
} from '../../../../types';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { ItemPredicate, Omnibar } from '@blueprintjs/select';

export interface IComponentPanelProps {
    isOpen: boolean;

    onClose(cl?: string[]): void;
}

const SolutionsOmniBar = Omnibar.ofType<ISolution>();

const solutionsPredicate: ItemPredicate<ISolution> = (
    query: string,
    item: ISolution,
    index?: number,
    exactMatch?: boolean
): boolean => {
    const lq = query.toLowerCase();
    return (
        query.length === 0 ||
        item.title.toLowerCase().includes(lq) ||
        item.description.toLowerCase().includes(lq) ||
        item.components.includes(lq)
    );
};

@observer
export class SolutionsSearch extends Component<IComponentPanelProps, any> {
    private progressToastInterval?: number;

    public render() {
        const { isOpen } = this.props;
        if (!isOpen) return '';
        return <div>{this.renderSolutionsSearch()}</div>;
    }

    private renderSolutionsSearch() {
        return (
            <SolutionsOmniBar
                className={'solution-navigator-menu'}
                items={solutions}
                isOpen={this.props.isOpen}
                query={' '}
                itemPredicate={solutionsPredicate}
                itemRenderer={solutionsRenderer}
                itemsEqual={areSolutionsEqual}
                noResults={<MenuItem disabled={true} text="No results." />}
                onClose={() => this.props.onClose()}
                onItemSelect={(e) => {
                    console.log(e);
                    this.props.onClose(e.components);
                }}
            />
        );
    }
}
