import { IFormParam, TypeCast } from '../../../types';
import { FormParamTypeEnum } from '../../../types';

export const prometheusConfigParameter: IFormParam = {
    type: FormParamTypeEnum.Checkbox,
    label: 'Enable advanced resource utilization capture.',
    k: 'prometheus',
    v: 'false',
    description: '',
    cast: TypeCast.Boolean,
    required: false,
    disabled: true,
};
