import { InMemoryStorage, StorageImpl } from '../service/storage';

const weakStorage = new StorageImpl(InMemoryStorage);

interface StoredObject {
    v: any;
    ts: number;
}

/**
 * Caching decorator
 * @param ttl - TTL in seconds
 */
export function cache(ttl: number = 300) {
    return function (
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const existingMethod = descriptor.value;
        descriptor.value = async function (...args: any) {
            const key = [
                target.name || target.constructor.name,
                propertyKey,
                JSON.stringify(args),
            ].join('.');
            const so: StoredObject | undefined = weakStorage.get(key);
            const ts = Date.now();
            if (so && so.ts && so.v && so.ts + ttl * 1000 > ts) {
                return so.v;
            }
            const v = await existingMethod.apply(this, args);
            console.log('saving', key);
            weakStorage.set(key, { ts, v });
            return v;
        };
    };
}

/**
 * Caching decorator
 * @param domain - Cache domain
 */
export function cacheResetDomain(domain: string) {
    return function (
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const existingMethod = descriptor.value;
        descriptor.value = async function (...args: any) {
            const key = [target.name || target.constructor.name, domain].join(
                '.'
            );
            const keys = weakStorage.getAll();
            console.log('cacheResetDomain', key, keys);
            keys.forEach((sk) => {
                if (sk.startsWith(key)) {
                    console.log('wiping ', sk);
                    weakStorage.unset(sk);
                }
            });
            return await existingMethod.apply(this, args);
        };
    };
}
