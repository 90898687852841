import React, { Component, FormEvent } from 'react';
import {
    Alignment,
    Button,
    Callout,
    Classes,
    Collapse,
    Dialog,
    FormGroup,
    H1,
    InputGroup,
    Intent,
    Navbar,
    NavbarGroup,
    Pre,
    Spinner,
    Switch,
    Tooltip,
} from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router';

import './secure-storage.scss';
import { ICAsParams, ICertificate, UIState } from '../../../types';
import { StatefulComponent } from '../../common/stateful/stateful';
import { CAModel } from '../../../models/cas';
import { AppNavigator } from '../../../models/navigation';
import { IconNames } from '@blueprintjs/icons';
import { CertificatesModel } from '../../../models/certs';
import { ButtonLink } from '../../common/links/button-link';
import { AppCopier } from '../../../service/copier';
import { SubheaderBreadcrumbs } from '../../common/breadcrumbs/cms-breadcrumbs';

@observer
class Certificates extends Component<ICAsParams> {
    @observable uiLoadState: UIState = UIState.Empty;
    casModel: CAModel;
    certsModel: CertificatesModel;

    @observable
    private text: string = '';

    constructor(props: any) {
        super(props);
        this.casModel = new CAModel();
        this.certsModel = new CertificatesModel();
        this.renderCertificate = this.renderCertificate.bind(this);
        this.copy = this.copy.bind(this);
        this.renderNewCertificateDialog =
            this.renderNewCertificateDialog.bind(this);
    }

    componentWillUnmount(): void {
        AppNavigator.clear();
    }

    async load() {
        this.uiLoadState = UIState.Loading;
        AppNavigator.clear();
        try {
            AppNavigator.add('/clusters/', 'Clusters');
            const cluster = await this.casModel.loadCluster(
                this.props.match.params.clusterId
            );
            if (cluster) {
                AppNavigator.add(
                    `/clusters/${cluster.id}/`,
                    cluster.clusterName
                );
                AppNavigator.add(`/clusters/${cluster.id}/secrets/`, 'Secrets');
                AppNavigator.add(
                    `/clusters/${cluster.id}/secrets/certs/`,
                    'CAs'
                );
                await this.casModel.setCluster(cluster);
                if (this.props.match.params.ca) {
                    await this.casModel.loadCAs();
                    await this.casModel.setActiveCertificateAuthority(
                        this.props.match.params.ca
                    );
                    if (this.casModel.activeCertificateAuthority) {
                        await this.certsModel.setCluster(cluster);
                        await this.certsModel.setActiveCertificateAuthority(
                            this.casModel.activeCertificateAuthority
                        );
                        AppNavigator.add(
                            `/clusters/${cluster.id}/secrets/certs/${this.casModel.activeCertificateAuthority.commonName}`,
                            this.casModel.activeCertificateAuthority.commonName
                        );
                    }
                }
            }
            this.uiLoadState = UIState.Loaded;
        } catch (e) {
            this.uiLoadState = UIState.Failed;
        }
    }

    async copy(c: ICertificate) {
        if (!c.data) {
            await this.certsModel.hydrate(c);
        }

        if (c.data && c.data.certificate) {
            AppCopier.copy(c.data.certificate);
        }
    }

    componentDidMount() {
        this.load().then();
    }

    public render() {
        return (
            <div className={'secureStorageComponent'}>
                <StatefulComponent state={this.uiLoadState}>
                    <div>
                        {this.certsModel.activeCertificateAuthority
                            ? this.renderCertificatesList()
                            : ''}

                        {this.renderNewCertificateDialog()}
                    </div>
                </StatefulComponent>
                <p></p>
                <ButtonLink to={'../'} icon={IconNames.CHEVRON_LEFT}>
                    Back
                </ButtonLink>
            </div>
        );
    }

    renderAccordionIcon(cert: ICertificate) {
        if (cert.uiIsLoading) {
            return <Spinner size={16} />;
        }
        if (cert.uiIsOpen) {
            return IconNames.CHEVRON_UP;
        }
        return IconNames.CHEVRON_DOWN;
    }

    renderCertificate(cert: ICertificate) {
        return (
            <div key={cert.commonName} className={'row'}>
                <Navbar>
                    <NavbarGroup align={Alignment.LEFT}>
                        <Button
                            icon={this.renderAccordionIcon(cert)}
                            minimal={true}
                            large={true}
                            fill={true}
                            disabled={cert.uiIsLoading}
                            onClick={() => this.certsModel.toggle(cert)}
                        >
                            {cert.commonName}
                        </Button>
                        <br />
                    </NavbarGroup>
                    <NavbarGroup align={Alignment.RIGHT}>
                        <Tooltip
                            interactionKind={'hover'}
                            content={
                                (cert.data === undefined ? 'Get' : 'Copy') +
                                ' Certificate'
                            }
                        >
                            <Button
                                minimal={true}
                                disabled={cert.uiIsLoading}
                                intent={Intent.NONE}
                                icon={
                                    cert.data === undefined
                                        ? IconNames.DOWNLOAD
                                        : IconNames.CLIPBOARD
                                }
                                onClick={() => {
                                    this.copy(cert).then();
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            interactionKind={'hover'}
                            content={'Delete Certificate'}
                        >
                            <Button
                                loading={cert.uiIsDeleting}
                                minimal={true}
                                intent={Intent.DANGER}
                                icon={IconNames.REMOVE}
                                onClick={() => this.certsModel.delete(cert)}
                            />
                        </Tooltip>
                    </NavbarGroup>
                </Navbar>
                <div className="flex-row">
                    <Collapse
                        isOpen={cert.uiIsOpen}
                        className={'certificateDetails'}
                    >
                        <p>
                            Fingerprint: <code>{cert.fingerprint}</code>
                        </p>
                        {cert.data ? (
                            <div>
                                <Pre>{cert.data.certificate}</Pre>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </Collapse>
                </div>
            </div>
        );
    }

    renderCertificatesList() {
        if (!this.casModel.activeCertificateAuthority) {
            return;
        }
        return (
            <div>
                <div className="flex-spaced">
                    <H1>
                        Certificates for{' '}
                        <q>
                            {
                                this.casModel.activeCertificateAuthority
                                    .commonName
                            }
                        </q>{' '}
                    </H1>
                    <Button
                        icon={IconNames.ADD}
                        minimal={true}
                        onClick={() => this.certsModel.showDialog()}
                        intent={Intent.PRIMARY}
                    >
                        Add
                    </Button>
                </div>
                <SubheaderBreadcrumbs breadcrumbs={AppNavigator.items} />

                <p></p>

                {this.certsModel.certs.map(this.renderCertificate)}
            </div>
        );
    }

    renderNewCertificateDialog() {
        const { newDialog } = this.certsModel;
        return (
            <Dialog
                isOpen={newDialog.isOpen}
                onClose={() => this.certsModel.hideDialog()}
                canEscapeKeyClose={false}
                usePortal={true}
                autoFocus={true}
                canOutsideClickClose={false}
                isCloseButtonShown={!newDialog.isCreating}
                title="Create New Certificate"
            >
                <form onSubmit={(e) => this.certsModel.submit(e)} action={'#'}>
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label={'Common Name'}
                            labelFor="commonName-input"
                            labelInfo={'(required)'}
                            helperText={'Use domain name here.'}
                        >
                            <InputGroup
                                disabled={newDialog.isDisabled}
                                required={true}
                                id="commonName-input"
                                placeholder="mycert.project.com"
                                type={'domain'}
                                name={'commonName'}
                                value={newDialog.cert.commonName}
                                onChange={(e: any) =>
                                    this.certsModel.updateField(e)
                                }
                                minLength={1}
                                pattern={'^[a-z0-9-.]+$'}
                                autoFocus={true}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                defaultChecked={newDialog.cert.savePrivateKey}
                                label={'Save Private Key'}
                                name={'savePrivateKey'}
                                disabled={newDialog.isDisabled}
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    this.certsModel.updateField(e)
                                }
                            />
                        </FormGroup>
                        <Callout intent={Intent.PRIMARY}>
                            This action will issue a new certificate.
                            <br />
                            Use chart secrets to deliver certificates to the
                            cluster.
                        </Callout>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => this.certsModel.hideDialog()}
                                disabled={newDialog.isDisabled}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    newDialog.isDisabled || !newDialog.isValid
                                }
                                loading={newDialog.isCreating}
                                type={'submit'}
                                intent={Intent.PRIMARY}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const CertificatesComponent = withRouter<ICAsParams, any>(Certificates);
