import { IManifestComponent } from '../../../../types';

export function setComponentOption(
    component: IManifestComponent,
    key: string,
    value: any
) {
    component.setOptions.forEach((option) => {
        if (option.key === key) {
            option.value = value;
        }
    });
    return component;
}

export function getComponentOption(
    component: IManifestComponent,
    key: string
): string | undefined {
    const fo = component.setOptions.find((option) => {
        return option.key === key;
    });
    if (fo) {
        return fo.value;
    }
    return undefined;
}
