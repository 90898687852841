import { action, computed, observable } from 'mobx';
import { IUser } from './users';
import { CognitoUser } from '@aws-amplify/auth';
import { ensureTrailingSlash, ucFirst } from '../utils/formatters';
import { ILoginFields } from '../types/login-fields-interface';
import { Storage } from '../service/storage';
import * as Sentry from '@sentry/browser';
import { Auth, Hub } from 'aws-amplify';
import Amplify, { HubCapsule } from '@aws-amplify/core';

export function emailToName(email: string) {
    const p = email.split('@');
    return p[0].split(/\W/, 2).map(ucFirst).join(' ');
}

export class UserStateModel {
    @observable isAuthenticated = false;
    @observable isAuthenticating = false;
    @observable changePasswordOnSignIn = false;
    @observable token: string = '';
    @observable tokenExpiresAt: Date = new Date(0);
    @observable user: IUser | undefined = undefined;

    cognitoUser?: CognitoUser;

    @observable
    fields: ILoginFields = {
        email: '',
        password: '',
        code: '',
        newPassword: '',
        newPasswordConfirmation: '',
    };

    constructor() {
        const {
            REACT_APP_API_AWS_USER_POOL_ID: userPoolId,
            REACT_APP_API_AWS_USER_POOL_WEB_CLIENT_ID: userPoolWebClientId,
            REACT_APP_API_AWS_REGION: region,
            REACT_APP_API_AWS_COGNITO_DOMAIN,
        } = process.env;

        const { PUBLIC_URL } = process.env;
        const redirectUrl = ensureTrailingSlash(
            window.location.origin + PUBLIC_URL
        );

        Amplify.configure({
            userPoolId,
            userPoolWebClientId,
            region,
            oauth: {
                domain: REACT_APP_API_AWS_COGNITO_DOMAIN,
                scope: [
                    'email',
                    'openid',
                    'phone',
                    'profile',
                    'aws.cognito.signin.user.admin',
                ],
                redirectSignIn: redirectUrl,
                redirectSignOut: redirectUrl,
                responseType: 'code', // REFRESH token will only be generated when the responseType is code
            },
        });

        Hub.listen('auth', (e: HubCapsule) => {
            console.log('hub events', e);

            if (e.channel === 'auth' && e.payload.event === 'signIn') {
                this.init().then();
            }
        });
    }

    @computed
    get userName(): string {
        if (this.isAuthenticated) {
            return this.user ? this.user.firstName : 'Profile';
        }
        return '';
    }

    @computed
    get userId(): string {
        if (this.isAuthenticated) {
            return (this.user && this.user.id) || '';
        }
        return '';
    }

    @computed
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    @action
    async assureCognitoSession() {
        if (!this.cognitoUser) {
            return this.init();
        }
        const session = this.cognitoUser.getSignInUserSession();
        if (!session || !session.isValid()) {
            return this.init();
        }
    }

    @action
    async init() {
        try {
            this.isAuthenticating = true;
            this.cognitoUser = await Auth.currentAuthenticatedUser({
                bypassCache: false,
            });
            console.log('cognitoUser', this.cognitoUser);
            if (this.cognitoUser) {
                const session = this.cognitoUser.getSignInUserSession();
                console.log('session', session);
                if (session && session.isValid()) {
                    Storage.set('token', session.getIdToken().getJwtToken());
                } else {
                    Storage.unset('token');
                }
                await this.setUser(this.cognitoUser);
                this.isAuthenticated = true;
                this.isAuthenticating = false;
            }
        } catch (e) {
            this.isAuthenticating = false;
            console.error('Session init error', e);
            this.reset();
            // await this.signOut();
        }
        return this.cognitoUser;
    }

    @action
    signIn() {}

    @action
    async signOut() {
        return Auth.signOut().finally(() => {
            this.reset();
            Storage.clear();
        });
    }

    @action
    async setUser(user: IUser | CognitoUser): Promise<IUser> {
        console.log('UserState.setUser', user);
        return new Promise((resolve, reject) => {
            if (user instanceof CognitoUser) {
                user.getUserAttributes((err, attributes) => {
                    console.log('user.getUserAttributes', err, attributes);
                    if (err || !attributes) {
                        reject(err);
                        return;
                    }
                    this.user = {
                        id:
                            extractAttribute(attributes, 'sub') ||
                            user.getUsername(),
                        firstName: user.getUsername(),
                        lastName: '',
                        email: extractAttribute(attributes, 'email'),
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        roles: [],
                    };
                    this.user.firstName = extractAttribute(
                        attributes,
                        'given_name',
                        emailToName(this.user.email)
                    );
                    this.user.lastName = extractAttribute(
                        attributes,
                        'family_name',
                        ''
                    );
                    this.user.phone = extractAttribute(
                        attributes,
                        'phone_number',
                        ''
                    );
                    Sentry.setUser({
                        id: this.user.id,
                        email: this.user.email,
                        username: this.user.firstName,
                    });
                    this.isAuthenticated = true;
                    resolve(this.user);
                });
            } else {
                this.user = user;
                resolve(this.user);
            }
        });
    }

    @action reset() {
        this.tokenExpiresAt = new Date(0);
        this.user = undefined;
        this.token = '';
        this.isAuthenticated = false;
        this.changePasswordOnSignIn = false;
    }
}

export function extractAttribute(
    attributes: any[],
    name: string,
    defaultValue: string = ''
): string {
    return attributes.reduce((acc, attribute) => {
        return attribute.getName() === name ? attribute.getValue() : acc;
    }, defaultValue);
}

export const UserModel = new UserStateModel();
