import * as React from 'react';
import { DefaultNodeWidget } from '@projectstorm/react-diagrams';
import { ContextMenuTarget, Menu, MenuItem } from '@blueprintjs/core';

@ContextMenuTarget
export class ChartNodeWidget extends DefaultNodeWidget {
    public renderContextMenu() {
        // return a single element, or nothing to use default browser behavior
        return (
            <Menu>
                <MenuItem onClick={this.handleSave} text="Nirvanaze" />
                <MenuItem onClick={this.handleDelete} text="Samsarize" />
            </Menu>
        );
    }

    handleSave() {
        window.open('https://www.youtube.com/watch?v=hTWKbfoikeg');
    }

    handleDelete() {
        alert(`
        In endless cycle, 
        Stuck between death and life
        Component shall find its purpose. 
        `);
    }
}
